export default [
  {
    path: "/forgotpassword/",
    name: "ForgotPassword",
    component: () => import("../components/UserEntry/ForgotPword.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/resetpassword/",
    name: "ResetPword",
    component: () => import("../components/UserEntry/ResetPword.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/email-confirm/",
    name: "SubscriptionEmailSuccessFail",
    component: () => import("@/views/subscription/SubscriptionEmailSuccessFail.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/tsandcs",
    name: "TsandCs",
    component: () => import("@/views/aux-pages/TsandCSPage.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/in-app-tsandcs",
    name: "InAppTsandCs",
    component: () => import("@/views/aux-pages/InAppTsandCSPage.vue"),
  },
  {
    path: "/test/",
    name: "Test",
    component: () => import("../views/TestPage.vue"),
    meta: { isPublic: true, hideBar: false },
  },
  {
    path: "/education/",
    name: "EducationPage",
    component: () => import("@/views/aux-pages/EducationPage.vue"),
  },
  {
    path: "/contact-support/",
    name: "ContactSupportPage",
    component: () => import("@/views/aux-pages/ContactSupportPage.vue"),
    meta: { isFree: true},
  },
  {
    path: "/request-features/",
    name: "RequestFeaturePage",
    component: () => import("@/views/aux-pages/RequestFeaturePage.vue"),
    meta: { isFree: true},
  },
]