<template>
  <div
    ref="panParent"
    class="pan-parent"
    :class="{'no-drag': allowPan}"
    @mousemove="allowPan && handleMouseMove($event)"
    @pointerup="allowPan && handleMouseUp($event)"
    @pointerdown.stop="allowPan && handleMouseDown($event)"
    @wheel="allowZoom && handleScroll($event)"
    @touchstart="allowPan && handleTouchStart($event)"
    @touchmove="allowPan && handleTouchMove($event)"
    @touchend="allowPan && handleTouchEnd($event)"
    style="border: solid 1px black; width: 100%;"
  >
    <div
      class="pan trytoswipe"
      :style="transform"
      :class="{'no-drag': allowPan}"
      style="aspect-ratio: 335 / 178;"
      ref="viewR"
    >
    
      <img ref="imgR" :src="imageUrl" />
    </div>
  </div>

  <div v-if="allowZoom" style="padding-top: 25px">
    <h3>{{ position.x }} {{ position.y }} {{ position.z }}</h3>
    <div class="range-wrapper">
      <ion-range labelPlacement="start" label="Choose Zoom" v-model="position.z" :ticks="true" :snaps="true" :min="0" :max="10"></ion-range>
    </div>
  </div>
</template>

<script setup>
// 580x410


const props = defineProps({
  imageUrl: String,
  allowZoom: {
    type: Boolean,
    default: true,
  },
  allowPan: {
    type: Boolean,
    default: true,
  },
  minScale: {
    type: Number,
    default: 0.1,
  },
  maxScale: {
    type: Number,
    default: 20,
  },
  viewPort: {
    type: Object,
    required: false,
    validate: (value) => {
      return (
        value.hasOwnProperty("x") &&
        value.hasOwnProperty("y") &&
        value.hasOwnProperty("z")
      );
    },
  },
});
import {
 IonRange
} from "@ionic/vue";

import { ref, watchEffect, reactive, onMounted, onBeforeUnmount } from "vue";
const panParent = ref(null);
const imgR = ref(null);
const isDragging = ref(false);
const viewR = ref(null);

let offSetX;
let offSetY;

const _offSetX = ref(undefined);
const _offSetY = ref(undefined);

const lastTouch = {
  x: 0,
  y: 0,
  z: 0,
};

const position = reactive({
  x: 0,
  y: 0,
  z: 1,
});

defineExpose({
  position,
})

const transform = ref({
  transform: `scale(${position.z}) translate(${position.x}px, ${position.y}px)`,
      });

var lastSize = {
  width: 0,
  height: 0,
};

//Grab the Viewport from the Parent
watchEffect(() => {
  if (props.viewPort) {
    position.x = props.viewPort.x * _offSetX.value;
    position.y = props.viewPort.y * _offSetY.value;
    position.z = props.viewPort.z;
  }
});

watchEffect(
  () => {
    let trueImgWidth = imgR.value.width * position.z;
    let trueImgHeight = imgR.value.height * position.z;
    lastSize.width = trueImgWidth; //Update the last size when we scale the image.
    lastSize.height = trueImgHeight;
    transform.value.transform = `scale(${position.z}) translate(${position.x}px, ${position.y}px)`; //Set the Transform
  },
  { flush: "post" } //This Makes sure it runs after the component is rendered
);
// const viewB = computed(() => {
//   return `${position.x} ${position.y} ${position.z} ${position.z}`;
// });

// const translate = computed(() => {
//   return `translate(${position.x}px, ${position.y}px)`;
// });
const resizeObserver = new ResizeObserver(handleResize);
onMounted(() => {
  resizeObserver.observe(viewR.value);
});
onBeforeUnmount(() => {
  resizeObserver.unobserve(viewR.value);
});
function handleResize(entires) {
  //Get the Actual Scaled Image Size
  let trueImgWidth = imgR.value.width * position.z;
  let trueImgHeight = imgR.value.height * position.z;

  if (lastSize.width === 0) { //Get the Initial Size If we don't already have it.
    lastSize.width = trueImgWidth;
    lastSize.height = trueImgHeight;
  }

  offSetX = trueImgWidth / lastSize.width;
  offSetY = trueImgHeight / lastSize.height;
  console.log("Resize", viewR);
  if(viewR.value.clientWidth){
    _offSetX.value = viewR.value.clientWidth / 580;
    _offSetY.value = viewR.value.clientHeight / 410;
  }
  
  console.log("OffSet", _offSetX.value, _offSetY.value);

  // lastSize.width = entires[0].contentRect.width;
  // lastSize.height = entires[0].contentRect.height;
  lastSize.width = trueImgWidth;
  lastSize.height = trueImgHeight;

  if (isNaN(offSetX) || isNaN(offSetY)) return; //prevent failture on divide by zero. It's possible this is no longer needed. better safe then sorry

  if (offSetX == Infinity || offSetY == Infinity) return; //See Above

  position.x = position.x * offSetX; //Scale the position of the image to match the new size.
  position.y = position.y * offSetY; 
}
function handleScroll(e) {
  // TODO Don't prevent event propagation so we can scroll the page when scaling is disabled and we are at the min or max scale
  // console.log(e.deltaY/ 1000);
  position.z = Math.ceil((Math.min(
    Math.max(position.z - e.deltaY / 1000, props.minScale),
    props.maxScale
  )) * 100)/ 100;
  if(props.allowZoom){
    e.preventDefault();
  }
}
function handleMouseDown(e) {
  // TODO Add a prop to disable panning 
  // TODO When Panning is disabled allow events to propagate.
  isDragging.value = true;
}
function handleMouseMove(e) {
  if (isDragging.value) {
    position.x = position.x + e.movementX;
    position.y = position.y + e.movementY;
  }

  if(props.allowPan){
    e.preventDefault();
  }
}
function handleMouseUp(e) {
  isDragging.value = false;
}

function handleTouchStart(e) {
  if (e.touches.length == 1) {
    lastTouch.x = e.touches[0].clientX;
    lastTouch.y = e.touches[0].clientY;
    handleMouseDown(e);
  } else if (e.touches.length == 2) {
    isDragging.value = false;
    //
    var dX = e.touches[0].clientX - e.touches[1].clientX;
    var dY = e.touches[0].clientY - e.touches[1].clientY;
    var z = Math.sqrt(dX ** 2 + dY ** 2);
    lastTouch.z = z;
  }
}
function handleTouchMove(e) {
  if (e.touches.length == 1 && isDragging.value) {
    position.x = position.x + ((e.touches[0].clientX - lastTouch.x)/ position.z); //For Some Reason on my android the speed of the drag scales with the zoom so we divide by the scale to compensate.
    position.y = position.y + ((e.touches[0].clientY - lastTouch.y)/ position.z);
    lastTouch.x = e.touches[0].clientX;
    lastTouch.y = e.touches[0].clientY;
  } else if (e.touches.length == 2) { //Pinch to Zoom
    isDragging.value = false;
    var dX = e.touches[0].clientX - e.touches[1].clientX; //Get the distance between the two fingers.
    var dY = e.touches[0].clientY - e.touches[1].clientY; //Get the distance between the two fingers.
    var z = Math.sqrt(dX ** 2 + dY ** 2); // Zoom based on the distance between the two fingers.
    position.z = Math.min(
      Math.max(position.z + (z - lastTouch.z) / 100, 0.1), //Clamp the Zoom TODO Add Max Zoom prop so it works here.
      20
    );
    lastTouch.z = z;
  }
}

function handleTouchEnd(e) {
  handleMouseUp(e);
  //
}

</script>
<style scoped lang="scss">

.no-drag{
  touch-action: none;
}
.pan {
  position: relative;
  user-select: none;
  /* width: 2000px; */
  transition: transform 330ms ease-in-out 0s;
}
.pan-parent {
  overflow: hidden;
  user-select: none;
  position: relative;
}
img {
  display: block;
  margin: 0 auto;
  /* Prevent the image from being dragged around the screen. */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}


</style>
