<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <HeaderComp title="Trackside Products">
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/shop" text="" :icon="chevronBackOutline"></ion-back-button>
      </ion-buttons>
    </HeaderComp>
    <ion-content v-if="chosenProduct.name">
      <div class="as-page-wrapper home-page addon-page">
        <ion-grid class="ST1Cont">
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <div class="title-content-wrapper">
                <h2>Calibration Upgrades</h2>
                <p>
                  You have chosen a <strong>{{ chosenProduct.name }}</strong
                  >.
                </p>
                <p>Please choose any required upgrades below (optional):</p>
              </div>
              <!--content-wrapper-->
            </ion-col>
          </ion-row>
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <ion-card class="first-card as-card">
                <ion-card-header>
                  <ion-card-title>Video Analysis</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div class="as-content">
                    <p>
                      Share in car video, trackside, video, thermal imaging etc.
                      to help us dial in your calibration
                    </p>
                  </div>
                  <!--as-content-->

                  <!-- <div class="plus-minus-wrapper">
                      <div class="number-wrapper">
                        <p>1</p>
                      </div>
                      <ion-icon :icon="removeOutline"></ion-icon>
                      <ion-icon :icon="add"></ion-icon>
                    </div> -->
                  <ion-toggle
                    v-model="videoAddonChosen"
                    color="secondary"
                    aria-label="Video Addon"
                    @ionChange="handleToggleChange"
                  ></ion-toggle>
                </ion-card-content>
                <div class="price-circle">$50</div>
              </ion-card>
              </ion-col>
              <ion-col class="ST1Col1">
              <ion-card class="as-card">
                <ion-card-header>
                  <ion-card-title>Data Analysis</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div class="as-content">
                    <p>
                      Share logged data, camera/data overlays, etc. to help us
                      add layers of detail to your calibration
                    </p>
                  </div>
                  <!--as-content-->
                  <!-- <div class="plus-minus-wrapper">
                      <div class="number-wrapper">
                        <p>0</p>
                      </div>
                      <ion-icon :icon="removeOutline"></ion-icon>
                      <ion-icon :icon="add"></ion-icon>
                    </div> -->
                  <ion-toggle
                    v-model="dataAddonChosen"
                    color="secondary"
                    aria-label="Data Addon"
                    @ionChange="handleToggleChange"
                  ></ion-toggle>
                </ion-card-content>
                <div class="price-circle">$100</div>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ST2Cont">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="title-content-wrapper">
                <h2>Choose Dates</h2>
                <p>
                  Choose your required date(s) for your calibration. This will
                  be sent to our team along with your product choices and they
                  will confirm via in-app chat if they are available for those
                  dates.
                </p>
              </div>
              <!--content-wrapper-->
            </ion-col>
          </ion-row>
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1 date-col">
              <div class="as-content-wrapper">
                <ion-list class="as-date-list">
                  <ion-item
                    lines="none"
                    id="date"
                    class="form-control date-time-item startDate"
                  >
                    <ion-label position="stacked">Start Date</ion-label>
                    <ion-datetime-button
                      datetime="datetimeStart"
                    ></ion-datetime-button>
                  </ion-item>
                  <ion-popover
                    :keep-contents-mounted="true"
                    class="date-time-popover"
                  >
                    <ion-datetime
                      id="datetimeStart"
                      v-model="dateStart"
                      @ionChange="getDates"
                      presentation="date"
                      color="secondary"
                    ></ion-datetime>
                  </ion-popover>

                  <!-- <ion-item class="icon-item" lines="none">
                      <ion-icon
                        @click="showEndDate()"
                        class="link-icon"
                        :icon="codeOutline"
                      ></ion-icon>
                    </ion-item> -->

                  <ion-item
                    lines="none"
                    id="date"
                    class="form-control date-time-item endDate"
                    style="position: relative"
                    @click="showEndDate()"
                  >
                    <ion-label position="stacked">End Date</ion-label>
                    <ion-datetime-button
                      datetime="datetimeEnd"
                      :disabled="isDisabled"
                      :class="{ error: dateErrorMessage }"
                    ></ion-datetime-button>
                  </ion-item>
                  <ion-popover
                    :keep-contents-mounted="true"
                    class="date-time-popover"
                  >
                    <ion-datetime
                      color="secondary"
                      id="datetimeEnd"
                      presentation="date"
                      @ionChange="getDates"
                      v-model="dateEnd"
                    ></ion-datetime>
                  </ion-popover>
                </ion-list>
                <p v-if="dateErrorMessage" class="dateError">
                  {{ dateErrorMessage }}
                </p>
              </div>
              <!--content-wrapper-->

              <!--plus-minus-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
            <div class="total-main-wrapper">
        <div class="inner-wrapper">
          <div class="prod-wrapper main-prod-wrapper" v-if="chosenProduct.name">
            <p>
              <span
                ><strong>{{ chosenProduct.name }}</strong
                >:</span
              >
              <span>${{ chosenProduct.price }} (+)</span>
            </p>
          </div>
          <!--main-prod-wrapper-->
          <div
            v-if="checkoutTotal.videoAdd"
            class="prod-wrapper add-on-wrapper"
          >
            <p>
              <span><strong>Video</strong>:</span>
              <span>${{ checkoutTotal.videoAdd }}</span>
            </p>
          </div>
          <div v-if="checkoutTotal.dataAdd" class="prod-wrapper add-on-wrapper">
            <p>
              <span><strong>Data</strong>:</span>
              <span>${{ checkoutTotal.dataAdd }}</span>
            </p>
          </div>
          <div
            v-if="checkoutTotal.dateSt"
            class="prod-wrapper add-on-wrapper top-date"
          >
            <p>
              <span
                ><strong
                  >Required <span v-if="!sameDate">Start </span>Date</strong
                >:</span
              >
              <span>{{ formatDateTime(checkoutTotal.dateSt)[0] }}</span>
            </p>
          </div>
          <div
            v-if="checkoutTotal.dateEn && !sameDate"
            class="prod-wrapper add-on-wrapper bottom-date"
          >
            <p>
              <span><strong>Required End Date</strong>:</span>
              <span>{{ formatDateTime(checkoutTotal.dateEn)[0] }}</span>
            </p>
          </div>

          <!--main-prod-wrapper-->

          <div class="important-information">
            <p>
              An analysis of several sessions and calibration's resulting in a
              completed calibration sheet. Included are recommendations and
              things to look for to get the most out of your efforts. These can
              be done to get a starting point for a fresh project, or a targeted
              calibration for the next outing.
            </p>
            <p>
              Our standard rates should cover most cases. Once we get your
              order, we'll confirm the scope of the calibration. Things like
              lots of sensors & camera angles , long histories (lots of
              sessions), etc. can add time. The calibration invoice & payment
              link is sent with the details discussed
            </p>
            <!-- <p>
              Once the calibrator has all the information they need, a price
              will be quoted in the chat. If you agree with the price you will
              be sent an invoice. On Payment of invoice the calibrator will be
              engaged for the date(s) you specified.
            </p> -->
          </div>
          <!--important-information-->
          <div class="prod-wrapper total-inner-wrapper">
            <p>
              <strong>Total</strong> &nbsp;<span style="font-size: 14px">
                (Estimate)</span
              >
            </p>
            <p>
              ${{
                chosenProduct.price +
                checkoutTotal.dataAdd +
                checkoutTotal.videoAdd
              }} (+)
            </p>
          </div>
          <div class="button-wrapper">
            <ion-button
              expand="block"
              @click="checkoutSubmit"
              class="checkout-button"
              >Purchase</ion-button
            >
          </div>
          <!--main-prod-wrapper-->
        </div>
        <!--inner-wrapper-->
      </div>
      <!--total-wrapper-->
          </ion-col>
        </ion-row>
      </ion-grid>
      </div>
      
      
      <!--as-page-wrapper-->
    </ion-content>
    <ion-content v-else>
      <ion-grid class="ST2Cont" style="max-width: 600px;">
        <ion-row class="ST2Row">
          <ion-col class="ST2Col1">
            <ion-skeleton-text
              :animated="true"
              style="height: 15vh"
            ></ion-skeleton-text>
            <br />
            <ion-skeleton-text
              :animated="true"
              style="height: 3vh"
            ></ion-skeleton-text>
            <br />
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ST2Cont"  style="max-width: 600px;">
        <ion-row class="ST2Row">
          <ion-col class="ST2Col1">
            <ion-skeleton-text
              :animated="true"
              style="height: 15vh"
            ></ion-skeleton-text>
            <br />
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <br />
            <ion-skeleton-text
              :animated="true"
              style="height: 3vh"
            ></ion-skeleton-text>
            <br />
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
            <ion-skeleton-text :animated="true"></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonThumbnail,
  IonCardSubtitle,
  IonIcon,
  IonDatetime,
  IonDatetimeButton,
  IonPopover,
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
  IonNote,
  onIonViewWillEnter,
  onIonViewDidEnter,
  IonSkeletonText,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import {
  chevronBackOutline
} from "ionicons/icons";
import { ref, watch } from "vue";
import { formatDateTime } from "@/modules/useDate.js";
import { useRoute, useRouter } from "vue-router";
import useShopContents from "@/modules/ShopContents";
import { useChat, getClient } from "@/modules/useTrackside";
import { useSendCheckout } from "@/modules/useSendEmbed.js";

const { products } = useShopContents();

const route = useRoute();
const router = useRouter();

const videoAddonChosen = ref(false);
const videoPrice = ref(50);
const dataAddonChosen = ref(false);
const dataPrice = ref(100);
const dateStart = ref(new Date().toISOString());
const dateEnd = ref(new Date().toISOString());
const checkoutTotal = ref({
  // product: product.value,
  videoAdd: 0,
  dataAdd: 0,
  dateSt: dateStart.value,
  dateEn: dateStart.value,
});

const chosenProduct = ref({
  id: "",
  name: "",
  subTitle: "",
  shortDescription: "",
  longDescription: "",
  price: 0,
});

const isDisabled = ref(true);
const dateErrorMessage = ref();

const toggleData = ref([]);

const showEndDate = () => {
  
  isDisabled.value = false;
};

onIonViewDidEnter(() => {
  videoAddonChosen.value = false;
  dataAddonChosen.value = false;
  const productId = route.query.product;
  (checkoutTotal.value.videoAdd = null),
    (checkoutTotal.value.dataAdd = null),
    (checkoutTotal.value.dateSt = dateStart.value),
    (checkoutTotal.value.dateEn = dateStart.value),
    (chosenProduct.value = products.value.find(
      (prod) => prod.id === productId
    ));
});

// const checkoutContents = ref({
//   dateStart: checkoutTotal.value.dateSt,
//   dateEnd: checkoutTotal.value.dateEn,
//   productId: chosenProduct.value.id,
//   productName: chosenProduct.value.name,
//   productPrice: chosenProduct.value.price,
//   video: videoAddonChosen.value,
//   videoPrice: videoAddonChosen.value ? checkoutTotal.value.videoAdd : "",
//   data: dataAddonChosen.value,
//   dataPrice: dataAddonChosen.value ? checkoutTotal.value.dataAdd : "",
// });

const { presentCheckout } = useSendCheckout();

const { sendMessage } = useChat();

const checkoutSubmit = () => {
  const estimate =
    chosenProduct.value.price +
    checkoutTotal.value.dataAdd +
    checkoutTotal.value.videoAdd;
  const todaysDate = formatDateTime(dateStart.value)[0];
  const startDateFormatted = formatDateTime(checkoutTotal.value.dateSt)[0];
  const endDateFormatted = formatDateTime(checkoutTotal.value.dateEn)[0];

  
  const checkoutContents = {
    dateStart: startDateFormatted,
    dateEnd: endDateFormatted,
    productId: chosenProduct.value.id,
    productName: chosenProduct.value.name,
    productPrice: chosenProduct.value.price,
    video: videoAddonChosen.value,
    videoPrice: videoAddonChosen.value ? checkoutTotal.value.videoAdd : "",
    data: dataAddonChosen.value,
    dataPrice: dataAddonChosen.value ? checkoutTotal.value.dataAdd : "",
    estimate: estimate,
  };

  
  // sendMessage(`${checkoutContents.value.productId} \n This is some tex`, '647e2cc29e0319d31455715e');
  // router.push({
  //   name: "ChatPage",
  //   params: {
  //     chatId: '647e2cc29e0319d31455715e',
  //   },
  // });

  const message = `Checkout Product List
(Submitted: ${todaysDate}):  \n\n
Product:
${checkoutContents.productName} - $${
    checkoutContents.productPrice
  } (+) \n\nUpgrades:
${
  checkoutContents.video
    ? `Video Analysis - $${checkoutContents.videoPrice}`
    : `Video Analysis - N/A`
}
${
  checkoutContents.data
    ? `Data Analysis - $${checkoutContents.dataPrice}`
    : `Data Analysis - N/A`
} \n
Total (Estimate):
$${estimate} (+)
\n\n${
    sameDate.value
      ? `Required Date: 
${checkoutContents.dateStart}\n`
      : `Required Start Date: 
${checkoutContents.dateStart}`
  }\n
${
  !sameDate.value
    ? `Required End Date: 
${checkoutContents.dateEnd}\n\n\n`
    : ""
}Thank you for your order! We will be in touch shortly to confirm.`;


  getClient().user.tracksideFetch('/billing/log-product', {
      method: "POST", 
      data: checkoutContents
    })
  presentCheckout(message);
};

const getDates = () => {
  //change the disabled end date if start is changed.
  if (isDisabled.value) {
    dateEnd.value = dateStart.value;
  }
  checkoutTotal.value.dateSt = dateStart.value;
  checkoutTotal.value.dateEn = dateEnd.value;
};

function validateDates() {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Reset time to 00:00:00

  const start = new Date(dateStart.value);
  start.setHours(0, 0, 0, 0); // Reset time to 00:00:00

  const end = new Date(dateEnd.value);
  end.setHours(0, 0, 0, 0); // Reset time to 00:00:00

  if (start < today || end < today) {
    dateErrorMessage.value = "Selected date is in the past";
    return false;
  }

  if (end < start) {
    dateErrorMessage.value = "End date cannot be before the start date";
    return false;
  }
  dateErrorMessage.value = "";
  return true; // Dates are valid
}

watch([dateStart, dateEnd], () => {
  if (!validateDates()) {
    
  }
});

const handleToggleChange = () => {
  
  if (videoAddonChosen.value === true) {
    checkoutTotal.value.videoAdd = videoPrice.value;
  } else {
    checkoutTotal.value.videoAdd = null;
  }
  if (dataAddonChosen.value === true) {
    checkoutTotal.value.dataAdd = dataPrice.value;
  } else {
    checkoutTotal.value.dataAdd = null;
  }
};

const sameDate = ref(true);

watch(checkoutTotal.value, (newVal) => {
  const dateSt = new Date(newVal.dateSt);
  const dateEn = new Date(newVal.dateEn);

  // reset time to 00:00:00
  dateSt.setHours(0, 0, 0, 0);
  dateEn.setHours(0, 0, 0, 0);

  if (dateSt.getTime() !== dateEn.getTime()) {
    sameDate.value = false;
    
  } else {
    sameDate.value = true;
  }
  
});
</script>

<style scoped lang="scss">
.home-page {
  margin-bottom: 30px;
}
.ST2Cont {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  & .ST2Row {
    & .ST2Col1 {
      & .title-content-wrapper {
        padding-left: 0;
        padding-right: 0;

        & h2 {
        }

        & p {
          text-align: left;
        }
      }
    }
  }
}
.as-content-wrapper {
  & .as-date-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & ion-item {
      &.startDate {
        margin-top: 2px;

        & ion-label {
        }
      }

      &.endDate {
        & ion-label {
          padding-top: 0px;
        }
      }

      &.icon-item {
        padding-left: 6px;

        & ion-icon {
        }
      }

      & ion-datetime-button {
        &.error {
          &::part(native) {
            color: red;
          }
        }
      }

      &.dateDisabled {
        & ion-datetime-button {
        }
      }

      &::part(native) {
        padding-inline-start: 0;
        --ion-safe-area-right: 0;
        --inner-padding-end: 0;
      }
    }
  }

  & .dateError {
    text-align: center;
    color: red;
    padding-right: 15px;
  }
}

.title-content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.title-content-wrapper h2 {
  font-weight: 700;
  text-align: center;
}

.title-content-wrapper p {
  text-align: center;
}

.total-main-wrapper {
  margin: 0 auto;
  border-radius: var(--as-border-radius);
  background-color: white;
  // padding: 30px 30px 20px;
  // margin-bottom: 30px;

  & .inner-wrapper {
    & .important-information {
      border-top: solid 1px black;
      margin-top: 15px;
    }

    & .prod-wrapper {
      &.top-date {
        border-top: solid 1px black;
        margin-top: 15px;
      }

      &.bottom-date {
      }

      & p {
        font-size: 17px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
      }
    }

    & .button-wrapper {
      width: 100%;
      display: block;
      padding-left: 0;
      padding-right: 0;
      margin-top: 15px;
    }

    & .total-inner-wrapper {
      margin-top: 15px;
      border-top: solid 1px black;
      padding-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 15px;

      & p {
        align-items: center;
      }
    }
  }
}

ion-card {
  background: var(--ion-color-white);
  position: relative;
  overflow: visible;
}

ion-card-title {
  font-size: 17px;
  margin-bottom: 7.5px;
  padding-right: 30px;
}

ion-card-subtitle {
  font-size: 14px;
}

.price-circle {
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 8px;
  height: 60px;
  width: 60px;
  background-color: var(--ion-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
  z-index: 10000;
  font-size: 16px;
}

.button-wrapper {
  padding: 15px;
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
}

.as-card {
  margin-top: 20px;

  & ion-card-header {
  }

  & ion-card-content {
    & .as-content {
      padding-bottom: 15px;

      & p {
      }
    }
  }
}

.as-card.first-card {
  margin-top: 20px;
}

.plus-minus-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.plus-minus-wrapper ion-icon {
  font-size: 23px;
  border: solid 1px black;
  color: black;
  padding: 2.5px 5px;
}

.plus-minus-wrapper ion-icon:nth-child(2) {
  border-right: solid 0px;
}

.plus-minus-wrapper ion-icon:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.number-wrapper {
  border: solid 1px black;
  border-right: solid 0px black;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.number-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.button-wrapper {
  & .checkout-button {
    &::part(native) {
      background: var(--as-red-gradient-button);
    }
  }
}
</style>
