<template>
  <ion-page>
    <ion-content>
      <SessionOutingLanding
        :TurnData="TurnData"
        hideButtons
      ></SessionOutingLanding>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  onIonViewWillEnter,
} from "@ionic/vue";
import { ref, shallowRef, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useTurnData } from "@jackallee/usetrackside/reactive";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import SessionOutingLanding from "@/components/session/SessionOutingLanding.vue";
const { isLoading } = useLoadingSpinner();
const sessionId = ref(undefined);
const { TurnData, createTurnData, loadTurnData, uploadTurnData } =
  useTurnData(sessionId);
const isSessionSetup = ref(false);

const route = useRoute();
const router = useRouter();
const query = route.query;

// watch(
//   () => route.query.id,
//   (newId) => {
//     if (newId) {
//       isLoading.value = true;
//       sessionId.value = newId;
//       loadTurnData({
//         onSuccess: () => {
//           isSessionSetup.value = true;
//         },
//         onComplete: () => {
//           isLoading.value = false;
//         },
//       });
//     }
//   }
// );

onIonViewWillEnter(() => {
  if (route.query.id) {
    isLoading.value = true;
    sessionId.value = route.query.id;
    loadTurnData({
      onSuccess: () => {
        isSessionSetup.value = true;
        TurnData.value._id = route.query.id;
      },
      onComplete: () => {
        isLoading.value = false;
      },
    });
  }
});
</script>
