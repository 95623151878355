import { createApp } from "vue";

import { 
  loadUser,
  useUser,
  getClient,
  initClient,
} from "@jackallee/usetrackside/reactive";

// import { useUpdateSW } from "@/modules/useUpdateSW";
import App from "./App.vue";
import router from "./router";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/*SASS */
import "@/styles/scss-bundle.scss";

/* Theme variables */
import "@/styles/theme/variables.css";
// import { setupProfile } from "./modules/useProfile";
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();
initClient(import.meta.env.VITE_API_URL);
// const { PWAState } = useUpdateSW();
loadUser().then(() => {
  const { userState } = useUser();
  const client = getClient()
  // client.baseURL = import.meta.env.VITE_API_URL;
  // setupProfile();
  router.beforeEach((to) => {
    if(to.meta.isPublic) return true;
    if (userState.isPaying === false && !to.meta.isFree) return { name: "SubscriptionLandingPage" };

    if (userState.loggedIn) {
      return true;
    } else {
      return { name: "Entry" };
    }
  });

  const app = createApp(App)
    .use(IonicVue, {
      mode: "ios",
      scrollAssist: false,
    })

    .use(router);
  if (import.meta.env.MODE !== "development") {
    Sentry.init({
      app,
      environment: import.meta.env.MODE,
      release: "new-calibration-state",
      dsn: "https://6ade0b63d3534fc8b07e40e2436eb918@o4504850489671680.ingest.sentry.io/4504850492686336",
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [
            "localhost:517*",
            "app.057motorsports.com",
            /^\//,
          ],
        }),
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: true,
        }),
        new Sentry.Integrations.Breadcrumbs({
          console: false,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }
  router.isReady().then(() => {
    app.mount("#app");
  });
});
//
