<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page>
    <!-- <ion-header> -->
    <HeaderCompCalibration :triggerModal="openModal" :promptLeave="showToolbar" :editOrNew="editedOrNew" :title="title"> 
    </HeaderCompCalibration>
    <ion-toolbar v-if="showToolbar" class="segment-toolbar">
      <ion-segment
        v-if="showButtons"
        @ionChange="setComponent"
        id="calSection"
        value="by-component"
      >
        <ion-segment-button value="by-corner"> By Corner </ion-segment-button>
        <ion-segment-button value="by-component">
          By Component
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
    <!-- <HeaderCompCalibration :promptLeave="false" title="Calibration Saved!" /> -->
    <!-- </ion-header> -->

    <ion-content class="ion-align-items-center">
      <KeepAlive>
        <component
          keep-alive
          :is="currentView"
          :calibration="calReactive"
        ></component>
      </KeepAlive>
      <div class="as-error-wrapper">
        <!-- <p
          class="button-global-content-wrapper error-message-global"
          v-if="CalibrationState.error">
          
          {{ CalibrationState.message }}
        </p> -->
      </div>
      <div v-if="showButtons" class="button-global-content-wrapper">
        <div class="button-wrapper cancel-button">
          <ion-button fill="outline" @click="cancelHome()"> Cancel </ion-button>
        </div>
        <div class="button-wrapper submit-button">
          <ion-button @click="handleSave()"> Save Calibration </ion-button>
        </div>
      </div>
      <!-- <ion-modal ref="modal" trigger="open-modal" class="calibrationModal">
        <CalibrationModal :fetchCal="false" :calibration="calReactive" />
      </ion-modal> -->
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonHeader,
  IonModal,
  alertController,
  modalController,
} from "@ionic/vue";
import { ref, shallowRef } from "vue";
import { useRoute, useRouter } from "vue-router";
import HeaderCompCalibration from "../components/header-components/HeaderCompCalibration.vue";
import CalibrationGlobal from "@/components/calibration/by-component/CalibrationGlobal.vue";
import ByCornerLanding from "@/components/calibration/by-corner/ByCornerLanding.vue";
import CalibrationModal from "@/components/modal-components/CalibrationModal.vue";
import CalibrationOverview from "@/components/calibration/CalibrationOverview.vue";
import { useCalibration } from "@/modules/useTrackside.js";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useToast, useErrorToast } from "@/modules/useToasts.js";

const { isLoading } = useLoadingSpinner();
const calibrationId = ref(undefined);
const { calReactive, loadCal, save } = useCalibration(calibrationId);

const currentView = shallowRef(CalibrationGlobal);
const route = useRoute();
const router = useRouter();

const showButtons = ref(true);

const showToolbar = ref(true);

const title = ref("Calibration");

const newCalibration = ref(false);
const editedCalibration = ref(false);

const editedOrNew = ref('new');

const query = route.query;

if (query.id) {
  isLoading.value = true;
  calibrationId.value = query.id;
  loadCal({
    onComplete: () => {
      isLoading.value = false;
      editedOrNew.value = 'edited';
     
    },
    onError: (e) => {
      useErrorToast(e);
    },
  });
  
} else {
  if (!query.car)
    throw new Error("Attempted to Create a calibration with no car");
  // calReactive.value.CarId = query.car;
}

// defineProps({
//   carId: {
//     type: String,
//     required: true,
//   },
//   calibrationId: {
//     type: String,
//     required: false,
//   },
// });

function handleSave() {
  console.log(query)
  isLoading.value = true;
  const { isError, error } = save({
    carId: query.car,
    onComplete: () => {
      isLoading.value = false;
    },
    onSuccess: (response) => {
      currentView.value = CalibrationOverview;
      
      calReactive.value._id = response.data.returnedId;
      showButtons.value = false;
      route.meta.leaveAlert = false;
      showToolbar.value = false;
      title.value = "Calibration Saved!";
      useToast({
        message: "Calibration Saved Successfully!",
        duration: 3000,
      });
    },
    onError: (e) => {
      console.error(e);
      useErrorToast(e);
    },
  });
}

function setComponent(e) {
  var switcherVal = e.target.value;
  if (switcherVal === "by-corner") {
    currentView.value = ByCornerLanding;
  } else {
    currentView.value = CalibrationGlobal;
  }
}

async function cancelHome() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will return you to the Garage Home page and lose all entered data",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          router.push({ name: "GarageLanding" });
        },
      },
    ],
  });

  await alert.present();
}

async function openModal() {
  const modal = await modalController.create({
    component: CalibrationModal,
    componentProps: {
      calibration: calReactive.value,
      fetchCal: false,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}
</script>
<style scoped>
/* global */

ion-grid {
  /* padding-bottom: 30px; */
  background-color: transparent;
  margin: 15px;
}

.item-row ion-list {
  display: flex;
  flex-wrap: wrap;
}

.item-row ion-item {
  flex: 50%;
}

.global-cal-row {
  margin-top: 30px;
  background-color: var(--as-ion-grid-background);
}

/* page specific */

.sub-title {
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 0;
}

.item-row {
  margin-top: 30px;
  background-color: var(--as-ion-grid-background);
}

.item-row .single-list .single-item {
  flex: 100%;
}

.title-row {
  background-color: var(--as-ion-grid-background);
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
  flex-wrap: nowrap;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
       background: var(--as-red-gradient-button);

}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}

.as-chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
}

.enteredChip {
  padding: 0.375rem 0.75rem;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.notEnteredChip {
  padding: 0.375rem 0;
}

.notEnteredChip ion-input {
  --padding-top: 0;
  --padding-bottom: 0;
}
</style>
