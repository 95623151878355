<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-accordion>
    <ion-item slot="header">
      <ion-label>{{ asProps.title }}</ion-label>
    </ion-item>
    <ion-list class="single-list" slot="content">
      <ion-item class="single-item">
        <ion-label position="stacked"> Left Front </ion-label>
        <MultipleInput :modelValue="LF"
          @remove="$emit('update:LF', $event.value)"
          @add="$emit('update:LF', $event.value)" />
      </ion-item>
      <ion-item class="single-item">
        <ion-label position="stacked"> Right Front </ion-label>
        <MultipleInput
          :modelValue="RF"
          @add="$emit('update:RF', $event.value)"
          @remove="$emit('update:RF', $event.value)"
          shimSide="RF"
          :calItemPass="asProps.calItem"
        />
      </ion-item>
      <ion-item class="single-item">
        <ion-label position="stacked"> Left Rear </ion-label>
        <MultipleInput
          :modelValue="LR"
          @add="$emit('update:LR', $event.value)"
          @remove="$emit('update:LR', $event.value)"

          shimSide="LR"
          :calItemPass="asProps.calItem"
        />
      </ion-item>
      <ion-item class="single-item">
        <ion-label position="stacked"> Right Rear </ion-label>
        <MultipleInput
          :modelValue="RR"
          @add="$emit('update:RR', $event.value)"
          @remove="$emit('update:RR', $event.value)"
          shimSide="RR"
          :calItemPass="asProps.calItem"
        />
      </ion-item>
    </ion-list>
  </ion-accordion>
</template>

<script setup>
import { IonAccordion, IonItem, IonLabel, IonList } from "@ionic/vue";

import { ref } from "vue";

import MultipleInput from "./MultipleInput.vue";

const asProps = defineProps({
  title: String,
  LF: Array,
  RF: Array,
  LR: Array,
  RR: Array,
  calItem: String,
});

const emit = defineEmits(["update:LF", "update:RF", "update:LR", "update:RR"]);

const test = ref();
function addTest(e) {
  emit("update:RF", e.value);
  
}
</script>

<style scoped>
.as-chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
}

.enteredChip {
  padding: 0.375rem 0.75rem;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.notEnteredChip {
  padding: 0.375rem 0;
}

.notEnteredChip ion-input {
  --padding-top: 0;
  --padding-bottom: 0;
}
</style>
