<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <HeaderComp>
    <ion-buttons v-if="!props.editOrNew" slot="start" @click="showOptions()">
      <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
    </ion-buttons>
    <ion-buttons v-if="props.editOrNew === 'new'" slot="start" @click="goToCarLanding()">
      <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
    </ion-buttons>
    <ion-buttons v-if="props.editOrNew === 'edited'" slot="start" @click="goToCarLandingEdit()">
      <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
    </ion-buttons>

    <ion-buttons slot="end" v-if="props.promptLeave">
      <ion-button @click="triggerModal" id="open-modal">
        <ion-icon slot="icon-only" :icon="clipboardOutline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-icon></ion-icon>
    <slot></slot>
  </HeaderComp>
</template>
<script setup>
import {
  IonButtons,
  IonTitle,
  IonIcon,
  IonButton,
  modalController,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { clipboardOutline, chevronBackOutline, addOutline } from "ionicons/icons";
import CalibrationModal from "../modal-components/CalibrationModal.vue";
import { useRoute, useRouter } from "vue-router";

import { alertController } from "@ionic/vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  allowLeave: {
    type: Boolean,
    required: false,
    default: false,
  },
  promptLeave: {
    type: Boolean,
    required: false,
    default: true,
  },
  triggerModal: {
    type: Function,
    required: false,
    default: ()=>{},
  },
  editOrNew: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const route = useRoute();

console.log(props.newCali);

async function showOptions() {
  if (props.promptLeave) {
    const alert = await alertController.create({
      header: "Warning",
      message:
        "Pressing OK will return you to the Garage Home page and lose all entered data",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "OK",
          handler: () => {
            router.push({ name: "GarageLanding" });
          },
        },
      ],
    });

    await alert.present();
  } else {
    router.push({ name: "GarageLanding" });
  }
}

console.log(route.query.car);

const goToCarLanding = () => {
  router.push({
      name: "CarLandingPage",
      params: { carId: route.query.car },
    });
};

const goToCarLandingEdit = () => {

  router.push({
      name: "CarLandingPage",
      params: { carId: route.query.car },
    });

};

async function openModal() {
  // 
  // const corners = ["RF", "LF", "RR", "LR"];
  // if (corners.includes(corner)) {
  //   var tireData = {
  //     TireData: calReactive.TireData[corner],
  //     ShockData: calReactive.ShockData[corner],
  //   };

  const modal = await modalController.create({
    component: CalibrationModal,
    // componentProps: {
    //   TireData: tireData,
    // },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}
</script>

<style scoped>
ion-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
</style>
