<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <!-- <SlideoutMenu contentId="main-content" /> -->
    <div class="ion-page">
      <HeaderComp title="Track Performance" />
      <ion-content class="as-content">
        <!-- <ion-grid class="ST1Cont" style="border-top: solid 8px black;">
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    <ion-button
                      size="default"
                      expand="block"
                      @click="newSession()"
                    >
                      <ion-icon
                        slot="start"
                        :icon="addCircleOutline"
                      ></ion-icon>
                      New Session
                    </ion-button>
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid> -->
        <ion-grid
          class="ST2Cont"
          v-if="!UserProfileReactive.sessions.isFetching"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div
                class="title-wrapper-main"
                style="
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
              >
                <p class="cali-section-title"><strong>Sessions </strong></p>
                <ion-button
                  v-if="garage.length > 0"
                  size="default"
                  @click="newSession()"
                  class="add-button"
                  fill="solid"
                >
                  <ion-icon slot="end" :icon="addCircle"></ion-icon>
                  New Session
                </ion-button>
                <ion-button
                  v-else
                  size="default"
                  @click="noCar()"
                  class="add-button"
                  fill="solid"
                >
                  <ion-icon slot="end" :icon="addCircle"></ion-icon>
                  New Session
                </ion-button>
              </div>
              <!--title-wrapper-->
              <!-- <ion-item class="item-previous-search" lines="none" v-if="sessionResults.length > 0">
                <ion-label position="stacked"
                  ><strong>Previous </strong></ion-label
                >
                <ion-searchbar
                  :debounce="1000"
                  @ionChange="handleChange($event)"
                  placeholder="Find Session"
                  class="track-search"
                ></ion-searchbar>
              </ion-item> -->
              <div
                class="search-content-wrapper"
                v-if="sessionResults.length > 0"
              >
                <!-- <ion-card v-for="session in sessionResults" :key="session.id"> -->
                  <div class="global-searchbar-wrapper">
                    <ion-searchbar v-model="search"></ion-searchbar>
                  </div><!--searchbar-wrapper-->
                 
                <ion-list class="garage-page list-previous search-list">
                  <ion-item
                    v-for="session in filteredData"
                    :key="session.id"
                    lines="none"
                  >
                    <ListTemplates
                      :chatButton="chatButton"
                      :outingsButton="outingsButton"
                      :editButton="editButton"
                      @edit-clicked="handleEditClicked(session.id)"
                      @outings-clicked="handleOutingsClicked(session.id)"
                      @chat-clicked="presentEmbed(true, 'session', session.id, session.title)"
                    >
                      <template #text-wrapper>
                        <div class="text-wrapper session-landing">
                          <div class="title-wrapper">
                            <p class="cal-sess-title">
                              <strong>{{ session.title }}</strong>
                            </p>
                            <p class="cal-sess-carname">
                              {{ session.date }} @ {{ session.time }}
                            </p>
                          </div>
                          <!--title-wrapper-->
                          <div class="cal-body-content">
                            <p>
                              {{ session.track }}
                            </p>
                            <p>{{ session.car }}</p>
                          </div>
                          <!--cal-body-content-->
                        </div>
                        <!--text-wrapper-->
                      </template>
                    </ListTemplates>
                  </ion-item>
                </ion-list>
                <!-- <ion-card-content class="cal-card-content">
                    <div class="title-wrapper">
                      <p class="cal-sess-title">
                        <strong>{{ session.title }}</strong>
                      </p>
                      <p class="cal-sess-carname">
                        {{ session.date }} @ {{ session.time }}
                      </p>
                    </div>
                    <div class="cal-body-content">
                      <p>
                        {{ session.track }}
                      </p>
                      <p>{{ session.car }}</p>
                    </div>

                    <div class="button-wrapper-cal" v-if="session.title !== ''">
                      <ion-button
                        class="edit-button cal-button"
                        style="font-weight: 700"
                        size="small"
                        fill="outline"
                        @click="requestSession(session.id)"
                      >
                        <ion-icon slot="start" :icon="pencilOutline"></ion-icon>
                        Edit
                      </ion-button>


                      <ion-button
                        class="view-button cal-button"
                        style="font-weight: 700"
                        size="small"
                        fill="outline"
                        @click="gotoOutings(session.id)"
                      >
                        <ion-icon
                          slot="start"
                          :icon="infiniteOutline"
                        ></ion-icon>
                        Outings
                      </ion-button>

                      <ion-button
                        class="chat-button cal-button"
                        style="font-weight: 700"
                        size="small"
                        fill="outline"
                        @click="presentEmbed(true, 'session', session.id)"
                      >
                        <ion-icon
                          slot="start"
                          :icon="chatbubbleOutline"
                        ></ion-icon>
                        Chat
                      </ion-button>
                    </div>
                  </ion-card-content>
                </ion-card> -->
              </div>
              <!--search-content-wrapper-->
              <div class="empty-content-wrapper" v-else>
                <ion-card>
                  <ion-card-content class="cal-card-content">
                    <p class="cal-sess-title">
                      <strong>No sessions found.</strong>
                    </p>
                  </ion-card-content>
                </ion-card>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont" v-else>
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <ion-skeleton-text
                :animated="true"
                style="height: 15vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text
                :animated="true"
                style="height: 3vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
  onIonViewWillEnter,
  IonSkeletonText,
  alertController,
  IonItem,
  IonList,
  IonSearchbar,
} from "@ionic/vue";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import {
  addCircle,
  chatbubbleOutline,
  infiniteOutline,
  pencilOutline,
} from "ionicons/icons";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import useSearchFilter from '@/modules/useSearchFilter.js';
import { useTurnData } from "@jackallee/usetrackside/reactive";
import { useGarage } from "@jackallee/usetrackside/reactive";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useProfile, fetchSessions } from "@/modules/useProfile.js";
import { useSessionModal } from "@/modules/useModals.js";
import { useSendEmbed } from "@/modules/useSendEmbed.js";
import { formatDateTime } from "@/modules/useDate.js";
import ListTemplates from "@/components/list-templates/ListTemplates.vue";

const editButton = {
  active: true,
};

const outingsButton = ref({
  active: true,
});

const chatButton = ref({
  active: true,
});

const handleOutingsClicked = (sessionId) => {
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { id: sessionId, outing: true },
  });
  return;
};

const handleEditClicked = (sessionId) => {
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { id: sessionId },
  });
};



const handleChatClicked = () => {
  
};

const { UserProfileReactive } = useProfile();
// const { isLoading } = useLoadingSpinner();
const router = useRouter();
const { presentEmbed } = useSendEmbed();

const { garage, getCars } = useGarage();
// const garage = ref([]);
const carResults = ref([]);
//

const { isLoading } = getCars();

const sessionList = ref([]);
const areSessions = ref(false);
const skeletonIsLoading = ref(true);

// let sessionResults = ref();
const sessionResults = computed(() => {
  return UserProfileReactive.sessions.value.map((session) => {
    try {
      let carDetail =
        session.car
          ? session.car
          : null;
      const carString = carDetail
        ? `${carDetail.make} ${carDetail.model} (${carDetail.year}) | ${carDetail.number}`
        : "";
      skeletonIsLoading.value = false;
      let dateString = session.Setup?.date ? new Date(session.Setup.date) : null;
      let splitDate = formatDateTime(dateString)[0];
      let splitTime = formatDateTime(dateString)[1];
      console.log(session);
      return {
        title: session.Setup?.title || "",
        id: session._id || "",
        car: carString || "",
        // date: session.Setup.date,
        time: splitTime,
        date: splitDate,
        track: session.track?.name || "",
      };
    } catch (e) {
      console.error(e);
      return {
        title: "",
        id: "",
        car: "",
        time: "",
        date: "",
        track: "",
      };
    }
  });
});

const { search, filteredData } = useSearchFilter(sessionResults, ['title', 'id', 'car', 'time', 'date', 'track']);

defineExpose({ search, filteredData });

// const search = ref('');

// // Create a computed property that filters the sessions based on the search query
// const filteredSessions = computed(() => {
//   return sessionResults.value.filter(session => 
//     session.title.toLowerCase().includes(search.value.toLowerCase()) ||
//     session.id.toLowerCase().includes(search.value.toLowerCase()) ||
//     session.car.toLowerCase().includes(search.value.toLowerCase()) ||
//     session.time.toLowerCase().includes(search.value.toLowerCase()) ||
//     session.date.toLowerCase().includes(search.value.toLowerCase()) ||
//     session.track.toLowerCase().includes(search.value.toLowerCase())
//   )
// })

// defineExpose({ search, filteredSessions })

async function noCar() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Please create a car before creating a session. Clicking OK will take you to the Garage.",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          router.push({ name: "GarageLanding" });
        },
      },
    ],
  });

  await alert.present();
}

onIonViewWillEnter(() => {
  // 
  // 
  carResults.value = garage.value;
  // 
  sessionList.value = sessionResults.value;
  areSessions.value = true;
  fetchSessions();
  // 
});

function newSession() {
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
  });
}

//this is linked to @ionChange on the <ion-searchbar></ion-searchbar>
const handleChange = (event) => {
  const query = event.target.value.toLowerCase();
  // 
  sessionResults.value = sessionList.value.filter(
    (d) =>
      d.title.toLowerCase().indexOf(query) > -1 ||
      d.car.toLowerCase().indexOf(query) > -1
  );
  //
};

// function requestSession(sessionId) {
//   router.push({
//     name: "SessionEditor",
//     params: { editSessionId: self.crypto.randomUUID() },
//     query: { id: sessionId },
//   });
//   return;
// }

function gotoOutings(sessionId) {
  // router.push({
  //   name: "OutingsPage",
  //   //params: { editSessionId: self.crypto.randomUUID() },
  //   query: { id: sessionId },
  // });
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { id: sessionId, outing: true },
  });
  return;
}
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/sessions/SessionsLandingPage";
</style>
