import { useUser } from "@jackallee/usetrackside/reactive";
import { createRouter, createWebHistory } from "@ionic/vue-router";

import licenseRoutes from "./license";
import auxiliaryRoutes from "./auxiliary";
import devRoutes from "./dev";

// import SessionsPage from "@/views/SessionsPage.vue";
import ChatPage from "@/views/trackside/chats/ChatPage.vue";
import ChatLandingPage from "@/views/trackside/chats/ChatLandingPage.vue";
import CalibratorSingle from "@/components/as-calibrators/CalibratorSingle.vue";
import SessionsLandingPage from "@/views/trackside/sessions/SessionsLandingPage.vue";
import SessionOutingLanding from "@/components/session/SessionOutingLanding.vue";
import SessionTurns from "@/components/session/SessionTurns.vue";
import HomePage from "@/views/trackside/home/HomePage.vue";
import ASCalibrationLandingPage from "@/views/trackside/garage/ASCalibrationLandingPage.vue";
import AddNewCar from "@/components/calibration/AddNewCar.vue";
import CarLandingPage from "@/views/CarLandingPage.vue";
import CalibratorListPage from "@/views/trackside/calibrators/CalibratorListPage.vue";
import CaliAdminGlobal from "@/components/cali-tools-admin/cali-tools-subpages/CaliAdminGlobal.vue";
import CaliAdminNewCali from "@/components/cali-tools-admin/cali-tools-subpages/CaliAdminNewCali.vue";
import ShopLandingPage from "@/views/shop-pages/ShopLandingPage.vue";
import ShopAddOnPage from "@/views/shop-pages/ShopAddOnPage.vue";
import ShopCheckoutPage from "@/views/shop-pages/ShopCheckoutPage.vue";
import CheckoutPage from "@/views/subscription/CheckoutPage.vue";
import SubscriptionLandingPage from "@/views/subscription/SubscriptionLandingPage.vue";

import SessionEditor from "@/views/SessionsEditor.vue";
// import OutingsEditor from "@/views/OutingsEditor.vue";
import CalibrationEditor from "@/views/CalibrationEditor.vue";
import OutingsViewPage from "@/views/OutingsViewPage.vue";
import CheckoutSuccessFail from "@/views/subscription/CheckoutSuccessFail.vue";
import PostRegisterPage from "@/views/subscription/PostRegisterPage.vue";
import ShopProdInfoPage from "@/views/shop-pages/ShopProdInfoPage.vue";
import SetTracksPage from "@/views/admin-pages/SetTracksPage.vue";
import Dashboard from "@/views/trackside/home/Dashboard.vue";

const { userState } = useUser();

const _routes = [
  {
    path: "/",
    redirect: (to) => {
      if (userState.loggedIn || to.meta.isPublic) {
        return "/garage";
      } else {
        return { name: "Entry" };
      }
    },
  },
  {
    path: "/entry/",
    name: "Entry",
    component: () => import("../views/trackside/home/EntryPage.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/login/",
    name: "Login",
    component: () => import("../components/UserEntry/AS-LoginForm.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/register/",
    name: "Register",
    component: () => import("../components/UserEntry/RegisterForm.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/profile/",
    name: "Profile",
    component: () => import("../components/UserEntry/UserProfile.vue"),
    meta: { isFree: true},
  },
  {
    path: "/chat-landing/chat/:chatId",
    name: "ChatPage",
    component: ChatPage,
  },
  {
    path: "/chat-landing/",
    name: "ChatLandingPage",
    component: ChatLandingPage,
  },
  {
    path: "/sessions/",
    name: "SessionsLanding",
    component: SessionsLandingPage,
  },
  {
    path: "/sessions/turns",
    name: "OutingsPage",
    component: OutingsViewPage,
  },
  {
    path: "/garage/",
    name: "GarageLanding",
    component: ASCalibrationLandingPage,
    props: (to) => ({ data: to.meta.data }),
  },
  {
    path: "/garage/add-car",
    name: "AddNewCar",
    component: AddNewCar,
    meta: { leaveAlert: true },
  },
  {
    path: "/caleditor/:editSessionId",
    name: "CalibrationEditor",
    component: CalibrationEditor,
    meta: { leaveAlert: true },
  },
  {
    path: "/sessionseditor/:editSessionId",
    name: "SessionEditor",
    component: SessionEditor,
    meta: { leaveAlert: true },
  },
  // {
  //   path: "/outingseditor/:editSessionId",
  //   name: "OutingsEditor",
  //   component: OutingsEditor,
  //   meta: { leaveAlert: true },
  // },
  {
    path: "/garage/car-landing/:carId",
    name: "CarLandingPage",
    component: CarLandingPage,
  },

  {
    path: "/calibrators/",
    name: "CalibratorList",
    component: CalibratorListPage,
  },

  {
    path: "/calibrators/:calibratorId",
    name: "CalibratorSingle",
    component: CalibratorSingle,
  },
  {
    path: "/shop/",
    name: "ShopLandingPage",
    component: ShopLandingPage,
  },
  {
    path: "/shop/addon/",
    name: "ShopAddOnPage",
    component: ShopAddOnPage,
  },
  {
    path: "/shop/:productId",
    name: "ShopProdInfoPage",
    component: ShopProdInfoPage,
  },
  {
    path: "/checkout/",
    name: "Checkout",
    component: CheckoutPage,
    meta: { isFree: true, hideBar: true },
  },
  {
    path: "/subscription-landing/",
    name: "SubscriptionLandingPage",
    component: SubscriptionLandingPage,
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/subscription/checkout/",
    name: "CheckoutSuccessFail",
    component: CheckoutSuccessFail,
    meta: { isFree: true, hideBar: false },
  },
  {
    path: "/post-register/",
    name: "PostRegisterPage",
    component: PostRegisterPage,
  },
  {
    path: "/set-tracks/",
    name: "SetTracksPage",
    component: SetTracksPage,
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: '/:pathMatch(.*)*', // or '/index.html'
    beforeEnter: (to, from, next) => {
      next('/')
    }
  }
];
const routes = _routes.concat(licenseRoutes, auxiliaryRoutes);

if(import.meta.env.MODE === 'development') {
  routes.push(...devRoutes);
}

console.log(routes);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
