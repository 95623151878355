<template>
  <ion-app>
    <ion-tabs class="as-tabs">
      <SlideoutMenu contentId="main-content" />
      <ion-router-outlet id="main-content"></ion-router-outlet>
      <!-- v-if="(userState.loggedIn)"  :animated="false"-->
      <ion-tab-bar
        class="as-tab-bar"
        color="primary"
        v-if="!$route.meta.hideBar"
      >
        <!-- /* BELOW ARE THE TABS FOR THE TRACKSIDE APP!! 
        FURTHER DOWN THE PAGE ARE THE TABS FOR THE CALIBRATOR TOOLS */ -->

        <!-- //TRACKSIDE APP!! THIS IS THE TAB
        FOR THE CALIBRATOR LIST -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('calibrators') }"
          class="as-tab-button"
          tab="profile"
          @click="tabCheck('CalibratorList', 'Calibrator List')"
          :disabled="!userState.loggedIn"
          v-if="!$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon gear-icon"
            :icon="buildOutline"
          ></ion-icon>
          <ion-label class="as-label">Calibrators</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!--//TRACKSIDE APP!! THIS IS THE TAB FOR THE GARAGE -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('garage') }"
          tab="garage"
          @click="tabCheck('GarageLanding', 'Garage')"
          :disabled="!userState.loggedIn"
          v-if="!$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon garage-icon"
            src="/assets/images/new-menu-icons/Garage_selected.svg"
          ></ion-icon>
          <ion-label>Garage</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //TRACKSIDE APP!! THIS IS THE TAB FOR THE HOMEPAGE -->
        <ion-tab-button
          class="shop-button"
          tab="logo"
          @click="tabCheck('Dashboard', 'Dashboard')"
          v-if="!$route.meta.caliTools"
        >
          <div class="logo-wrapper">
            <div class="inner-logo-wrapper">
              <img
                class="as-logo"
                src="/assets/images/as-images/1024x1024_white_transparentBG.png"
                alt=""
              />
            </div>
          </div>
          <!--logo-wrapper-->
        </ion-tab-button>

        <!-- //TRACKSIDE APP!! THIS IS THE TAB FOR THE SESSIONS -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('session') }"
          tab="session"
          @click="tabCheck('SessionsLanding', 'Session Landing')"
          :disabled="!userState.loggedIn"
          v-if="!$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon session-icon"
            src="/assets/images/new-menu-icons/Session_Track_selected.svg"
          ></ion-icon>
          <ion-label>Session</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //TRACKSIDE APP!! THIS IS THE TAB FOR THE CONVERSATIONS -->
        <ion-tab-button
          tab="chat"
          @click="tabCheck('ChatLandingPage', 'Chat Landing')"
          :disabled="!userState.loggedIn"
          :class="{ 'tab-selected': $route.path.includes('chat') }"
          v-if="!$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon chat-icon"
            src="/assets/images/new-menu-icons/Chat_Track_selected.svg"
          ></ion-icon>
          <ion-label> Chats </ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //CALI TOOLS!! THIS IS THE TAB FOR THE add new calibration -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('CaliAdminNewCali') }"
          tab="garage"
          @click="tabCheck('CaliAdminNewCali')"
          :disabled="!userState.loggedIn"
          v-if="$route.meta.caliTools"
        >
          <ion-icon class="as-tab-icon" :icon="addCircleOutline"></ion-icon>
          <ion-label>New</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //CALI TOOLS!! THIS IS THE TAB FOR THE GARAGE -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('calitoolsgarage') }"
          tab="garage"
          @click="tabCheck('CTGaragePage', 'Garage')"
          :disabled="!userState.loggedIn"
          v-if="$route.meta.caliTools"
        >
          <ion-icon class="as-tab-icon" :icon="pencilOutline"></ion-icon>
          <ion-label>Edit Current</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //CALI TOOLS!! THIS IS THE TAB FOR THE Calitoolshome -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('calitoolshp') }"
          tab="garage"
          @click="tabCheck('CTHomePage')"
          :disabled="!userState.loggedIn"
          v-if="$route.meta.caliTools"
        >
          <div class="logo-wrapper">
            <img
              class="as-logo"
              src="/assets/images/as-images/1024x1024_white_transparentBG.png"
              alt=""
            />
          </div>
          <!--logo-wrapper-->

          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //CALI TOOLS!! THIS IS THE TAB FOR THE Racers -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('ct-calitoolsfiles') }"
          tab="garage"
          @click="tabCheck('CTFileUploadPage')"
          :disabled="!userState.loggedIn"
          v-if="$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon gear-icon"
            :icon="fileTrayFullOutline"
          ></ion-icon>
          <ion-label>Files</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>

        <!-- //CALI TOOLS!! THIS IS THE TAB FOR THE chat -->
        <ion-tab-button
          :class="{ 'tab-selected': $route.path.includes('calitoolschat') }"
          tab="garage"
          @click="tabCheck('CTChatPage')"
          :disabled="!userState.loggedIn"
          v-if="$route.meta.caliTools"
        >
          <ion-icon
            class="as-tab-icon garage-icon"
            src="/assets/images/new-menu-icons/Chat_Track_selected.svg"
          ></ion-icon>
          <ion-label>Chat</ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-app>
</template>

<script setup>
import {
  IonApp,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRippleEffect,
  menuController,
} from "@ionic/vue";

import {
  addCircleOutline,
  buildOutline,
  carSportOutline,
  createOutline,
  fileTrayFullOutline,
  pencilOutline,
  pencilSharp,
} from "ionicons/icons";

import { useUser, onLogout } from "@jackallee/usetrackside/reactive";

import { useRoute, useRouter } from "vue-router";
import { alertController } from "@ionic/vue";
import SlideoutMenu from "./components/slideout-menu/SlideoutMenu.vue";

const { userState } = useUser();

const route = useRoute();
const router = useRouter();

onLogout(() => {
  router.push({ name: "Entry" });
});

async function cancelHome(passedNamedLink, prettyName) {
  const alert = await alertController.create({
    header: "Warning",
    message: `Pressing OK will take you to the ${prettyName} page and lose all entered data`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          //resetSetupSheet();
          router.push({ name: passedNamedLink });
        },
      },
    ],
  });

  await alert.present();
}

const tabCheck = async (namedLink, prettyName) => {
  if (route.meta.leaveAlert === true) {
    cancelHome(namedLink, prettyName);
  } else {
    await menuController.close();
    router.push({ name: namedLink });
  }
};
</script>

<style lang="scss" scoped>

ion-tab-bar {
  background: var(--ion-tab-bar-background);
}

.tab-selected ion-icon,
.tab-selected ion-label {
  /* background: var(--ion-color-secondary); */
  color: var(--ion-color-secondary);
}

ion-tab-button ion-icon,
ion-tab-button ion-label {
  /* background: var(--ion-color-secondary); */
  color: white;
}

ion-tab-button .as-logo {
  height: 42px;
  width: 42px;
}
</style>
