// ShopContents.js
import { ref } from "vue";

export default function useShopContents() {
  const products = ref([
    {
      id: "111",
      name: "Calibration Analysis",
      subTitle: "From $150 Per Calibration",
      shortDescription:
        "Submit Sessions to 057 or to your Pro for analysis and feedback",
      productInformation: [
        {
          description: `Choose your vehicle, track, time, and set the conditions for the day.`,
          image: '/assets/images/product-images/calibration-images/Setup.svg',
        },
        {
          description: `Create a session and enter laps, including as many or as few as needed. For each lap enter in Turn-by-turn feedback (or focus on a few key areas) with  a fast and easy to use interface that helps us make the right changes.`,
          image: '/assets/images/product-images/calibration-images/turndata.svg',
        },
        {
          description: `Include video and data analysis to get even better results. Live chat with engineers to get your answers quickly. We return a recommended calibration with changes to try and some “what to do next” scenarios.`,
          image: '/assets/images/product-images/calibration-images/chat-product.svg',
        },
      ],
      price: 150,
    },
    {
      id: "112",
      name: "Live, Real Time Calibration Analysis",
      subTitle: "From $350 Per Day",
      shortDescription:
        "With you live for a whole day, exchanging sessions and calibrations",
        productInformation: [
          {
            description: `Transmit session reports throughout the day to make the most of your time on track.`,
            image: '/assets/images/product-images/calibration-images/car-landing.svg',
          },
          {
            description: `Make progress, dial in, and catalog the day.`,
            image: '/assets/images/product-images/calibration-images/calibration-overview.svg',
          },
          {
            description: `Chat in between to discuss the session and plan for the next one.`,
            image: '/assets/images/product-images/calibration-images/chat-product.svg',
          },
        ],
      price: 350,
    },
  ]);

  return {
    products,
  };
}
