<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-col size="6"
    ><ion-item
      class="item-corner"
      v-if="
        calReactive.CornerWeights.LR &&
        calReactive.CornerWeights.RF &&
        calReactive.CornerWeights.LF &&
        calReactive.CornerWeights.RR
      "
    >
      <ion-label position="stacked">Corner Total</ion-label>
      <p style="margin-top: 0; margin-bottom: 0">
        {{ calReactive.CornerWeights.Total }}
      </p>
      <ion-note slot="helper"
        ><ion-icon @click="weightCalc()" :icon="calculator"></ion-icon
        >&nbsp;&nbsp;Click Calculator to (re)calculate</ion-note
      >
    </ion-item></ion-col
  >
  <ion-col size="6">
    <ion-item
      class="item-corner"
      v-if="
        calReactive.CornerWeights.LR &&
        calReactive.CornerWeights.RF &&
        calReactive.CornerWeights.Total
      "
    >
      <ion-label position="stacked">Cross Weight&nbsp;&nbsp;</ion-label>
      <p style="margin-top: 0; margin-bottom: 0">
        {{ calReactive.CornerWeights.Cross }}
      </p>
    </ion-item>
  </ion-col>
</template>

<script setup>
import { IonItem, IonLabel, IonIcon, IonNote, IonCol } from "@ionic/vue";

import { useCalibration } from "@jackallee/usetrackside/reactive";

import { calculator } from "ionicons/icons";

const { calReactive } = useCalibration();

// let crossWeight = ref();

// const weightCalc = (a, b, totalWeight) => {
//   if (totalWeight) {
//     calReactive.CornerWeights.Cross = (+a + +b) / +totalWeight;

//     return calReactive.CornerWeights.Cross;
//   }
//   return;
// };

const weightCalc = () => {
  calReactive.CornerWeights.Total =
    +calReactive.CornerWeights.LR +
    +calReactive.CornerWeights.RF +
    +calReactive.CornerWeights.LF +
    +calReactive.CornerWeights.RR;
  const roundDown =
    (+calReactive.CornerWeights.RF + +calReactive.CornerWeights.LR) /
    +calReactive.CornerWeights.Total;
  calReactive.CornerWeights.Cross = roundDown.toFixed(2);
};
</script>

<style scoped>
.item-corner::part(native) {
  padding-inline-start: 0;
}

ion-note {
  margin-left: -20px;
}
</style>
