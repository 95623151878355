import { actionSheetController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useProfile } from "@/modules/useProfile";
import { useChat } from "@/modules/useTrackside";

function sendEmbed(calibratorId, msg, type, id) {
  const { sendMessage } = useChat();

  if (type === "session") {
    sendMessage("Session: ", calibratorId, {
      type: "Session",
      data: id,
      msg: msg,
    });
  } else if (type === "calibration") {
    sendMessage("Calibration: ", calibratorId, {
      type: "Calibration",
      data: id,
      msg: msg,
    });
  }
}

export function useSendEmbed() {
  const { UserProfileReactive } = useProfile();
  const router = useRouter();

  const presentEmbed = async (state, type, calsessId, msg) => {
    const cancelObject = {
      text: "Cancel",
      role: "cancel",
      data: {
        action: "cancel",
      },
    };

    const buttonsObj = UserProfileReactive.chat.conversations.value.map((person) => {
      const personObj = {
        text: person.displayName,
        type: type,
        calsessId: calsessId,
        handler: () => {
          
          sendEmbed(person._id, msg, type, calsessId);

          router.push({
            name: "ChatPage",
            params: { chatId: person._id },
          });
        },
      };
      return personObj;
    });
    buttonsObj.push(cancelObject);
    const actionSheet = await actionSheetController.create({
      header: "Send Calibration to:",
      buttons: buttonsObj,
    });
    await actionSheet.present();
  };
  return { presentEmbed };
}

export function useSendCheckout() {
  const { UserProfileReactive } = useProfile();
  const router = useRouter();
  const { sendMessage } = useChat();

  const presentCheckout = async (message) => {
    const cancelObject = {
      text: "Cancel",
      role: "cancel",
      data: {
        action: "cancel",
      },
    };

    const buttonsObj = UserProfileReactive.chat.conversations.value.map((person) => {
      const personObj = {
        text: person.displayName,
        handler: () => {
          
          // sendEmbed(person._id, "test", type, calsessId);

          // router.push({
          //   name: "ChatPage",
          //   params: { chatId: person._id },
          // });
          sendMessage(message, person._id);
          router.push({
            name: "ChatPage",
            params: {
              chatId: person._id,
            },
          });
        },
      };
      return personObj;
    });
    buttonsObj.push(cancelObject);
    const actionSheet = await actionSheetController.create({
      header: "Send Checkout List to:",
      buttons: buttonsObj,
    });
    await actionSheet.present();
  };
  return { presentCheckout };
}
