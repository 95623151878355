<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-grid class="ST2Cont" v-if="!UserProfileReactive.sessions.isFetching">
    <ion-row class="ST2Row">
      <ion-col class="ST2Col1 cal-sess-butt-wrap">
        <div class="title-wrapper-main">
          <h2><strong>Outings </strong></h2>
          <p class="session-title" style="margin-bottom: 0">
            <strong>{{ TurnData.Setup.title }}</strong>
          </p>
          <p class="car-title" v-if="isCarFetched">
            <strong>{{ carTitle }}</strong>
          </p>

          <div class="outing-details">
            <p><strong>Track:</strong> {{ TrackData.name }}</p>
            <p>
              <strong>Date:</strong>
              {{ formatDateTime(TurnData.Setup.date)[0] }}
            </p>
            <p v-if="TurnData.Setup.trackNotes">
              <strong>Track Notes:</strong> {{ TurnData.Setup.trackNotes }}
            </p>
            <p style="text-transform: capitalize" v-if="TurnData.Setup.weather">
              <strong>Weather:</strong>
              {{ TurnData.Setup.weather.join(", ") }}
            </p>
            <p
              v-if="
                TurnData.Setup.temperature.farenheit ||
                TurnData.Setup.temperature.celcius
              "
            >
              <strong>Temperature:</strong>
              {{ TurnData.Setup.temperature.farenheit }}&#8457; /
              {{ TurnData.Setup.temperature.celcius }}&#8451;
            </p>
          </div>
          <ion-button
            size="block"
            @click="turnByTurn()"
            class="add-button add-button-outings"
            fill="solid"
          >
            <ion-icon slot="end" :icon="addCircle"></ion-icon>
            New Outing
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ST2Row">
      <ion-col class="ST2Col1">
        <!--title-wrapper-->
        <!-- <ion-item class="item-previous-search" lines="none" v-if="sessionResults.length > 0">
                  <ion-label position="stacked"
                    ><strong>Previous </strong></ion-label
                  >
                  <ion-searchbar
                    :debounce="1000"
                    @ionChange="handleChange($event)"
                    placeholder="Find Session"
                    class="track-search"
                  ></ion-searchbar>
                </ion-item> -->

        <div class="search-content-wrapper">
          <div class="outings-title" style="padding-bottom: 15px">
            <p><strong>Recent Outings</strong></p>
          </div>
          <div class="global-searchbar-wrapper" style="max-width: 600px;">
            <ion-searchbar v-model="search"></ion-searchbar>
          </div>
          <!--searchbar-wrapper-->
          <ion-list
            class="list-previous search-list list-init outings-list"
            v-if="TurnData.outings.length > 0"
          >
            <ion-item
              v-for="outing in filteredData"
              :key="outing._id"
              lines="none"
            >
              <ListTemplates
                :viewButton="viewButton"
                :trashButton="trashButton"
                :editButton="editButton"
                @edit-clicked="requestSession(outing._id)"
                @delete-clicked="emitDelete(outing._id)"
                @view-clicked="
                  useSessionModal({
                    outingId: outing._id,
                    turnData: TurnData,
                  })
                "
              >
                <template #text-wrapper>
                  <div class="text-wrapper session-landing">
                    <div class="title-wrapper">
                      <p class="outing-sess-title">
                        <strong>Outing:</strong> {{ outing.title }} @
                        {{ formatDateTime(outing.date)[1] }}
                      </p>
                      <p><strong>Driver:</strong> {{ outing.driver }}</p>
                      <p v-if="outing.bestTime">
                        <strong>Best Lap:</strong>
                        {{ outing?.bestTime.minutes }} :
                        {{ outing?.bestTime.seconds }} .
                        {{ outing?.bestTime.milliseconds }}
                      </p>
                      <p v-else><strong>Best Lap:</strong> N/A</p>
                    </div>
                  </div>
                  <!--text-wrapper-->
                </template>
              </ListTemplates>
            </ion-item>
          </ion-list>

          <div class="empty-content-wrapper" v-else>
            <ion-card>
              <ion-card-content class="cal-card-content">
                <p class="cal-sess-title">
                  <strong>No Outings found.</strong>
                </p>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="ST2Cont" v-else>
    <ion-row class="ST2Row">
      <ion-col class="ST2Col1">
        <ion-skeleton-text
          :animated="true"
          style="height: 15vh"
        ></ion-skeleton-text>
        <br />
        <ion-skeleton-text
          :animated="true"
          style="height: 3vh"
        ></ion-skeleton-text>
        <br />
        <ion-skeleton-text :animated="true"></ion-skeleton-text>
        <ion-skeleton-text :animated="true"></ion-skeleton-text>
        <ion-skeleton-text :animated="true"></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  onIonViewWillEnter,
  IonSkeletonText,
  IonCardContent,
  IonCard,
  alertController,
  modalController,
  IonSearchbar,
} from "@ionic/vue";
import {
  onMounted,
  ref,
  computed,
  toRef,
  watchEffect,
  withDefaults,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import {
  addCircle,
  chatbubbleOutline,
  clipboardOutline,
  pencilOutline,
  trash,
} from "ionicons/icons";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import SessionModal from "@/components/modal-components/SessionModal.vue";
import { useGarage, getClient, useTracks } from "@jackallee/usetrackside/reactive";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useProfile, fetchSessions } from "@/modules/useProfile.js";
import { useSessionModal } from "@/modules/useModals.js";
import { useSendEmbed } from "@/modules/useSendEmbed.js";
import { formatDateTime } from "@/modules/useDate.js";
import ListTemplates from "@/components/list-templates/ListTemplates.vue";
import useSearchFilter from "@/modules/useSearchFilter.js";

const { UserProfileReactive } = useProfile();
// const { isLoading } = useLoadingSpinner();
const router = useRouter();
const { presentEmbed } = useSendEmbed();

// const { garage, getCars } = useGarage();
// const garage = ref([]);
const carResults = ref([]);
//

const props = defineProps({
  TurnData: { type: Object },
  TrackData: { type: Object },
  hideButtons: {
    //Except for the session modal button
    type: Boolean,
  },
});

const editButton = {
  active: true,
};

const viewButton = ref({
  active: true,
});

const trashButton = ref({
  active: true,
});

const emit = defineEmits(["edit:Outing", "delete", "outingCreate"]);

const TurnData = toRef(props, "TurnData");
const isCarFetched = ref(false);
const carTitle = ref("");

const outings = computed(() => TurnData.value?.outings || []);

const { getTrack } = useTracks();
const track = ref({});

const { search, filteredData } = useSearchFilter(outings, ["title", "driver"]);

defineExpose({ search, filteredData });


// isCarFetched.value = false;
// carTitle.value = "";
// try {
//   const carRes = await getClient().garage.getCar(TurnData.value.Setup.car);
//   isCarFetched.value = true;
//   let car = carRes.data.car || {};
//   carTitle.value = `${car.make} ${car.model} (${car.year}) | ${car.number}`;
// } catch (e) {
//   carTitle.value = "";
// }

// watch(
//   () => TurnData.value.Setup.car,
//   async (carId) => {
//     isCarFetched.value = false;
//     carTitle.value = "";
//     try {
//       const carRes = await getClient().garage.getCar(carId);
//       isCarFetched.value = true;
//       let car = carRes.data.car || {};
//       carTitle.value = `${car.make} ${car.model} (${car.year}) | ${car.number}`;
//     } catch (e) {
//       carTitle.value = "";
//     }
//   }
// );
if(TurnData.value.Setup.trackId){
    const { onSuccess, onError } = getTrack(TurnData.value.Setup.trackId, {
      onSuccess: (res) => {
        track.value = res.data.track;
      },
      onError: (err) => {
        console.log("Error Getting Track Data", err);
      }
  });
} 
// watchEffect(() => {
//   if(TurnData.value.Setup.trackId){
//     const { onSuccess, onError } = getTrack(TurnData.value.Setup.trackId, {
//       onSuccess: (res) => {
//         track.value = res.data.track;
//       },
//       onError: (err) => {
//         console.log("Error Getting Track Data", err);
//       }
//   });
// } else {
//         throw new Error("No Track Found");
//       }
// });

watchEffect(
  async (carId) => {
    isCarFetched.value = false;
    carTitle.value = "";
    try {
      if(TurnData.value.Setup.car){
      const carRes = await getClient().garage.getCar(TurnData.value.Setup.car);
      isCarFetched.value = true;
      let car = carRes.data.car || {};
      carTitle.value = `${car.make} ${car.model} (${car.year}) | ${car.number}`;
      } else {
        throw new Error("No car found");
      }
    } catch (e) {
      carTitle.value = "";
    }
  }
);
//

// function formatDateTime(date) {
//   date = new Date(date);

//   let dateFormatted = date.toLocaleDateString("en-US", {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   });
//   let timeFormatted = date.toLocaleTimeString();

//   return [dateFormatted, timeFormatted];
// }


const sessionList = ref([]);
const areSessions = ref(false);
const skeletonIsLoading = ref(true);

function requestSession(outingId) {
  emit("edit:Outing", outingId);
  return;
}

function emitDelete(outingId) {
  emit("delete", outingId);
  return;
}

function turnByTurn() {
  emit("outingCreate");
  return;
}

// function requestSessionModal(outingId) {
//   useModalOutings(TurnData.value._id, outingId);
// }
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/sessions/SessionOutingPage";
</style>
