<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <div class="ion-page">
      <HeaderComp title="Checkout">
        <ion-buttons slot="start">
          <ion-button
            slot="icon-only"
            @click="$router.push({ name: 'SubscriptionLandingPage' })"
          >
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </HeaderComp>
      <ion-content>
        <div class="as-page-wrapper home-page">
          <ion-grid class="ST1Cont" style="border-top: solid 8px var(--ion-color-black);">
            <ion-row class="ST1Row">
              <ion-col class="ST1Col1">
                <div class="intro-content-wrapper">
                  <p>
                    <strong>Complete your Subscription choice below</strong>
                  </p>
                </div>
                <!--content-wrapper-->
              </ion-col>
            </ion-row>
            <ion-row class="ST1Row">
              <ion-col class="ST1Col1">
                <div class="content-wrapper">
                  <div class="total-main-wrapper">
                    <div v-if="isSubscriptionReady" class="inner-wrapper">
                      <div
                        v-for="(line, i) in invoiceLines"
                        :key="i"
                        class="prod-wrapper main-prod-wrapper"
                      >
                        <p>
                          <strong>Item #{{ i + 1 }}</strong>
                        </p>
                        <p>{{ line.description }} Racer</p>
                      </div>

                      <!--main-prod-wrapper-->
                      <div class="total-wrapper">
                        <p>
                          <strong>Total</strong>
                        </p>
                        <p>
                          ${{ subscription.latest_invoice.amount_due / 100 }}
                        </p>
                      </div>
                      <!--total-wrapper-->
                    </div>
                    <div>
                      <p>{{ errorMessage }}</p>
                    </div>
                    <div class="payment-wrapper">
                      <p><strong>Payment Method:</strong></p>
                      <p style="padding-bottom: 15px;">Please enter your card details below. This card will be used for all transactions with 057 motorsports. It can be changed in your stripe user area:</p>
                      <div id="payment-element" class=""></div>
                      <div class="button-wrapper" style="padding-top: 30px;">
                        <ion-button
                          v-if="isCheckoutReady"
                          :disabled="!isSubscriptionReady"
                          expand="block"
                          @click="handleSubscribe"
                          color="secondary"
                          >Subscribe</ion-button
                        >
                      </div>

                      <!--main-prod-wrapper-->
                    </div>
                    <!--inner-wrapper-->
                  </div>
                  <!--total-wrapper-->
                </div>
                <!--content-wrapper-->
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <!--as-page-wrapper-->
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import { onBeforeMount, ref, shallowRef } from "vue";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonThumbnail,
  IonCardSubtitle,
  IonRadioGroup,
  IonRadio,
  onIonViewWillEnter,
  IonButtons,
  IonIcon,
} from "@ionic/vue";
import { loadStripe } from "@stripe/stripe-js";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { chevronBackOutline } from "ionicons/icons";
import { useUser, useAuthHeader } from "@jackallee/usetrackside/reactive";
import { useToast } from "@/modules/useToasts.js";
import axios from "axios";
const APIURL = import.meta.env.VITE_API_URL;
let prices = ref();
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const { query } = useRoute();
const { userState } = useUser();
const isSubscriptionReady = ref(false);
const subscription = shallowRef({});
const invoiceLines = ref([]);
const isLoading = ref(false);
const errorMessage = ref("");
const isCheckoutReady = ref(false);
let stripe;
let elements;
const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

onIonViewWillEnter(async () => {
  stripe = await loadStripe(publishableKey);
  if (!query.plan) {
    useToast({ message: "No plan selected" });
    router.push({ name: "SubscriptionLandingPage" });
    return;
  }
  let payload = {
    uid: userState.id,
    priceId: query.plan,
  };

  try {
    isLoading.value = true;
    const response = await axios.post(
      `${APIURL}/billing/create-subscription`,
      payload,
      {
        headers: {
          Authorization: useAuthHeader(),
        },
      }
    );
    isLoading.value = false;
    if (response.status === 200) {
      var checkoutData = {};
      const clientSecret = response.data.clientSecret;
      subscription.value = response.data.subscription;
      const invoice = subscription.value.latest_invoice;
      invoiceLines.value = invoice.lines.data;
      isSubscriptionReady.value = true;

      const appearance = {
        theme: "flat",
        variables: { colorPrimaryText: "#262626" },
      };
      const options = {
        /* options */
      };
      elements = stripe.elements({ clientSecret, appearance });
      const paymentElement = elements.create("payment", options);
      paymentElement.mount("#payment-element");
      paymentElement.on("ready", () => {
        isCheckoutReady.value = true;
      });
    } else {
      useToast({
        message: "Error Creating Subscriptions and Setting up Payment Element",
      });
      // throw new Error(
      //   "Error Creating Subscriptions and Setting up Payment Element"
      // );
    }
  } catch (e) {
    let message =
      e?.response.data.message ||
      "Error Creating Subscriptions and Setting up Payment Element";
    useToast({
      message,
    });

    if (e?.response?.status === 400) {
      router.push("/garage");
      return;
    }

    console.error(
      "Error Creating Subscription and Setting up Payment Element: ",
      e
    );
  }
});

async function handleSubscribe() {
  isSubscriptionReady.value = false;
  isLoading.value = true;
  const paymentResult = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: `${import.meta.env.VITE_APP_URL}/subscription/checkout`,
    },
  });

  console.log(paymentResult);

  if (paymentResult.error) {
    errorMessage.value = paymentResult.error.message;
    if(paymentResult.error?.payment_intent?.status !== "success"){
      isSubscriptionReady.value = true;
      isLoading.value = false;
      return;
    } 
  }
}
</script>

<style scoped lang="scss">
.intro-content-wrapper {
  padding: 0 15px;
}

.total-main-wrapper {
  margin: 0 auto;
  border-radius: var(--as-border-radius);
  background-color: white;
  padding: 15px;
}

.total-main-wrapper .inner-wrapper p {
  font-size: 17px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.total-main-wrapper .inner-wrapper .button-wrapper {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
}

.total-main-wrapper .inner-wrapper .total-inner-wrapper {
  margin-top: 15px;
  border-top: solid 1px black;
  padding-top: 20px;
}

.prod-wrapper {
  display: block;
  justify-content: space-between;
}

.prod-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}

.total-wrapper {
  border-top: solid 1px black;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 30px;
}
</style>
