<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <div class="ion-page">
      <HeaderCompLoggedOut
        :title="status === 'success' ? 'Success' : (status === 'loading' ? '' : 'Failure')"
        asHref="/entry"
      />
      <ion-content>
        <ion-grid class="ST1Cont" :class="status === 'success' ? 'success' : (status === 'loading' ? 'loading' : 'failure')">
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <div class="content-wrapper" v-if="status === 'success'">
                <p style="font-size: 22px;">
                  <strong>{{ message }}</strong>
                </p>
                <p>
                  Welcome aboard! Your subscription was successfully setup.
                  We're delighted to have you with us!
                </p>
                <div class="what-next">
                  <p><strong>Suggested Next Steps:</strong></p>
                  <ul class="what-next-list">
                    <li>Add your cars to the app by creating them in the <strong>Garage</strong>.</li>
                    <li>If you have some track time coming up, press the <strong>Big Red Button</strong> to initiate a session with a calibrator. Trackside makes it easy to set up a session.</li>
                    <li>Contact your calibrator through the <strong>Chat</strong> and check in.</li>
                    <li>If you have any questions/queries please feel free to contact the 057 Motorsports team at <a href="mailto:team@057motorsports.com"><strong>team@057motorsports.com</strong></a></li>
                  </ul>
                </div><!--what-next-->
              </div>
              <div class="content-wrapper" v-else-if="status === 'loading'">
                <ion-skeleton-text
                :animated="true"
                style="height: 15vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text
                :animated="true"
                style="height: 3vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </div>
              <div class="content-wrapper" v-else>
                <p style="font-size: 22px;">
                  <strong>Failure! Payment not received.</strong>
                </p>
                <p>
                  Unfortunately your subscription attempt was unsuccessful.
                  Please try again or if the problem persists contact us at
                  <a href="mailto:team@057motorsports.com">team@057motorsports.com</a> and we'll be happy to assist.
                </p>
              </div>
              <!--content-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import { onBeforeMount, ref, shallowRef } from "vue";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonThumbnail,
  IonCardSubtitle,
  IonRadioGroup,
  IonRadio,
  IonSkeletonText,
} from "@ionic/vue";
import HeaderCompLoggedOut from "@/components/header-components/HeaderCompLoggedOut.vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "@/modules/useToasts.js";
import { loadStripe } from "@stripe/stripe-js";

const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const isLoading = ref(false);
const status = ref("loading"); //"loading", "success", "failure"
const route = useRoute();
const router = useRouter();

const errorMessage = ref("");
const message = ref("");
const password = ref("");
const confirmPassword = ref("");

if (!route.query.payment_intent || !route.query.payment_intent_client_secret) {
  useToast({
    message: "Uh Oh Something Went Wrong!",
  });
  errorMessage.value = "Uh Oh Something Went Wrong!";
}

onBeforeMount(async () => {
  const stripe = await loadStripe(publishableKey);
  const { paymentIntent } = await stripe.retrievePaymentIntent(
    route.query.payment_intent_client_secret
  );

  // Inspect the PaymentIntent `status` to indicate the status of the payment
  // to your customer.
  //
  // Some payment methods will [immediately succeed or fail][0] upon
  // confirmation, while others will first enter a `processing` state.
  //
  // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
  switch (paymentIntent.status) {
    case "succeeded":
      message.value = "Success! Payment received.";
      status.value = "success";
      break;

    case "processing":
      message.value =
        "Payment processing. We'll update you when payment is received.";
      status.value = "processing";

      break;

    case "requires_payment_method":
      message.value = "Payment failed. Please try another payment method.";
      status.value = "failed";
      router.push("/subscription-landing");
      useToast({
        message:
          "Payment failed. Please try again with another payment method.",
      });
      break;

    default:
      status.value = "failed";
      message.value = "Something went wrong.";
      break;
  }
});
</script>

<style scoped lang="scss">

.button-wrapper {
  margin-top: 60px;

  & ion-button {

    &::part(native) {
    background: var(--as-red-gradient-button);
  }
  }

}

.what-next {

  .what-next-list {
    list-style-type: none;
  padding-inline-start: 2px;

  & li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #f0f0f0;

    &:last-child {
      border-bottom: solid 0px #f0f0f0;
    }
  }
}
}

.ST1Cont {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;

  &.success {
    border-top: solid 8px green;
  }
  &.failure {
    border-top: solid 8px red;
  }
}
ion-menu-button.button {
  display: none !important;
}
.intro-content-wrapper {
  padding: 0 15px;
}

.total-main-wrapper {
  margin: 0 auto;
  border-radius: var(--as-border-radius);
  background-color: white;
  padding: 15px;
}

.total-main-wrapper .inner-wrapper p {
  font-size: 17px;
  margin-bottom: 5px;
}

.total-main-wrapper .inner-wrapper .button-wrapper {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
}

.total-main-wrapper .inner-wrapper .total-inner-wrapper {
  margin-top: 15px;
  border-top: solid 1px black;
  padding-top: 20px;
}

.prod-wrapper {
  display: flex;
  justify-content: space-between;
}

.prod-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
