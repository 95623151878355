<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <!-- <SlideoutMenu contentId="main-content" /> -->
    <HeaderComp :title="displayTitle">
      <ion-buttons slot="start">
        <ion-back-button
          text=""
          default-href=""
          @click="$router.push({ name: 'ChatLandingPage' })"
        ></ion-back-button>
      </ion-buttons>
    </HeaderComp>
    <ion-content class="as-content">
      <ion-grid id="as-new-styles" class="ST1Cont chat-page">
        <ion-row>
          <ion-col>
            <div class="content-wrapper">
              <div class="chat-inner" style="position: relative">
                <div
                  class="image-wrapper ST1Cont"
                  v-if="messages && messages.length === 0"
                  style="
                    position: absolute;
                    top: 0px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: top;
                    z-index: 1;
                  "
                >
                  <img
                    class="as-image"
                    style="width: 100%; height: 100%; object-fit: cover"
                    src="/assets/images/as-images/appbackground-v1.png"
                    alt=""
                  />
                </div>
                <ChatPanel
                  id="msgs"
                  :messages="messages"
                  :convo-data="convoData"
                ></ChatPanel>
                <ion-text
                  v-if="messages && messages.length === 0"
                  class="no-content-message"
                >
                  <p>
                    Welcome to the 057 Chat. <br /><br />Here you will be able
                    to talk to the 057 team about calibrations.
                    <br /><br />Start your chat by typing a message below.
                  </p>
                </ion-text>
              </div>
              <form @submit.prevent="sendMessage" class="form">
                <ion-item lines="none" class="ST1Cont">
                  <ion-input
                    v-model="msgInput"
                    aria-label="Message Input"
                    placeholder="Type a message"
                  ></ion-input>
                  <ion-button
                    class="as-submit-button"
                    type="submit"
                    fill="clear"
                  >
                    <ion-icon
                      slot="icon-only"
                      :icon="paperPlaneOutline"
                    ></ion-icon
                  ></ion-button>
                </ion-item>
              </form>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonItem,
  IonContent,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonButtons,
  IonToolbar,
  IonBackButton,
  IonTitle,
  onIonViewWillEnter,
  onIonViewDidEnter,
} from "@ionic/vue";
import {
  ref,
  nextTick,
  reactive,
  watch,
  computed,
  onMounted,
  watchEffect,
} from "vue";
import { useRoute } from "vue-router";

import { useProfile } from "../../../modules/useProfile";

// import {io} from 'socket.io-client';
import { useUser, useSocket } from "../../../modules/useTrackside.js";
import { logoIonic, paperPlaneOutline } from "ionicons/icons";

import ChatPanel from "../../../components/chat/ChatPanel.vue";
import HeaderComp from "../../../components/header-components/HeaderComp.vue";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import alertSfx from "/assets/sounds/dummy-alert.mp3";

const { UserProfileReactive } = useProfile();
const { isLoading } = useLoadingSpinner();
const { userState } = useUser();
//
const initialised = ref(false);
// const messages = ref([]);

const { params } = useRoute();

const messages = computed(() => {
  return UserProfileReactive.chat[params.chatId]?.messages;
});

const scrollBot = () => {
  document.querySelector(".as-content").scrollToBottom();
};

watch(messages, (newMessages) => {
  if (newMessages && newMessages.length > 0) {
    nextTick(() => {
      scrollBot();
    });
  }
});

onIonViewWillEnter(() => {
  if (params.chatId) {
    chatSessionId.value = params.chatId;
    UserProfileReactive.chat[params.chatId]?.messages;
  }
});

const displayTitle = computed(() => {
  let conversations = UserProfileReactive.chat.conversations.value;
  let chatId = params.chatId;
  return (
    "Chat (" + conversations.find((c) => chatId === c._id)?.displayName + ")" ||
    ""
  );
});

const msgInput = ref("");
const { socket } = useSocket();
const adminAcct = import.meta.env.VITE_ADMIN_ID;
// const adminAcct = "631ba5d1a714fbc051b9c98a";
const chatSessionId = ref();
const convoData = reactive({
  [userState.id]: {
    name: userState.username,
    avatar: "",
  },
  [adminAcct]: { name: "O57 Calibrator", avatar: "" },
});

// isLoading.value = true;

//Find the Chat Session for the user

//

// if (params.chatId) {
//   // socket.emit("set-session", { id: params.chatId });
//   // messages.value = computed(() => UserProfileReactive.chat[params.chatId]?.messages);
//   chatSessionId.value = params.chatId;
// } else {
//   // socket.emit("set-session", { users: [userState.id, adminAcct] });
// }

const playRef = ref();

const audio = new Audio(alertSfx);

const playFunction = () => {
  audio.play();
};

function sendMessage() {
  if (msgInput.value)
    socket.emit("chat-message", {
      content: msgInput.value,
      to: chatSessionId.value,
    });
  scrollBot();
  msgInput.value = "";
}

</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/chats/ChatPage";

ion-content::part(scroll){
  display: flex;
  flex-direction: column-reverse;
}

ion-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

ion-back-button::part(native) {
  color: var(--ion-color-black);
}
</style>
