import { useGarage } from "../modules/useTrackside";

export function useFindCar() {
  const { getCars } = useGarage();

  let prettyCarString;

  async function carWanted(carId, userId) {
    
    try {
      const { data } = await getCars(userId);
      const chosenCar = data.garage.find((car) => car._id === carId);
      
      prettyCarString = `${chosenCar.make} ${chosenCar.model} (${chosenCar.year})`;

      
      return prettyCarString;
    } catch (error) {
      console.error(error);
    }
  }

  return { carWanted };
}
