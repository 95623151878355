<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page class="car-landing-page-parent">
    <!-- <SlideoutMenu contentId="main-content" /> -->
    <div class="ion-page">
      <HeaderComp title="Car Landing Page">
        <ion-buttons slot="primary">
          <ion-button @click="deleteCarWarning()">
            <ion-icon slot="icon-only" :icon="trash"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-button
            slot="icon-only"
            @click="$router.push({ name: 'GarageLanding' })"
          >
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </HeaderComp>
      <ion-content class="as-content ion-align-items-center">
        <ion-grid class="ST1Cont" v-if="!skeletonIsLoading">
          <ion-row class="title-row head-comp-title">
            <ion-col class="title-col">
              <h2>{{ CarName }}<br />{{ CarNumber }}</h2>
              <br />
              <p v-if="CarNote">
                {{ CarNote }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1 cal-sess-butt-wrap">
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    <ion-button
                      class="new-calibration-button"
                      size="default"
                      expand="block"
                      @click="gotoNewCalibration()"
                    >
                      <ion-icon
                        slot="start"
                        :icon="addCircleOutline"
                      ></ion-icon>
                      New Calibration
                    </ion-button>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    <ion-button
                      class="new-session-button"
                      size="default"
                      expand="block"
                      @click="newSession()"
                    >
                      <ion-icon
                        slot="start"
                        :icon="addCircleOutline"
                      ></ion-icon>
                      New Session
                    </ion-button>
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont" v-if="!skeletonIsLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="header-wrapper">
                <h4 class="ion-text-center">
                  <strong>Calibration List</strong>
                </h4>
              </div>

              <!--searchbar-wrapper-->
              <ion-list class="list-previous search-list list-init">
                <!-- <ion-item class="item-previous-search" lines="none">
                  <ion-searchbar
                    :debounce="1000"
                    @ionChange="handleChangeCalibration($event)"
                    placeholder="Find Calibration"
                    class="track-search"
                  ></ion-searchbar>
                </ion-item> -->
                <div
                  class="content-wrapper"
                  v-if="filteredDataCalibration"
                >
                  <div class="global-searchbar-wrapper">
                    <ion-searchbar v-model="searchCalibration"></ion-searchbar>
                  </div>
                  <ion-list class="garage-page list-previous search-list">
                    <ion-item
                      v-for="cal in filteredDataCalibration"
                      :key="cal.id"
                      lines="none"
                    >
                      <ListTemplates
                        :chatButton="chatButton"
                        :viewButton="viewButton"
                        :editButton="editButton"
                        :trashButton="trashButton"
                        @edit-clicked="requestCalibration(cal.id, true)"
                        @view-clicked="requestCalibration(cal.id)"
                        @chat-clicked="
                          presentEmbed(true, 'calibration', cal.id, cal.title)
                        "
                        @delete-clicked="deleteCalWarning(cal.id)"
                      >
                        <template #text-wrapper>
                          <div class="text-wrapper car-landing">
                            <p class="cal-sess-title">
                              <strong>{{ cal.title }}</strong>
                            </p>
                          </div>
                          <!--text-wrapper-->
                        </template>
                      </ListTemplates>
                    </ion-item>
                  </ion-list>
                  <!-- <ion-card
                    v-for="cal in calibrationResults"
                    v-bind:key="cal.id"
                  >
                    <ion-card-content class="cal-card-content">
                      <p class="cal-sess-title">
                        <strong>{{ cal.title }}</strong>
                      </p>
                      <div
                        class="button-wrapper-cal"
                        v-if="cal.title !== caliNoContentsTitle"
                      >
                        <ion-button
                          class="edit-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="requestCalibration(cal.id, true)"
                        >
                          <ion-icon
                            slot="icon-only"
                            :icon="pencilOutline"
                          ></ion-icon>
                        </ion-button>

                        <ion-button
                          class="view-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="requestCalibration(cal.id)"
                        >
                          <ion-icon
                            slot="icon-only"
                            :icon="clipboardOutline"
                          ></ion-icon>
                        </ion-button>
                        <ion-button
                          class="chat-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="presentEmbed(true, 'calibration', cal.id)"
                        >
                          <ion-icon
                            slot="icon-only"
                            :icon="chatbubbleOutline"
                          ></ion-icon>
                        </ion-button>
                        <ion-button
                          style="font-weight: 700"
                          class="trash-button cal-button"
                          @click="deleteCalWarning(cal.id)"
                          size="small"
                          fill="outline"
                        >
                          <ion-icon slot="icon-only" :icon="trash"></ion-icon>
                        </ion-button>
                      </div>
                    </ion-card-content>
                  </ion-card> -->
                </div>
                <!--content-wrapper-->
                <div class="empty-content-wrapper" v-else>
                  <ion-card>
                    <ion-card-content class="cal-card-content">
                      <p class="cal-sess-title">
                        <strong>No calibrations found.</strong>
                      </p>
                    </ion-card-content>
                  </ion-card>
                </div>
                <!--empty-content-wrapper-->
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont" v-if="!skeletonIsLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="header-wrapper">
                <h4 class="ion-text-center"><strong>Session List</strong></h4>
              </div>
              <ion-list class="list-previous search-list list-init">
                <!-- <ion-item class="item-previous-search" lines="none">
                  <ion-searchbar
                    :debounce="1000"
                    @ionChange="handleChangeSession($event)"
                    placeholder="Find Session"
                    class="track-search"
                  ></ion-searchbar>
                </ion-item> -->
                <div
                  class="search-content-wrapper"
                  v-if="sessionResults.length > 0"
                >
                  <div class="global-searchbar-wrapper">
                    <ion-searchbar v-model="searchSession"></ion-searchbar>
                  </div>
                  <ion-list class="garage-page list-previous search-list">
                    <ion-item
                      v-for="session in filteredDataSession"
                      :key="session.id"
                      lines="none"
                    >
                      <ListTemplates
                        :chatButton="chatButton"
                        :outingsButton="outingsButton"
                        :editButton="editButton"
                        @edit-clicked="requestSession(session.id)"
                        @outings-clicked="gotoOutings(session.id)"
                        @chat-clicked="
                          presentEmbed(
                            true,
                            'session',
                            session.id,
                            session.title
                          )
                        "
                      >
                        <template #text-wrapper>
                          <div class="text-wrapper car-landing">
                            <p class="cal-sess-title">
                              <strong>{{ session.title }}</strong>
                            </p>
                          </div>
                          <!--text-wrapper-->
                        </template>
                      </ListTemplates>
                    </ion-item>
                  </ion-list>

                  <!-- <ion-card
                    v-for="session in sessionResults"
                    v-bind:key="session.id"
                  >
                    <ion-card-content class="cal-card-content">
                      
                        <p class="cal-sess-title">
                          <strong>{{ session.title }}</strong>
                        </p>

                      <div
                        class="button-wrapper-cal"
                        v-if="session.title !== ''"
                      >
                        <ion-button
                          class="edit-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="requestSession(session.id)"
                        >
                          <ion-icon
                            slot="start"
                            :icon="pencilOutline"
                          ></ion-icon>
                          Edit
                        </ion-button>

                        <ion-button
                          class="view-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="gotoOutings(session.id)"
                        >
                          <ion-icon
                            slot="start"
                            :icon="infiniteOutline"
                          ></ion-icon>
                          Outings
                        </ion-button>

                        <ion-button
                          class="chat-button cal-button"
                          style="font-weight: 700"
                          size="small"
                          fill="outline"
                          @click="presentEmbed(true, 'session', session.id)"
                        >
                          <ion-icon
                            slot="start"
                            :icon="chatbubbleOutline"
                          ></ion-icon>
                          Chat
                        </ion-button>
                      </div>
                    </ion-card-content>
                  </ion-card> -->
                </div>
                <div class="empty-content-wrapper" v-else>
                  <ion-card>
                    <ion-card-content class="cal-card-content">
                      <p class="cal-sess-title">
                        <strong>No sessions found.</strong>
                      </p>
                    </ion-card-content>
                  </ion-card>
                </div>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST1Cont" v-if="skeletonIsLoading">
          <ion-row class="title-row head-comp-title">
            <ion-col class="title-col">
              <h2>
                <ion-skeleton-text :animated="true"></ion-skeleton-text
                ><br /><ion-skeleton-text :animated="true"></ion-skeleton-text>
              </h2>
              <br />
              <p>
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1 cal-sess-butt-wrap">
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </div>
                </ion-label>
              </ion-item>
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont" v-if="skeletonIsLoading">
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
          <br />
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
          <br />
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-grid>
        <ion-grid class="ST3Cont" v-if="skeletonIsLoading">
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
          <br />
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
          <br />
          <ion-skeleton-text :animated="true"></ion-skeleton-text>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  modalController,
  IonCard,
  IonCardContent,
  alertController,
  IonButtons,
  IonMenuButton,
  IonSkeletonText,
  IonBackButton,
  IonSearchbar,
  onIonViewWillEnter,
  onIonViewDidEnter,
} from "@ionic/vue";

import { ref, watch, onMounted, computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  addCircleOutline,
  clipboardOutline,
  trash,
  chatbubbleOutline,
  pencilOutline,
  infiniteOutline,
  caretBack,
  chevronBackOutline,
} from "ionicons/icons";
import CalibrationModal from "@/components/modal-components/CalibrationModal.vue";
import { useUser, useChat, getClient } from "@/modules/useTrackside";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import axios from "axios";
import SessionModal from "@/components/modal-components/SessionModal.vue";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useSendEmbed } from "@/modules/useSendEmbed.js";
import { useProfile } from "@/modules/useProfile.js";
import ListTemplates from "@/components/list-templates/ListTemplates.vue";
import useSearchFilter from "@/modules/useSearchFilter.js";
// import {io} from 'socket.io-client';

const editButton = {
  active: true,
};

const viewButton = ref({
  active: true,
});

const chatButton = ref({
  active: true,
});

const trashButton = ref({
  active: true,
});

const outingsButton = ref({
  active: true,
});

//const { isLoading } = useLoadingSpinner();
const { sendMessage } = useChat();
const { getAuthHeader } = useUser();

const { presentEmbed } = useSendEmbed();
const route = useRoute();
const router = useRouter();

const caliNoContentsTitle = "No Calibrations Found";
const sessNoContentsTitle = "No Sessions Found";

const skeletonIsLoading = ref(true);

let sessionResults = ref([]);
let calibrationResults = ref([]);
const caliSearch = ref([]);
const sessionSearch = ref([]);
//isLoading.value = true;
//Car Stuff

let searchCalibration = null;
let filteredDataCalibration = null;
let searchSession = null;
let filteredDataSession = null;

const car = reactive({});

//

const CarName = computed(() => {
  const { make, model, year } = car;
  //
  return `${make} ${model} (${year})`;
});

const CarNumber = computed(() => {
  const { number } = car;
  return `${number}`;
});

const CarNote = computed(() => {
  const { note } = car;
  return note;
});

onMounted(() => {
  getClient()
    .garage.getCar(route.params.carId)
    .then((result) => {
      console.log(result);
      if (result.data.success) {
        skeletonIsLoading.value = false;
        
        // eslint-disable-next-line no-prototype-builtins
        if (result.data.hasOwnProperty("car")) {
          Object.assign(car, { ...result.data.car });
          //
          // eslint-disable-next-line no-prototype-builtins
          if (result.data.car.hasOwnProperty("raceDataEntries")) {
            sessionResults.value = result.data.car.raceDataEntries;
            //
            
              const { search, filteredData } = useSearchFilter(sessionResults, [
              "title",
            ]);
            searchSession = search;
            filteredDataSession = filteredData;
           
            
          }
          // eslint-disable-next-line no-prototype-builtins
          if (result.data.car.hasOwnProperty("calibrations")) {
            calibrationResults.value = result.data.car.calibrations;
            const { search, filteredData } = useSearchFilter(
              calibrationResults,
              ["title"]
            );
            searchCalibration = search;
            filteredDataCalibration = filteredData;
          }
        }
      }
    });
});

defineExpose({
  searchCalibration,
  filteredDataCalibration,
  searchSession,
  filteredDataSession,
});

function deleteCal(calId) {
  //confirmation here
  axios
    .delete(`${import.meta.env.VITE_API_URL}/calibration/delete/${calId}`, {
      headers: {
        Authorization: getAuthHeader(),
      },
    })
    .then(() => {
      getClient()
        .garage.getCar(route.params.carId)
        .then((result) => {
          //
          if (result.data.success) {
            // eslint-disable-next-line no-prototype-builtins
            if (result.data.hasOwnProperty("car")) {
              Object.assign(car, {});
              Object.assign(car, result.data.car);

              //
              // eslint-disable-next-line no-prototype-builtins
              if (result.data.car.hasOwnProperty("raceDataEntries")) {
                sessionResults.value = result.data.car.raceDataEntries;
              }
              // eslint-disable-next-line no-prototype-builtins
              if (result.data.car.hasOwnProperty("calibrations")) {
                calibrationResults.value = result.data.car.calibrations;
              }
            }
          }
        });
    });
}

async function deleteCalWarning(calId) {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will delete this calibration and you will not be able to retrieve the data.",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          deleteCal(calId);
        },
      },
    ],
  });

  await alert.present();
}

function deleteCar() {
  //confirmation here
  if (!car._id) {
    return false;
  }
  axios
    .delete(`${import.meta.env.VITE_API_URL}/garage/car/delete/${car._id}`, {
      headers: {
        Authorization: getAuthHeader(),
      },
    })
    .then(() => {
      router.push("/garage/");
    });
}

async function deleteCarWarning() {
  const alert = await alertController.create({
    header: "Warning",
    message: `Pressing OK will delete this car (${CarName.value}) and all linked calibrations and sessions. IMPORTANT you will NOT be able to retrieve the data.`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          deleteCar();
        },
      },
    ],
  });

  await alert.present();
}

//Calibration Stuff
function gotoNewCalibration() {
  let carId = route.params.carId;
  //todo check success
  router.push({
    name: "CalibrationEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { car: carId },
  });
}

function requestCalibration(calId, withEdit = false) {
  if (withEdit) {
    let carId = route.params.carId;
    router.push({
      name: "CalibrationEditor",
      params: { editSessionId: self.crypto.randomUUID() },
      query: { id: calId, car: carId },
    });
    return;
  } else {
    openModal(calId);
  }
}

function requestSession(sessionId) {
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { id: sessionId },
  });
  return;
}

function gotoOutings(sessionId) {
  // router.push({
  //   name: "OutingsPage",
  //   //params: { editSessionId: self.crypto.randomUUID() },
  //   query: { id: sessionId },
  // });
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { id: sessionId, outing: true },
  });
  return;
}

watch(
  () => route.params.carId,
  async () => {
    //
    //
    if (route.params.carId) {
      const result = await getClient().garage.getCar(route.params.carId);
      //
      if (result.data.success) {
        const carData = result.data.car;

        // eslint-disable-next-line no-prototype-builtins
        if (result.data.car.hasOwnProperty("raceDataEntries")) {
            sessionResults.value = result.data.car.raceDataEntries;
            //
            
              const { search, filteredData } = useSearchFilter(sessionResults, [
              "title",
            ]);
            searchSession = search;
            filteredDataSession = filteredData;
           
            
          }
          // eslint-disable-next-line no-prototype-builtins
          if (result.data.car.hasOwnProperty("calibrations")) {
            calibrationResults.value = result.data.car.calibrations;
            const { search, filteredData } = useSearchFilter(
              calibrationResults,
              ["title"]
            );
            searchCalibration = search;
            filteredDataCalibration = filteredData;
          }

        // if (Object.prototype.hasOwnProperty.call(carData, "raceDataEntries")) {
        //   sessionResults.value = result.data.car.raceDataEntries;
        //   sessionSearch.value = sessionResults.value;
        // }
        // if (Object.prototype.hasOwnProperty.call(carData, "calibrations")) {
        //   calibrationResults.value = result.data.car.calibrations;
        //   caliSearch.value = calibrationResults.value;
        // }
      }
    }
  },
  { immediate: true }
);
function newSession() {
  router.push({
    name: "SessionEditor",
    params: { editSessionId: self.crypto.randomUUID() },
    query: { carId: route.params.carId }
  });
}

//this is linked to @ionChange on the <ion-searchbar></ion-searchbar>
const handleChangeSession = (event) => {
  const query = event.target.value.toLowerCase();

  sessionSearch.value = sessionResults.value.filter(
    (d) => d.title.toLowerCase().indexOf(query) > -1
  );
};

//this is linked to @ionChange on the <ion-searchbar></ion-searchbar>
const handleChangeCalibration = (event) => {
  const query = event.target.value.toLowerCase();

  caliSearch.value = calibrationResults.value.filter(
    (d) => d.title.toLowerCase().indexOf(query) > -1
  );
};

async function openModal(id) {
  //
  // const corners = ["RF", "LF", "RR", "LR"];
  // if (corners.includes(corner)) {
  //   var tireData = {
  //     TireData: calReactive.TireData[corner],
  //     ShockData: calReactive.ShockData[corner],
  //   };
  const modal = await modalController.create({
    component: CalibrationModal,
    componentProps: {
      calibrationId: id,
      fetchCal: true,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}

async function openModalSession(sessionId) {
  //
  // const corners = ["RF", "LF", "RR", "LR"];
  // if (corners.includes(corner)) {
  //   var tireData = {
  //     TireData: calReactive.TireData[corner],
  //     ShockData: calReactive.ShockData[corner],
  //   };

  const modal = await modalController.create({
    component: SessionModal,
    componentProps: {
      sessionId,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/garage/CarLandingPage";

.text-wrapper.car-landing {
  & p {
    font-size: 16px;
  }
}

.title-row ion-col {
  text-align: center;
}

.title-row ion-col h2 {
  font-weight: 700;
}

.title-row ion-col p {
  margin-top: 0;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.upgrade-wrapper {
  margin-top: 10px;
  color: var(--ion-color-black);
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px 30px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.product-wrapper {
  border-bottom: solid 1px var(--ion-color-black);
}

.product-wrapper .section-title {
  margin-top: 10px;
  padding-bottom: 7.5px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
}

.product-wrapper .section-title ion-icon {
  font-size: 22px;
  margin-top: 0px;
}

.as-popover ion-content {
  --background: var(--ion-color-white);
  --padding: 15px;
}

.as-popover ion-content .content-wrapper {
  padding: 15px;
}
.as-popover ion-content .product-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
}

.as-popover ion-content .add-on-list {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  padding-inline-start: 10px;
}

.as-popover ion-content .add-on-list li {
  font-size: 14px;
}

.enhancement-wrapper {
  margin-top: 5px;
  padding-top: 3px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
}

.enhancement-wrapper p {
}

.enhancement-wrapper ion-icon {
  font-size: 22px;
  margin-top: 4px;
}
</style>
