export default [
  {
    path: "/in-app-license-information/",
    name: "SubscriptionInformationPage",
    component: () =>
      import("@/views/subscription/SubscriptionInformationPage.vue"),
  },
  {
    path: "/license-page-racer/",
    name: "LicensePageRacer",
    component: () =>
      import("@/views/subscription/LicensePages/LicensePageRacer.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/license-page-pro/",
    name: "LicensePagePro",
    component: () =>
      import("@/views/subscription/LicensePages/LicensePagePro.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/license-page-team/",
    name: "LicensePageTeam",
    component: () =>
      import("@/views/subscription/LicensePages/LicensePageTeam.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/license-page-manufacturer/",
    name: "LicensePageManufacturer",
    component: () =>
      import("@/views/subscription/LicensePages/LicensePageManufacturer.vue"),
    meta: { isPublic: true, hideBar: true },
  },
  {
    path: "/in-app-license-page-racer/",
    name: "InAppLicensePageRacer",
    component: () =>
      import("@/views/subscription/LicensePages/InAppLicensePageRacer.vue"),
  },
  {
    path: "/in-app-license-page-pro/",
    name: "InAppLicensePagePro",
    component: () =>
      import("@/views/subscription/LicensePages/InAppLicensePagePro.vue"),
  },
  {
    path: "/in-app-license-page-team/",
    name: "InAppLicensePageTeam",
    component: () =>
      import("@/views/subscription/LicensePages/InAppLicensePageTeam.vue"),
  },
  {
    path: "/in-app-license-page-manufacturer/",
    name: "InAppLicensePageManufacturer",
    component: () =>
      import(
        "@/views/subscription/LicensePages/InAppLicensePageManufacturer.vue"
      ),
  },
];
