<template>
  <ul class="chat-pan">
    <MessageComponent
      v-for="(msg, msgId) in messages"
      :key="msgId"
      :fromSelf="msg.fromSelf"
      :content="msg.content"
      :embeds="msg.embeds"
      :from="msg.from"
      :name="convoData[msg.from]?.name"
      :avatar="convoData[msg.from]?.avatar"
      :timestamp="msg.timestamp"
    />
  </ul>
</template>
<script setup>
import { h, ref } from "vue";

import { useCalibration, useTurnData } from "@jackallee/usetrackside/reactive";
import CalibrationModal from "@/components/modal-components/CalibrationModal.vue";
import SessionModal from "@/components/modal-components/SessionModal.vue";
import { useSessionModal, useCalibrationModal } from "@/modules/useModals.js";
import { useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
import { formatDateTime } from "@/modules/useDate.js";
const router = useRouter();

function MessageComponent(props) {
  
  var embedVnode;
  let dateSplit;
  let timeSplit;
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };
  // if(props.timestamp !== undefined) {
  //   dateSplit = new Date(props.timestamp).toLocaleDateString("en-US", options);
  // } else {
  //   dateSplit = new Date().toLocaleDateString("en-US", options);
  // }

  // if(props.timestamp !== undefined) {
  //   timeSplit = new Date(props.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  // } else {
  //   timeSplit = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  // }

  if (props.timestamp !== undefined) {
    dateSplit = formatDateTime(props.timestamp)[0];
  } else {
    let newDate = new Date();
    let isoString = newDate.toISOString();
    dateSplit = formatDateTime(isoString)[0];
  }

  if (props.timestamp !== undefined) {
    timeSplit = formatDateTime(props.timestamp)[1];
  } else {
    let newDate = new Date();
    let isoString = newDate.toISOString();
    timeSplit = formatDateTime(isoString)[1];
  }

  //add in styling for props.

  //const timeSplit = new Date(props.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  if (props.embeds) {
    if (props.embeds.type === "Calibration") {
      embedVnode = h(
        "div",
        { class: "embed" },
        h(
          "div",
          {
            onClick: () => {
              //
              useCalibrationModal(props.embeds.data);
            },
            class: "cal-embed",
          },
          "View →"
        )
      );
    } else if (props.embeds.type === "Session") {
      embedVnode = h(
        "div",
        { class: "embed" },
        h(
          "div",
          {
            onClick: () => {
              //
              router.push({
                name: "OutingsPage",
                query: { id: props.embeds.data },
              });
            },
            class: "cal-embed",
          },
          "View →"
        )
      );
    } else {
      embedVnode = h("div", { class: "embed" }, "Unknown Embed");
    }
  }
  return [
    h("li", { class: "chat-item" }, [
      h("div", { class: "datetimewrapper" }, [
        h("p", { class: "time" }, dateSplit),
        h("p", { class: "time" }, "@"),
        h("p", { class: "date" }, timeSplit),
      ]),
      h("div", { class: [{ mine: props.fromSelf }, "bubble"] }, [
        h("p", { class: "messageFrom" }, props.name),
        h(
          "pre",
          { class: "textContent" },
          `${props.content} 
${props.embeds && props.embeds.msg ? props.embeds.msg : ""}`
        ),
        embedVnode,
      ]),
      // h("div", { class: [{ mine: props.fromSelf }, "avatar"] }, [
      //   h("p", { class: "avatar-letters" }, props.avatar),
      // ]),
    ]),
  ];
}

defineProps({
  messages: {
    type: Array,
    required: true,
  },
  convoData: {
    type: Object,
    required: true,
  },
});
</script>

<style scoped>
/* keep div scrolled to bottom */
div {
  overflow: auto;
}

ul.chat-pan {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-left: 0;
}

li {
  background-color: pink;
}

li div {
  list-style: none;
  margin-bottom: 20px;
  padding: 8px 15px;
  border-radius: 12px;
  background: var(--as-chat-individual-background);
  color: black;
  width: fit-content;
  align-self: flex-start;
  max-width: 85%;
  position: relative;
}

li div:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 24px 33px 0 0;
  border-color: var(--as-chat-individual-background) transparent transparent
    transparent;
  bottom: -15px;
  left: 0;
  right: auto;
  margin-left: -5.5px;
}

li div.mine {
  background: var(--ion-color-secondary);
  /* margin-left: 30%;
  margin-right: 8px; */
  /* background: black; */
  align-self: flex-end;
  color: white;
  position: relative;
}

li div.mine:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 27px 18px 0;
  border-color: transparent var(--ion-color-secondary) transparent transparent;
  bottom: -9px;
  right: 0;
  left: auto;
  margin-left: -9.5px;
}

.cal-embed {
  cursor: pointer;
}
</style>
