<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <!-- <SlideoutMenu contentId="main-content" /> -->
    <div class="ion-page">
      <HeaderComp title="Calibrators" />
      <ion-content class="as-content">
        <!-- <ion-grid id="as-new-styles">
          <ion-row>
            <ion-col>
              <ion-card v-for="chat in chatPersonResults" :key="chat.id">
                <ion-card-content class="cal-card-content">
                  <div class="title-wrapper" style="display: flex; justify-content: space-between;">
                    <p>{{ chat.chatName }}</p>
                    <p>{{ chat.date }}</p>
                  </div>
                  <div class="content-wrapper">
                      <p>{{ chat.lastChatItem }}</p>
                  </div>
                </ion-card-content>
              </ion-card>
              
            </ion-col>
          </ion-row>
        </ion-grid> -->
        <!-- <pre>{{  UserProfileReactive }}</pre> -->
        
        <ion-grid
          v-if="!UserProfileReactive.calibrators.isFetching"
          class="ST2Cont"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <p
                style="
                  padding-bottom: 30px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
                class="calibrator-title"
              >
                <strong>My Calibrators</strong>
              </p>
              <div
              v-if="chatPersonResults.length > 0"
                class="calibrator-text"
                style="padding-left: 10px; padding-right: 10px"
              >
                <p>
                  Below is a list of calibrators that have been assigned to you. If you would like to add/remove a calibrator please
                  contact the team at
                  <a href="mailto:team@057motorsports.com"
                    >team@057motorsports.com</a
                  >
                </p>
              </div>

              <!-- <div
                class="welcome-wrapper"
                style="padding-left: 15px"
                v-if="firstLoad.onBoarding"
              >
                <p>
                  Welcome to the Trackside App! First thing we need to do is
                  create a chat session with your calibrator. <br /><br />Click
                  the chat icon&nbsp;<ion-icon
                    slot="icon-only"
                    :icon="chatboxEllipsesOutline"
                  ></ion-icon>
                  below and follow the instructions.
                </p>
              </div> -->

              <div
                class="search-content-wrapper"
                v-if="chatPersonResults.length > 0"
              >
                <ion-list class="garage-page list-previous search-list">
                  <ion-item
                    v-for="calibrator in chatPersonResults"
                    :key="calibrator._id"
                    lines="none"
                  >
                    <ListTemplates
                      :chatButton="chatButton"
                      @chat-clicked="handleChatClicked(calibrator)"
                    >
                      <template #text-wrapper>
                        <div class="text-wrapper calibrator-landing">
                          <p class="title">
                            <strong>{{ calibrator.name }}</strong>
                          </p>
                          <!-- <p>
                            <ion-toggle
                            aria-label="calibrator toggle"
                              @click="toggleCalibrator(calibrator)"
                              color="secondary"
                              :enable-on-off-labels="true"
                            ></ion-toggle>
                          </p> -->
                        </div>
                        <!--text-wrapper-->
                      </template>
                    </ListTemplates>
                  </ion-item>
                </ion-list>
              </div>
              <div class="empty-content-wrapper" v-else>
                <ion-card style="background-color: var(--as-color-white);">
                  <ion-card-content class="cal-card-content">
                    <p class="cal-sess-title">
                      <strong
                        >No calibrators have been assigned to you yet. Please
                        contact 057 to hook up with your calibrator(s).</strong
                      >
                    </p>
                    <br />
                    <p>
                      <strong
                        >You can reach us here
                        <a href="mailto:team@057motorsports.com"
                          >team@057motorsports.com</a
                        ></strong
                      >
                    </p>
                  </ion-card-content>
                </ion-card>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid
          v-else
          class="ST2Cont"
          style="padding-top: 15px; padding-bottom: 15px"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <ion-skeleton-text
                :animated="true"
                style="height: 15vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text
                :animated="true"
                style="height: 3vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- <ion-grid class="ST2Cont">
            <ion-row class="ST2Row">
              <ion-col class="ST2Col1">
                <p style="text-align: center;"><strong>Calibrator Marketplace</strong></p>
                <ion-item class="item-previous-search">
                  <ion-searchbar
                    :debounce="1000"
                    @ionChange="handleChange($event)"
                    placeholder="Find New Calibrator"
                    class="track-search"
                  ></ion-searchbar>
                </ion-item>
                <ion-list class="list-previous search-list">
                  <ion-item
                    lines="none"
                    href="#"
                    v-for="chat in chatPersonResults"
                    :key="chat.id"
                    button
                    @click="
                      $router.push({
                        name: 'ChatPage',
                        params: { chatId: chat.id },
                      })
                    "
                  >
                    <ion-badge
                      class="badge-styles"
                      :class="chat.unreadMessages === 0 ? 'faded' : 'filled'"
                      slot="end"
                      >{{ chat.unreadMessages
                      }}<span :class="chat.unreadMessages === 0 ? 'hide' : 'show'"
                        >*</span
                      ></ion-badge
                    >
                    <ion-label>
                      <div class="title-wrapper">
                        <p>
                          <strong>{{ chat.chatName }}</strong>
                        </p>
                      </div>
                      <div class="car-wrapper" style="">
                        <p>{{ chat.linkedCar }}</p>
                      </div>
                      <div class="chat-content-wrapper">
                        <p class="chat-content">
                        &ldquo; {{ chat.lastChatItem }} &rdquo;
                      </p>
                        <p class="date-content">{{ chat.date }}</p>
                      </div>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid> -->
      </ion-content>
    </div>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonCard,
  IonCardContent,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonList,
  IonBadge,
  IonToggle,
  IonButton,
  IonIcon,
  alertController,
  IonSkeletonText,
} from "@ionic/vue";
import {
  chatboxEllipsesOutline,
  mailOutline,
  personOutline,
  star,
} from "ionicons/icons";
import { toRef, nextTick, reactive, ref } from "vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import SlideoutMenu from "../../../components/slideout-menu/SlideoutMenu.vue";
import { useProfile } from "@/modules/useProfile";
import { useRoute, useRouter } from "vue-router";
import ListTemplates from "../../../components/list-templates/ListTemplates.vue";
const { UserProfileReactive, setChatSession } = useProfile();

const route = useRoute();
const router = useRouter();

const chatPersonResults = toRef(UserProfileReactive.calibrators, "value");

console.log(chatPersonResults);

const firstLoad = toRef(UserProfileReactive, "onBoarding");

const chatButton = {
  active: true,
};

const calibratorActivated = ref(false);

const handleChatClicked = (calibrator) => {
  router.push({
    name: "ChatPage",
    params: {
      chatId: calibrator.chatSessionId,
    },
  });
};

const toggleCalibrator = (calibrator) => {
  console.log(calibrator);
  console.log(UserProfileReactive.chat);
};

function getConversationId(calibratorId) {
  for (let conversationId in UserProfileReactive.chat) {
    let conversation = UserProfileReactive.chat[conversationId];
    //
    if (conversation.users) {
      if (conversation.users.includes(calibratorId)) return conversationId;
    }
  }

  // alertController
  //   .create({
  //     header: "Alert",
  //     subHeader: "Important message",
  //     message: "No conversation has been created yet click OK to create one!",
  //     buttons: [
  //       {
  //         text: "Cancel",
  //         role: "cancel",
  //         handler: () => {},
  //       },
  //       {
  //         text: "OK",
  //         role: "confirm",
  //         handler: () => {
  //           setChatSession([calibratorId, UserProfileReactive.user.id]);
  //           localStorage.setItem("firstLoad", false);
  //           router.push({
  //             name: "ChatLandingPage",
  //           });
  //         },
  //       },
  //     ],
  //   })
  //   .then((alert) => {
  //     alert.present();
  //   });

  // getConversationId(calibratorId);
}
const dateOrderChatRes = chatPersonResults.value.sort((a, b) => {
  return new Date(b.dateISO) - new Date(a.dateISO);
});

const handleChange = (event) => {
  const query = event.target.value.toLowerCase();

  chatPersonResults.value = dateOrderChatRes.filter((d) => {
    return (
      d.chatName.toLowerCase().indexOf(query) > -1 ||
      d.date.toLowerCase().indexOf(query) > -1 ||
      d.linkedCar.toLowerCase().indexOf(query) > -1
    );
  });
};
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/calibrators/CalibratorListPage";

.calibrator-landing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0;
}
</style>
