<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <HeaderComp :title="title">
    <ion-buttons slot="start" @click="showOptions()">
      <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
    </ion-buttons>
  </HeaderComp>
</template>
<script setup>
import {
  IonButtons,
  IonIcon,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { chevronBackOutline } from "ionicons/icons";
import { useRouter } from "vue-router";

import { alertController } from "@ionic/vue";

import { defineProps } from "vue";
defineProps({
  title: {
    type: String,
    required: true,
  },
});

const router = useRouter();

async function showOptions() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will return you to the Session Home page and lose all entered data",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          router.push({ name: "SessionsLanding" });
        },
      },
    ],
  });

  await alert.present();
}
</script>

<style scoped>
ion-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
</style>
