// useSearchFilter.js
import { ref, computed } from 'vue'

export default function useSearchFilter(dataArray, searchFields) {
  const search = ref('')

  // Create a computed property that filters the data based on the search query
  const filteredData = computed(() => {
    return dataArray.value.filter(item => 
      searchFields.some(field => 
        String(item[field]).toLowerCase().includes(search.value.toLowerCase())
      )
    )
  })

  return { search, filteredData }
}

/*

import useSearchFilter from '@/modules/useSearchFilter.js';

<div class="global-searchbar-wrapper">
    <ion-searchbar v-model="search"></ion-searchbar>
</div><!--searchbar-wrapper--> 

const { search, filteredData } = useSearchFilter(sessionResults, ['title', 'id', 'car', 'time', 'date', 'track']);

defineExpose({ search, filteredData });



*/