<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-header>
    <ion-toolbar class="as-toolbar">
      <!-- <ion-buttons slot="start">
        <ion-button @click="cancelHandler">Close</ion-button>
      </ion-buttons> -->
      <ion-title v-if="selectedOuting">{{ selectedOuting?.title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-align-items-center">
    <ion-grid class="ST1Cont top-cont" v-if="selectedOuting">
      <ion-row>
        <ion-col>
          <div class="title-wrapper">
            <p style="padding-left: 15px; font-size: 18px">
              <strong>Outing Details</strong>
            </p>
            <ion-item>
              <p>
                <strong>Date:</strong>
                {{ formatDateTime(selectedOuting.date)[0] }}
              </p>
            </ion-item>
            <ion-item>
              <p>
                <strong>Time:</strong>
                {{ formatDateTime(selectedOuting.date)[1] }}
              </p>
            </ion-item>
            <ion-item>
              <p><strong>Driver:</strong> {{ selectedOuting.driver }}</p>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
          <OutingsTurns  v-if="!isFetching && isTrackReady"
            :CurrentOuting="selectedOuting"
            :TrackData="trackData"
            :Setup="TurnData.Setup"
            :disabledFields="disabledFields"
          />

    <ion-grid class="ST1Cont top-cont" v-if="!isFetching && isTrackReady">
      <ion-row>
        <ion-col>
          <div class="as-track-image">
            <div class="title-wrapper">
              <p class="cal-sess-title" style="margin-top: 0">
                <strong>{{ trackData.name }} Overview</strong>
              </p>
            </div>
            <div class="image-wrapper">
              <img class="as-image" :src="`https://trackside-public-assets.s3.amazonaws.com/track-maps${trackData.path}`" alt="" />
            </div><!--image-wrapper-->
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ST1Cont top-cont" v-else>
      <ion-row>
        <ion-col>
          <div class="as-track-image">
            <div class="title-wrapper">
              <p class="cal-sess-title" style="margin-top: 0">
                <strong>
                  <ion-skeleton-text :animated="true"></ion-skeleton-text
                ></strong>
              </p>
            </div>
            <ion-skeleton-text
              :animated="true"
              style="width: 100%; height: 20vh"
            ></ion-skeleton-text>
            <!-- <SVGDefault /> -->
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ST1Cont top-cont as-laptime-wrapper" v-if="!isFetching">
      <ion-row>
        <ion-col>
          <div class="content-wrapper">
            <div class="flex-wrapper">
              <div class="header-wrapper">
                <ion-text>
                  <p><strong>Lap Times</strong></p>
                </ion-text>
              </div>
            </div>
          </div>
          <div class="lap-time-wrapper">
            <ion-list v-if="selectedOuting.laptimes.length !== 0">
              <ion-item
                :id="`smooth${index}`"
                v-for="(lap, index) in selectedOuting.laptimes"
                v-bind:key="index"
                lines="none"
                :class="{
                  'best-lap':
                    JSON.stringify(lap) ===
                    JSON.stringify(selectedOuting.bestTime),
                }"
              >
                <ion-icon
                  v-if="
                    JSON.stringify(lap) !==
                    JSON.stringify(selectedOuting.bestTime)
                  "
                  :icon="stopwatchOutline"
                  slot="start"
                ></ion-icon>
                <ion-icon
                  v-if="
                    JSON.stringify(lap) ===
                    JSON.stringify(selectedOuting.bestTime)
                  "
                  class="trophy-icon"
                  :icon="medalOutline"
                  slot="start"
                ></ion-icon>
                <p>
                  <strong>Lap {{ index + 1 }}:</strong>&nbsp;&nbsp;{{
                    lap.minutes
                  }}
                  :{{ lap.seconds }} . {{ lap.milliseconds }}
                </p>
              </ion-item>
            </ion-list>
            <p v-else>No lap times recorded.</p>
          </div>
          <!--laptime-wrapper-->
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="ST1Cont top-cont" v-else>
      <ion-row>
        <ion-col>
          <div class="as-track-image">
            <div class="title-wrapper">
              <p class="cal-sess-title" style="margin-top: 0">
                <strong>
                  <ion-skeleton-text :animated="true"></ion-skeleton-text
                ></strong>
              </p>
            </div>
            <ion-skeleton-text
              :animated="true"
              style="width: 100%; height: 20vh"
            ></ion-skeleton-text>
            <!-- <SVGDefault /> -->
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-fab class="as-fab" slot="fixed" vertical="bottom" horizontal="end">
      <ion-fab-button class="as-fab-button" @click="cancelHandler">
        <ion-icon :icon="closeOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
  <!-- </ion-modal> -->
</template>
<script setup>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  modalController,
  IonImg,
  IonText,
  //pickerController,
  IonList,
  IonItem,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonFooter,
  IonFab,
  IonFabButton,
} from "@ionic/vue";

// import CalibrationOverview from "./CalibrationOverview.vue";
import { useTurnData, useTracks } from "@jackallee/usetrackside/reactive";
import CornerReadOnly from "../session/CornerReadOnly.vue";
import {
  closeOutline,
  stopwatchOutline,
  trophyOutline,
  medalOutline,
  ribbonOutline,
  starOutline,
  star,
} from "ionicons/icons";

import "swiper/css";
// import 'swiper/css/bundle';

// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/virtual";
import { ref, onBeforeMount } from "vue";
import OutingsTurns from "../outings/OutingsTurns.vue";

const props = defineProps({
  fetchSession: {
    type: Boolean,
    required: true,
  },
  sessionId: {
    type: String,
    required: false,
  },
  turnData: { type: Object, required: false },
  outingId: { type: String, required: true },
});
const { TurnData, loadTurnData } = useTurnData(props.sessionId);
const { getTrack } = useTracks();

const isTrackReady = ref(false);
const disabledFields = ref(true);
const selectedOuting = ref();
const trackData = ref();
const isFetching = ref(true);

function formatDateTime(date) {
  date = new Date(date);

  let dateFormatted = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let timeFormatted = date.toLocaleTimeString();

  return [dateFormatted, timeFormatted];
}

onBeforeMount(() => {
  if (props.fetchSession) {
    loadTurnData({
      onComplete: () => {
        isFetching.value = false;
        selectedOuting.value = TurnData.value.outings.find(
          (el) => el._id === props.outingId
        );
      },
    });
  } else {
    if (!props.turnData) {
      throw new Error("Invalid Session In Session Modal: " + props.turnData);
    }
    TurnData.value = props.turnData;
    isFetching.value = false;
    selectedOuting.value = TurnData.value.outings.find(
      (el) => el._id === props.outingId
    );
  }

  getTrack(TurnData.value.Setup.trackId, {
      onSuccess: (res) => {
        trackData.value = res.data.track;
        isTrackReady.value = true;
      },
      onError: (err) => {
        console.error(err);
      },
    });
});

const cancelHandler = () => {
  return modalController.dismiss(null, "cancel");
};

const asPagination = {
  dynamicBullets: true,
  dynamicMainBullets: 1,
  renderBullet: function (index, className) {
    return '<span  class="' + className + '">' + (index + 1) + "</span>";
  },
  // return '<span class="' + className + '">' + (index + 1) + '</span>';
  clickable: true,
};
</script>
<style scoped lang="scss">

.top-cont {
  margin-top: 30px;
  border-top: solid 8px var(--ion-color-black);
}
ion-item.best-lap {
  & p {
    font-weight: 700;
    font-style: italic;
  }

  & ion-icon {
    &.trophy-icon {
    }
  }
}
.as-toolbar {
  padding-top: 5px;
}

.as-fab {
  & .as-fab-button {
    &::part(native) {
      background-color: var(--ion-color-secondary);
    }
  }
}
.session-setup-title-wrapper {
  padding-left: 15px;

  & p {
    margin-bottom: 0;
  }
}

.session-setup-content-wrapper {
  padding-left: 15px;
  padding-right: 15px;

  & .main-list-session-setup {
    list-style-type: none;
    padding-inline-start: 0;

    & > li {
      margin-bottom: 15px;
      border-bottom: solid 1px #f0f0f0;
      padding-bottom: 15px;
      line-height: 1.4em;
    }
  }
}

.subtitle-trackname {
  font-size: 30px;
  font-family: Helvetica, sans-serif;
  text-transform: capitalize;
}

ion-card {
  background-color: var(--as-ion-grid-background);
  margin: 0;
  box-shadow: none;
}

.as-track-image {
  margin-bottom: 30px;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.as-track-image {
  background-color: white;
  /* padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px; */
  padding: 15px;
}

.as-laptime-wrapper {
  background-color: var(--as-ion-grid-background);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
}

.as-laptime-wrapper {
  margin-top: 20px;
}

.as-laptime-wrapper .button-wrapper {
  margin-top: -5px;
  text-align: right;
}

/* .as-laptime-wrapper .button-wrapper ion-button::part(native) {
  background-color: var(--ion-color-secondary);
} */

.as-laptime-wrapper .lap-time-wrapper ion-list ion-item::part(native) {
  margin-left: 0;
  padding-left: 0;
}

.as-track-image ion-text {
  font-weight: 700;
  color: black;
}

.half-half-styleT .image-wrapper svg {
  width: 100%;
  object-fit: contain;
}

.swiper {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
}

.swiper-slide ion-card {
  border-radius: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.lap-button {
  color: var(--ion-color-black);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}
</style>
