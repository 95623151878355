<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page>
    <ion-header :class="isTurnComponent ? 'padding-class turn-header' : ''">
      <HeaderCompSession
        :title="isTurnComponent ? 'Outing Log' : 'Track Performance'"
      />
      <ion-toolbar class="segment-toolbar" v-if="!isTurnComponent">
        <ion-segment
          @ionChange="setComponent"
          id="segSection"
          :value="segmentVal"
          :disabled="!isSessionSetup || isTurnComponent"
        >
          <ion-segment-button value="session-setup">Setup</ion-segment-button>
          <ion-segment-button v-if="isSessionSetup" value="session-outings">
            Outings
          </ion-segment-button>
          <!-- <ion-segment-button v-if="isSessionSetup" value="session-editor">
            Outings Editor
          </ion-segment-button> -->
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-align-items-center">
      <!-- <ion-grid
        class="ST2Cont"
        v-if="!EditOutingHide && isSessionSetup && !isTurnComponent"
      >
        <ion-row class="ST2Row">
          <ion-col class="ST2Col1 cal-sess-butt-wrap">
            <div
              class="title-wrapper-main"
              style="
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: solid 1px #f0f0f0;
              "
            >
              <p class="cali-section-title"><strong>Outings </strong></p>
              <ion-button
                size="default"
                @click="goToTurnByTurn()"
                class="add-button"
                fill="solid"
              >
                <ion-icon slot="end" :icon="addCircle"></ion-icon>
                New Outing
              </ion-button>
            </div>
            <div class="outing-details">
              <p><strong>Session Name:</strong> {{ TurnData.Setup.title }}</p>
              <p>
                <strong>Track:</strong> {{ TurnData.Setup.track.trackName }}
              </p>
              <p><strong>Date:</strong> {{ TurnData.Setup.date }}</p>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid> -->
      <!-- <KeepAlive> -->
      <component
        :is="currentView"
        :CurrentOuting="CurrentOuting"
        :TurnData="TurnData"
        :Setup="TurnData.Setup"
        :isSetup="isSessionSetup"
        :TrackData="TrackData"
        @edit:Outing="handleEditOuting"
        @delete="deleteOuting"
        @outingCreate="goToTurnByTurn"
      ></component>
      <!-- </KeepAlive> -->
      <div class="as-error-wrapper">
        <!-- <p
            class="button-global-content-wrapper error-message-global"
            v-if="CalibrationState.error">
            
            {{ CalibrationState.message }}
          </p> -->
      </div>
      <div class="button-outer-wrapper" v-if="isTurnComponent">
        <div class="button-global-content-wrapper">
          <div class="button-wrapper cancel-button">
            <ion-button fill="outline" @click="cancelOutingLanding()">
              Cancel
            </ion-button>
          </div>

          <div class="button-wrapper submit-button">
            <ion-button @click="handleOutingSave()"> Save Outing </ion-button>
          </div>
        </div>
      </div>
      <div class="button-outer-wrapper" v-else>
        <div class="button-global-content-wrapper">
          <div class="button-wrapper cancel-button">
            <ion-button fill="outline" @click="cancelHome()">
              Cancel
            </ion-button>
          </div>

          <div v-if="isSessionSetup" class="button-wrapper submit-button">
            <ion-button @click="handleSave()">Save Event</ion-button>
          </div>
          <div v-else class="button-wrapper submit-button">
            <ion-button @click="setupEvent()"> Setup Event </ion-button>
          </div>
        </div>
      </div>

      <!-- <ion-modal ref="modal" trigger="open-modal" class="calibrationModal">
        <CalibrationModal :fetchCal="false" :calibration="calReactive" />
      </ion-modal> -->
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonHeader,
  IonModal,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { addCircle } from "ionicons/icons";
import { ref, shallowRef, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import HeaderCompSession from "../components/header-components/HeaderCompSession.vue";
import SessionSetup from "@/components/session/SessionSetup.vue";
import SessionTurns from "@/components/session/SessionTurns.vue";
import SessionOutingLanding from "@/components/session/SessionOutingLanding.vue";
import { useTurnData, useTracks } from "@jackallee/usetrackside/reactive";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useToast, useErrorToast } from "@/modules/useToasts.js";

const { isLoading } = useLoadingSpinner();
const sessionId = ref(undefined);
const {
  TurnData,
  TrackData,
  createTurnData,
  loadTurnData,
  uploadTurnData,
  createNewOuting,
} = useTurnData(sessionId);

const { tracks, getTrack } = useTracks();

const route = useRoute();
const router = useRouter();

const query = route.query;

const currentView = shallowRef(SessionSetup);
const segmentVal = ref("session-setup");
const isTurnComponent = ref(false);
const isSessionSetup = ref(false);
const CurrentOuting = ref(undefined);
const EditOutingHide = ref(true);
const isOpen = ref(false);
const trackData = ref(undefined);
const isTrackReady = ref(false);

// console.log("TrackData", TrackData.value);
// // watchEffect(() => {
// if (TurnData.value.Setup.trackId) {
//   if(TrackData.value) trackData.value = TrackData.value;
//   trackData.value.url = `https://trackside-public-assets.s3.amazonaws.com/track-maps${trackData.value.path}`;
//   // getTrack(TurnData.value.Setup.trackId, {
//   //   onSuccess: (res) => {
//   //     trackData.value = res.data.track;
//   //     isTrackReady.value = true;
//   //   },
//   //   onError: (err) => {
//   //     console.error(err);
//   //   },
//   // });
// }
// // });

TurnData.value.Setup.date = new Date().toISOString();
if (query.id) {
  isLoading.value = true;
  sessionId.value = query.id;
  loadTurnData({
    onSuccess: (res) => {
      isSessionSetup.value = true;
      TurnData.value._id = query.id;
    },
    onComplete: () => {
      isLoading.value = false;
    },
  });
}

if (query.outing) {
  isLoading.value = true;
  sessionId.value = query.id;
  currentView.value = SessionOutingLanding;
  segmentVal.value = "session-outings";
  loadTurnData({
    onSuccess: () => {
      isSessionSetup.value = true;
      TurnData.value._id = query.id;
    },
    onComplete: () => {
      isLoading.value = false;
    },
  });
}

function setupEvent() {
  if (!TurnData.value.Setup.title) {
    useToast({
      message: "'Session Name' must not be empty",
      duration: null,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    throw new Error("Attempted to setup an Event (session) without a Title!");
  }
  if (!TurnData.value.Setup.trackId) {
    useToast({
      message: "A Track must be selected",
      duration: null,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    throw new Error(
      "Attempted to setup an Event (session) without a track selected!"
    );
  }
  if (!TurnData.value.Setup.car) {
    useToast({
      message: "A Car must be selected",
      duration: null,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    throw new Error(
      "Attempted to setup an Event (session) without a car selected!"
    );
  }

  //Add Prompt Here to make sure that the user understands the track and car cannot be changed from this point
  isLoading.value = true;
  // createTurnData({ turnsCount: TurnData.value.Setup.track.turnsCount });
  getTrack(TurnData.value.Setup.trackId, {
    onSuccess:(res) =>{
      TrackData.value = res.data.track;
    },
    onError: () => {
      useToast({
      message: "Invalid or Unable to Fetch Track",
      duration: null,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    throw new Error("Error Getting Track");
    }
  });

  uploadTurnData({
    onComplete: () => {
      isLoading.value = false;
    },
    onSuccess: (response) => {
      useToast({
        message: "Session Saved Successfully",
      });
      isTurnComponent.value = false;
      currentView.value = SessionOutingLanding;
      if (!sessionId.value) {
        TurnData.value._id = response.data.returnedId;
        sessionId.value = response.data.returnedId;
      }
    },
    onError: (e) => {
      console.error(e);
      useErrorToast(e);
    },
  });

  isSessionSetup.value = true;
  currentView.value = SessionOutingLanding;
  segmentVal.value = "session-outings";
}

const goToTurnByTurn = () => {
  const newLen = createNewOuting({
    turnsCount: TrackData.value.turnsCount,
  });
  if (newLen > 0) {
    CurrentOuting.value = TurnData.value.outings[newLen - 1];

    isTurnComponent.value = true;
    currentView.value = SessionTurns;
  }
};

function handleSave() {
  isLoading.value = true;
  uploadTurnData({
    onComplete: () => {
      isLoading.value = false;
    },
    onSuccess: (response) => {
      useToast({
        message: "Session Saved Successfully",
      });
      router.push("/sessions/");
    },
    onError: (e) => {
      console.error(e);
      useErrorToast(e);
    },
  });
}

//

const handleOutingSave = () => {
  if (!CurrentOuting.value.title) {
    useToast({
      message: "'Outing Name' must not be empty",
      duration: null,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    throw new Error("Attempted to save an outing without a Title!");
  }
  isLoading.value = true;
  uploadTurnData({
    onComplete: () => {
      isLoading.value = false;
    },
    onSuccess: (response) => {
      useToast({
        message: "Outing Saved Successfully",
      });
      isTurnComponent.value = false;

      CurrentOuting.value = undefined;
      sessionId.value = TurnData.value.id;
      currentView.value = SessionOutingLanding;
    },
    onError: (e) => {
      console.error(e);
      useErrorToast(e);
    },
  });
};
function handleEditOuting(outingId) {
  //
  CurrentOuting.value = TurnData.value.outings.find(
    (outing) => outing._id === outingId
  );
  isTurnComponent.value = true;
  currentView.value = SessionTurns;
  // TurnData.value.CurrentOuting = outing;
}

function setComponent(e) {
  var switcherVal = e.target.value;
  //
  if (switcherVal === "session-outings") {
    //
    EditOutingHide.value = false;
    //
    currentView.value = SessionOutingLanding;
    isTurnComponent.value = false;
  } else if (switcherVal === "session-editor") {
    EditOutingHide.value = true;
    currentView.value = SessionTurns;
    isTurnComponent.value = true;
  } else {
    EditOutingHide.value = true;
    currentView.value = SessionSetup;
    isTurnComponent.value = false;
  }
}

async function cancelOutingLanding() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will return you to the Outings Landing page and lose all entered data",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          currentView.value = SessionOutingLanding;
          isTurnComponent.value = false;
        },
      },
    ],
  });

  await alert.present();
}

async function cancelHome() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will return you to the Session Landing page and lose all entered data",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",

        handler: () => {
          router.push({ name: "SessionsLanding" });
        },
      },
    ],
  });

  await alert.present();
}

async function deleteOutingCheck(outingId) {
  isOpen.value = true;

  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will delete the outing. IMPORTANT: The outing will not be recoverable.",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          const outingIndex = TurnData.value.outings.findIndex(
            (outing) => outing._id === outingId
          );

          if (outingIndex > -1) {
            TurnData.value.outings.splice(outingIndex, 1);

            useToast({
              message:
                "Outing Deleted, you must save the session to persist this change",
            });
          }
        },
      },
    ],
  });

  await alert.present();
}

function deleteOuting(outingId) {
  deleteOutingCheck(outingId);
}
</script>
<style scoped lang="scss">
.padding-class.turn-header {
  & ion-toolbar {
    padding-top: 10px;
  }
}
.ST2Cont {
  margin: 15px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-top: solid 8px var(--ion-color-black);
  margin-top: 30px;
  padding-top: 10px;

  & .ST2Row {
    & .ST2Col1 {
      & .title-wrapper-main {
        display: flex;
        justify-content: space-between;

        & p.cali-section-title {
        }

        & .add-button {
          margin-top: 6px;

          &::part(native) {
            background: var(--as-red-gradient-button);
            // background-color: var(--ion-color-secondary);
          }

          & ion-icon {
            font-size: 30px;
            color: white;
          }
        }
      }

      & .outing-details {
        padding: 0 15px;
      }
    }
  }
}
.button-outer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  & .button-global-content-wrapper {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    background-color: var(--as-chat-white-transp);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-inline: 15px;
    width: 100%;

    & .button-wrapper.cancel-button {
      & ion-button {
        &::part(native) {
          color: var(--ion-color-black);
          border: solid 1px var(--ion-color-black);
        }
      }
    }

    & .button-wrapper.submit-button {
      & ion-button {
        &::part(native) {
          background: var(--as-red-gradient-button);
        }
      }
    }
  }
}
</style>
