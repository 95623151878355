<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-toolbar>
    <slot>
      <p
        class="network-error"
        v-if="!appStatus.isConnected && appStatus.isSetup"
      >
        Network Error!
      </p>
      <ion-buttons slot="start">
        <ion-back-button text="" :default-href="asHref"></ion-back-button>
      </ion-buttons>
      <ion-icon></ion-icon>
    </slot>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</template>
<script setup>
import {
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonIcon,
  IonMenuButton,
  IonProgressBar,
} from "@ionic/vue";
import { useAppStatus } from "@/modules/useProfile";

const appStatus = useAppStatus();
defineProps({
  title: {
    type: String,
    required: true,
  },
  asHref: {
    type: String,
    required: false,
  },
});
</script>

<style scoped>
ion-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

ion-back-button::part(native) {
  color: var(--ion-color-black);
}

.network-error {
  position: fixed;
  margin-top: 0;
  margin-bottom: 0;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 30px;
  text-align: center;
  font-weight: 700;
  color: red;
  border-radius: 8px;
}
</style>
