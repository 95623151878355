<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <div class="ion-page">
      <HeaderComp title="Licenses">
        <ion-buttons slot="start">
          <ion-button
            slot="icon-only"
            @click="$router.push({ name: 'Entry' })"
          >
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
          </ion-buttons>
      </HeaderComp>
      <ion-content>
        <div class="as-page-wrapper home-page">
          <ion-grid class="ST1Cont">
            <ion-row class="ST1Row">
              <ion-col class="ST1Col1">
                <div class="title-content-wrapper">
                  <h2>Licenses</h2>
                  <p>
                    Choose your license here. <br /><br />There are
                    <strong>4 licenses</strong> to choose from. These can be
                    paid annually or month by month.
                  </p>
                </div>
                <!--content-wrapper-->
              </ion-col>
            </ion-row>
            <ion-row class="ST1Row">
              <ion-col v-if="isReady" class="ST1Col1">
                <ion-card class="first-card as-card racer">
                  <ion-card-header>
                    <ion-card-title>Racer License</ion-card-title>
                  </ion-card-header>

                  <ion-card-content>
                    <h3
                      style="
                        text-align: center;
                        font-size: 20px;
                        color: var(--ion-color-black;);
                        margin-bottom: 15px;
                      "
                    >
                      <strong
                        >$500 billed annually or $50 month-to-month</strong
                      >
                    </h3>
                    <ion-list class="licence-item-list">
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label>
                          Access Trackside by 057 Calibration Services w/live
                          Chat
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label> Garage </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label> Sessions </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label> Calibrations </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label> Education </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label>
                          Bring pros, team & manufactures in and out as needed
                          to help with driver coaching, calibration, race
                          engineering and more
                        </ion-label>
                      </ion-item>
                    </ion-list>
                    <div class="subscription-choice-wrapper">
                      <p class="intro-text">
                        Please choose whether to pay monthly or annually
                      </p>
                      <ion-radio-group v-model="termSelectorRacer" value="monthly">
                        <ion-radio value="monthly"
                          >Monthly ($50 per month)</ion-radio
                        ><br />
                        <ion-radio value="annually"
                          >Annually ($500 each year)</ion-radio
                        ><br />
                      </ion-radio-group>
                      <p class="small-text">
                        Subscriptions will renew automatically until cancelled
                      </p>
                    </div>
                    <!--subscription-choice-wrapper-->
                  </ion-card-content>
                  <div class="button-wrapper">
                    <ion-button
                      @click="handleButtonClick('racer')"
                      color="secondary"
                      expand="block"
                      >Choose {{ termSelectorRacer === 'monthly' ? 'Monthly' : 'Annually' }} License</ion-button
                    >
                  </div>
                  <div class="learn-more-wrapper">
                    <p @click="$router.push({ name: 'LicensePageRacer' })">
                      Learn More
                    </p>
                  </div>
                </ion-card>
                <ion-card class="as-card pro">
                  <ion-card-header>
                    <ion-card-title>Pro License</ion-card-title>
                    <ion-card-subtitle></ion-card-subtitle>
                  </ion-card-header>

                  <ion-card-content>
                    <h3
                      style="
                        text-align: center;
                        font-size: 20px;
                        color: var(--ion-color-black;);
                        margin-bottom: 15px;
                      "
                    >
                      <strong
                        >$2,000 billed annually or $200 month-to-month</strong
                      >
                    </h3>
                    <ion-list class="licence-item-list">
                      <ion-item lines="none">
                        <ion-label>
                          Everything that's included in the Racer License,
                          plus...
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label>
                          Manage all your client data - app Q1 2023, now
                          actively managed by 057
                        </ion-label>
                      </ion-item>
                    </ion-list>
                    <div class="subscription-choice-wrapper">
                      <p class="intro-text">
                        Please choose whether to pay monthly or annually
                      </p>
                      <ion-radio-group v-model="termSelectorPro" value="monthly">
                        <ion-radio value="monthly"
                          >Monthly ($200 per month)</ion-radio
                        ><br />
                        <ion-radio value="annually"
                          >Annually ($2,000 each year)</ion-radio
                        ><br />
                      </ion-radio-group>
                      <p class="small-text">
                        Subscriptions will renew automatically until cancelled
                      </p>
                    </div>
                    <!--subscription-choice-wrapper-->
                  </ion-card-content>
                  <div class="button-wrapper">
                    <ion-button
                      @click="handleButtonClick('pro')"
                      color="secondary"
                      expand="block"
                      >{{ termSelectorPro === 'monthly' ? 'Monthly' : 'Annually' }}</ion-button
                    >
                  </div>
                  <div class="learn-more-wrapper">
                    <p @click="$router.push({ name: 'LicensePagePro' })">
                      Learn More
                    </p>
                  </div>
                </ion-card>
                <ion-card class="as-card team">
                  <ion-card-header>
                    <ion-card-title>Team License</ion-card-title>
                  </ion-card-header>

                  <ion-card-content>
                    <h3
                      style="
                        text-align: center;
                        font-size: 20px;
                        color: var(--ion-color-black;);
                        margin-bottom: 15px;
                      "
                    >
                      <strong
                        >$3,000 billed annually or $300 month-to-month</strong
                      >
                    </h3>
                    <ion-list class="licence-item-list">
                      <ion-item lines="none">
                        <ion-label>
                          Everything that's included in the Racer and Pro
                          Licenses, plus...
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label>
                          1 manager and 1 crew included. Additional crew
                          $100/mth, 1k/yr
                        </ion-label>
                      </ion-item>
                    </ion-list>
                    <div class="subscription-choice-wrapper">
                      <p class="intro-text">
                        Please choose whether to pay monthly or annually
                      </p>
                      <ion-radio-group v-model="termSelectorTeam" value="monthly">
                        <ion-radio value="monthly"
                          >Monthly ($300 per month)</ion-radio
                        ><br />
                        <ion-radio value="annually"
                          >Annually ($3,000 each year)</ion-radio
                        ><br />
                      </ion-radio-group>
                      <p class="small-text">
                        Subscriptions will renew automatically until cancelled
                      </p>
                    </div>
                    <!--subscription-choice-wrapper-->
                  </ion-card-content>
                  <div class="button-wrapper">
                    <ion-button
                      @click="handleButtonClick('team')"
                      color="secondary"
                      expand="block"
                      >{{ termSelectorTeam === 'monthly' ? 'Monthly' : 'Annually' }}</ion-button
                    >
                  </div>
                  <div class="learn-more-wrapper">
                    <p @click="$router.push({ name: 'LicensePageTeam' })">
                      Learn More
                    </p>
                  </div>
                </ion-card>
                <ion-card class="as-card manufacturer">
                  <ion-card-header>
                    <ion-card-title>Manufacturer License</ion-card-title>
                  </ion-card-header>

                  <ion-card-content>
                    <h3
                      style="
                        text-align: center;
                        font-size: 20px;
                        color: var(--ion-color-black;);
                        margin-bottom: 15px;
                      "
                    >
                      <strong
                        >$10,000 billed annually or $1,000 month-to-month</strong
                      >
                    </h3>
                    <ion-list class="licence-item-list">
                      <ion-item lines="none">
                        <ion-label>
                          Everything that's included in the Racer and Pro
                          Licenses, plus...
                        </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label> Support teams, pros and racers. </ion-label>
                      </ion-item>
                      <ion-item lines="none">
                        <ion-icon
                          :icon="checkmarkOutline"
                          slot="start"
                        ></ion-icon>
                        <ion-label>
                          3 staff included, additional $200/mth, $2k/yr
                        </ion-label>
                      </ion-item>
                    </ion-list>
                    <div class="subscription-choice-wrapper">
                      <p class="intro-text">
                        Please choose whether to pay monthly or annually
                      </p>
                      <ion-radio-group v-model="termSelectorManufacturer" value="monthly">
                        <ion-radio value="monthly"
                          >Monthly ($1,000 per month)</ion-radio
                        ><br />
                        <ion-radio value="annually"
                          >Annually ($10,000 each year)</ion-radio
                        ><br />
                      </ion-radio-group>
                      <p class="small-text">
                        Subscriptions will renew automatically until cancelled
                      </p>
                    </div>
                    <!--subscription-choice-wrapper-->
                  </ion-card-content>
                  <div class="button-wrapper">
                    <ion-button
                      @click="handleButtonClick('manufacturer')"
                      color="secondary"
                      expand="block"
                      >{{ termSelectorManufacturer === 'monthly' ? 'Monthly' : 'Annually' }}</ion-button
                    >
                  </div>
                  <div class="learn-more-wrapper">
                    <p @click="$router.push({ name: 'LicensePageManufacturer' })">
                      Learn More
                    </p>
                  </div>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <!--as-page-wrapper-->
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonThumbnail,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonList,
  IonIcon,
  IonItem,
  IonCardSubtitle,
  IonButtons,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { checkboxOutline, checkmarkOutline, chevronBackOutline } from "ionicons/icons";
import { useUser } from "@jackallee/usetrackside/reactive";
import { useToast } from "@/modules/useToasts.js";
import axios from "axios";
const APIURL = import.meta.env.VITE_API_URL;
let prices = ref();
import { useRouter } from "vue-router";
const router = useRouter();
const { userState } = useUser();
const isReady = ref(false);

const termSelectorRacer = ref("monthly");
const termSelectorPro = ref("monthly");
const termSelectorTeam = ref("monthly");
const termSelectorManufacturer = ref("monthly");

const termTitle = ref("Monthly");
const termQuery = ref("month");

const subPlans = {
  prod_Nwzklmix8v5ANs: {
    productId: "prod_Nwzklmix8v5ANs",
    name: "Racer License",
    description: "",
    hidden: false,
    sendToSupport: false,
  },
  prod_NwzlOp7ZH2HoEc: {
    productId: "prod_NwzlOp7ZH2HoEc",
    name: "Pro License",
    description: "",
    hidden: false,
    sendToSupport: false,
  },
  prod_Nwzovq4zXG5pvV: {
    productId: "prod_Nwzovq4zXG5pvV",
    name: "Team License",
    description: "",
    hidden: false,
    sendToSupport: true,
  },
  manufacturer: {
    productId: "manufacturer",
    monthlyPriceId: "",
    yearlyPriceId: "",
    name: "Manufacturer License",
    description: "",
    hidden: false,
    sendToSupport: true,
  },
};

watch(termSelectorRacer, (newVal) => {
  if (newVal === "monthly") {
    termTitle.value = "Monthly";
    termQuery.value = "month"; // this is the value that stripe uses
  } else {
    termTitle.value = "Annual";
    termQuery.value = "year"; // this is the value that stripe uses
  }
  
});

watch(termSelectorPro, (newVal) => {
  if (newVal === "monthly") {
    termTitle.value = "Monthly";
    termQuery.value = "month"; // this is the value that stripe uses
  } else {
    termTitle.value = "Annual";
    termQuery.value = "year"; // this is the value that stripe uses
  }
  
});

watch(termSelectorTeam, (newVal) => {
  if (newVal === "monthly") {
    termTitle.value = "Monthly";
    termQuery.value = "month"; // this is the value that stripe uses
  } else {
    termTitle.value = "Annual";
    termQuery.value = "year"; // this is the value that stripe uses
  }
  
});

watch(termSelectorManufacturer, (newVal) => {
  if (newVal === "monthly") {
    termTitle.value = "Monthly";
    termQuery.value = "month"; // this is the value that stripe uses
  } else {
    termTitle.value = "Annual";
    termQuery.value = "year"; // this is the value that stripe uses
  }
  
});

//identifier which will say which radio buttons they are.

function fetchPrices() {
  axios
    .get(`${APIURL}/billing/get-subscription-prices`)
    .then((res) => {
      
      prices.value = res.data;
      isReady.value = true;
    })
    .catch((err) => {
      
    });
}
fetchPrices();

function handleButtonClick(planKey) {
  const planData = prices.value.plans[planKey];
  
  if (planData.sendToSupport) {
    useToast({
      message:
        "Please contact team@057motorsports.com and a team member will be happy to help you",
    });
    router.push({ name: "ContactSupportPage" });
    return;
  }
  if (userState.loggedIn) {
    router.push({
      name: "Checkout",
      query: {
        plan: planData.prices[termQuery.value].id,
      },
    });
    //termQuery.value = month/annual
    //?plan=price_1NG2wTKjsTGrpHGbpvlE8kfl
  } else {
    useToast({ message: "Please login or create an account to continue" });
    router.push({ name: "Entry" });
  }
}
</script>

<style scoped lang="scss">
.subscription-choice-wrapper {
  margin-top: 30px;
  border-top: solid 1px black;
  padding-top: 30px;

  & p {
    &.intro-text {
      margin-bottom: 15px;
    }
    &.small-text {
      font-size: 12px;
    }
  }

  & ion-radio-group {
    margin-top: 10px;
    border-top: solid 1px #f0f0f0;

    & ion-radio {
      padding-top: 10px;
      --border-radius: 4px;
      --inner-border-radius: 4px;

      --color: #ddd;
      --color-checked: var(--ion-color-secondary);
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: var(--ion-color-black);
    }

    & ion-radio.ios::part(container) {
      width: 20px;
      height: 20px;

      border: 2px solid #ddd;
      border-radius: 4px;
    }

    & .radio-checked.ios::part(container) {
      border-color: var(--ion-color-secondary);
    }
  }
}
.licence-item-list ion-item {
  font-size: 14px;
}

.licence-item-list ion-item ion-icon {
  margin-inline: 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.licence-item-list ion-item ion-label {
  white-space: normal;
}

.licence-item-list ion-item::part(native) {
  padding-inline-start: 5px;
}

.as-card.racer {
  border-top: solid 8px var(--as-color-racer);
}

.as-card.pro {
  border-top: solid 8px var(--as-color-pro);
}

.as-card.team {
  border-top: solid 8px var(--as-color-team);
}

.as-card.manufacturer {
  border-top: solid 8px var(--as-color-manufacturer);
}
.title-content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.title-content-wrapper h2 {
  font-weight: 700;
  text-align: center;
}

.title-content-wrapper p {
}

ion-card {
  background: var(--ion-color-white);
  position: relative;
  overflow: visible;
  padding-bottom: 15px;
}

ion-card-title {
  font-size: 19px;
  margin-bottom: 7.5px;
  text-align: center;
}

ion-card-subtitle {
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  color: var(--ion-color-black);
}

.price-circle {
  text-align: center;
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: var(--ion-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
  z-index: 10000;
  font-size: 16px;
}

.button-wrapper {
  padding: 15px;
  padding-bottom: 0;
  padding-top: 0;
  justify-content: flex-end;
}

.learn-more-wrapper {
  padding: 20px 20px 20px;
  text-align: center;
}

.learn-more-wrapper p {
  display: inline;
  text-decoration: none;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: solid 1px black;
}

.learn-more-wrapper p:hover {
  cursor: pointer;
}

.as-card {
  margin-top: 45px;
}

.as-card.first-card {
  margin-top: 20px;
}
</style>
