<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-grid class="ModalCont" v-if="!loadingValue">
    <ion-row class="title-row">
      <!-- <div class="red-circle-wrapper">
        <img class="as-red-circle" :src="image" alt="" />
      </div> -->
      <ion-col class="title-col">
        <h2>{{ calReactive.Header.title }}</h2>
        <!-- <p>{{ car }}</p> -->
        <p class="car-title">{{ carName }}</p>
      </ion-col>
    </ion-row>
    <ion-row class="ST2Row corner-section">
      <ion-col size="12" class="corner-title-col">
        <div class="content-wrapper">
          <p class="title ion-text-center">
            <strong>Corner Weight</strong>
          </p>
        </div>
      </ion-col>
      <ion-col size="3" class="front-left gray-corner">
        <p>{{ calReactive.CornerWeights.LF }}</p>
      </ion-col>
      <ion-col size="3" class="cross">
        <p>Cross</p>
      </ion-col>
      <ion-col size="3" class="cross">
        <p>{{ calReactive.CornerWeights.Cross }}%</p>
      </ion-col>
      <ion-col size="3" class="front-right gray-corner">
        <p>{{ calReactive.CornerWeights.RF }}</p>
      </ion-col>
      <ion-col size="3" class="rear-left gray-corner">
        <p>{{ calReactive.CornerWeights.LR }}</p>
      </ion-col>
      <ion-col size="3" class="total">
        <p>Total</p>
      </ion-col>
      <ion-col size="3" class="total">
        <p>{{ calReactive.CornerWeights.Total }}</p>
      </ion-col>
      <ion-col size="3" class="rear-right gray-corner">
        <p>{{ calReactive.CornerWeights.RR }}</p>
      </ion-col>
      <ion-col size="3" class="total as-margin-top">
        <p>Fuel</p>
      </ion-col>
      <ion-col size="3" class="cross as-margin-top">
        <p>{{ calReactive.CornerWeights.Fuel }}</p>
      </ion-col>
      <ion-col size="3" class="total as-margin-top">
        <p>Ballast</p>
      </ion-col>
      <ion-col size="3" class="cross as-margin-top">
        <p>{{ calReactive.CornerWeights.Ballast }}</p>
      </ion-col>
    </ion-row>
    <!-- <CalTitleCompVue car="{{ calReactive.Header.title }}" /> -->
    <ion-row class="ST1Row">
      <ion-col class="ST1Col" size="6">
        <TireData :cal="calReactive" title="Left Front" cornerSide="LF" />
      </ion-col>
      <ion-col class="ST1Col" size="6">
        <TireData :cal="calReactive" title="Right Front" cornerSide="RF" />
      </ion-col>
    </ion-row>
    <ion-row class="ST1Row">
      <ion-col class="ST1Col" size="6">
        <TireData :cal="calReactive" title="Left Rear" cornerSide="LR" />
      </ion-col>
      <ion-col class="ST1Col" size="6">
        <TireData :cal="calReactive" title="Right Rear" cornerSide="RR" />
      </ion-col>
    </ion-row>
    <ion-row class="ST1Row">
      <ion-col size="12" class="corner-title-col">
        <div class="content-wrapper">
          <p class="title title-header ion-text-center">
            <strong>Aero-Dynamics</strong>
          </p>
        </div>
      </ion-col>
      <ion-col>
        <ul class="input-list list-aero">
          <li>
            <div class="wrapper-div">
              <p>Front Splitter</p>
              <p>{{ calReactive.Aero.FrSplitter }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Dive Plane 1</p>
              <p>{{ calReactive.Aero.FrDivePlane1 }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Dive Plane 2</p>
              <p>{{ calReactive.Aero.FrDivePlane2 }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Third Element</p>
              <p>{{ calReactive.Aero.Fr3rdElement }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rear Wing</p>
              <p>{{ calReactive.Aero.RrWing }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rear Diffuser</p>
              <p>{{ calReactive.Aero.RrDiffuser }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rear Gurney</p>
              <p>{{ calReactive.Aero.RrGurney }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rear Third Element</p>
              <p>{{ calReactive.Aero.Rr3rdElement }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Wing Shim</p>
              <p>{{ calReactive.Aero.WingShim }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rake</p>
              <p>{{ calReactive.Aero.Rake }}</p>
            </div>
          </li>
        </ul>
      </ion-col>
    </ion-row>
    <ion-row class="ST1Row">
      <ion-col size="12" class="corner-title-col">
        <div class="content-wrapper">
          <p class="title-header title ion-text-center">
            <strong>Header</strong>
          </p>
        </div>
      </ion-col>
      <ion-col>
        <ul class="input-list global-list">
          <li>
            <div class="wrapper-div">
              <p>Differential Deceleration</p>
              <p>{{ calReactive.Header.DiffDeccel }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Differential Acceleration</p>
              <p>{{ calReactive.Header.DiffAccel }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Preload lb/ft</p>
              <p>{{ calReactive.Header.Preload }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Bias %</p>
              <p>{{ calReactive.Header.Bias }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Tire Brand/Size</p>
              <p>{{ calReactive.Header.TireFrontBRSZ }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Rear Tire Brand/Size</p>
              <p>{{ calReactive.Header.TireRearBRSZ }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Pad Front</p>
              <p>{{ calReactive.Header.BrkPadFront }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Pad Rear</p>
              <p>{{ calReactive.Header.BrkPadRear }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Disc Front</p>
              <p>{{ calReactive.Header.BrkDiscFront }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Disc Rear</p>
              <p>{{ calReactive.Header.BrkDiscRear }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Mast Cyl Dia</p>
              <p>{{ calReactive.Header.FrtMastCylDia }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Front Mast Cyl Dia</p>
              <p>{{ calReactive.Header.RrMastCylDia }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Cooling Front</p>
              <p>{{ calReactive.Header.BrkCoolFront }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Brake Cooling Rear</p>
              <p>{{ calReactive.Header.BrkCoolRear }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Anti Lock Braking</p>
              <p>{{ calReactive.Header.abs }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Elec Stability Control</p>
              <p>{{ calReactive.Header.esc }}</p>
            </div>
          </li>
          <li>
            <div class="wrapper-div">
              <p>Traction Control</p>
              <p>{{ calReactive.Header.tc }}</p>
            </div>
          </li>
        </ul>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="ModalCont" v-else>
    <ion-row class="title-row">
      <!-- <div class="red-circle-wrapper">
        <img class="as-red-circle" :src="image" alt="" />
      </div> -->
      <ion-col class="title-col">
        <h2><ion-skeleton-text :animated="true"></ion-skeleton-text></h2>
        <!-- <p>{{ car }}</p> -->
        <p class="car-title"><ion-skeleton-text :animated="true"></ion-skeleton-text></p>
      </ion-col>
    </ion-row>
    <ion-row class="ST2Row corner-section">
      <ion-col size="12" class="corner-title-col">
        <ion-skeleton-text :animated="true" style="height: 20vh;"></ion-skeleton-text>
      </ion-col>
      <ion-col size="12" class="corner-title-col">
        <div class="content-wrapper">
          <p class="title ion-text-center">
            <strong><ion-skeleton-text :animated="true"></ion-skeleton-text></strong>
          </p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
    
</template>
<script setup>
import {
  IonGrid,
  IonRow,
  IonCol,
  onIonViewWillEnter,
  IonSkeletonText,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
} from "@ionic/vue";

import { useCalibration } from "@jackallee/usetrackside/reactive";
import TireData from "../modal-components/TireData.vue";
import { useFindCar } from "../../modules/FindCar.js";
import { ref, toRef, onBeforeMount } from "vue";
import { closeOutline } from "ionicons/icons";

const { carWanted } = useFindCar();
const carName = ref("");
// carName.value = carWanted(calReactive.CarId, calReactive.AuthorId);

const props = defineProps({
  fetchCal: {
    type: Boolean,
    required: true,
  },
  calibrationId: {
    type: String,
    required: false,
  },
  calibration: {
    type: Object,
    required: false,
  },
});

const cancelHandler = () => {
  return modalController.dismiss(null, "cancel");
};

const { calReactive, loadCal } = useCalibration(props.calibrationId);

//
const loadingValue = ref(true);

onBeforeMount(() => {
  if (props.fetchCal) {
    //
    const { isReady, isLoading, error } = loadCal();
    loadCal({
      onComplete: () => {
        loadingValue.value = false;
      }
    })
    //
  } else {
    if (!props.calibration) {
      throw new Error("Invalid Calibration In Calibration Overview");
    }
    calReactive.value = props.calibration;
    loadingValue.value = false;
    //
  }
});
</script>

<style scoped lang="scss">
/* global */

.as-fab {
  z-index: 10000;
& .as-fab-button {

  &::part(native) {
    background-color: rgba(255, 40, 0, 1);
  }
}
}
.as-top-border {
  border-top: solid 8px var(--ion-color-black);
}

ion-grid {
  background-color: transparent;
  margin: 0;
}

ion-row {
  background-color: var(--as-ion-grid-background);
  margin-bottom: 15px;
}

.title-row ion-col {
  text-align: center;
}

.title-row ion-col h2 {
  font-weight: 700;
}

.title-row ion-col p {
  margin-top: 0;
}

.corner-section {
  padding-bottom: 15px;
}

.corner-section ion-col {
  text-align: center;
}

.corner-section .corner-title-col p {
  margin-top: 16px;
  margin-bottom: 16px;
}
.corner-section ion-col p {
  margin-top: 0;
  margin-bottom: 0;
}

.gray-corner {
  background-color: #888585;

  & p {
    color: var(--always-white);
  }
}

.cross {
  background-color: #5c5c5c;

  & p {
    color: var(--always-white);
  }
}

.total {
  background-color: black;

  & p {
    color: var(--always-white);
  }
}

.content-wrapper .title {
  text-align: center;
  color: var(--ion-color-secondary);
}

.input-list {
  margin-left: 0;
  list-style-type: none;
  padding: 5px;
}

.input-list.list-aero {
  background-color: var(--always-black);
  margin-top: 0;
}

.input-list.list-tire {
  //this is in TireData.vue
}

.input-list.list-aero p {
  color: var(--always-white);
}

.input-list.list-header {
  background-color: black;

  & p {
    color: var(--always-white);
  }
}

.input-list li {
  font-size: 15px;
}

.input-list li p {
  margin-top: 0;
  margin-bottom: 0;
}

.input-list li .wrapper-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-list li::part(native) {
  padding-left: 0;
}

.title-header {
  margin-bottom: 0;
}

.global-list {
  background-color: #888585;
  margin-top: 0;

  & p {
    color: var(--always-white);
  }
}

.as-margin-top {
  margin-top: 5px;
}
</style>
