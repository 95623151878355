<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template v-if="shouldRender">
  <ion-page>
    <div class="ion-page">
      <HeaderComp title="Garage" />

      <ion-content class="ion-align-items-center">
        <!-- <ion-grid class="ST1Cont" v-if="!isLoading">
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid> -->
        <ion-grid class="ST2Cont" v-if="isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div
                class="title-wrapper-main"
                style="
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
              >
                <p class="cali-section-title">
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </p>
                <ion-button>
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </ion-button>
              </div>
              <!--title-wrapper-->
              <ion-item>
                <ion-label>
                  <p class="title">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </p>
                  <p style="line-height: 1.3em">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text
                    ><br />
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ST2Cont" v-if="!isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div
                class="title-wrapper-main"
                style="
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
              >
                <p class="cali-section-title"><strong>Cars </strong></p>
                <ion-button
                  size="default"
                  @click="
                    $router.push({
                      name: 'AddNewCar',
                    })
                  "
                  class="add-button"
                  fill="solid"
                  color="secondary"
                >
                  <ion-icon slot="end" :icon="addCircle"></ion-icon>
                  Add Car
                </ion-button>
              </div>
              <!--title-wrapper-->

              <!-- <ion-item class="item-previous-search" v-if="garage.length > 0">
                <ion-searchbar
                  :debounce="1000"
                  @ionChange="handleChange($event)"
                  placeholder="Find Car"
                  class="track-search"
                ></ion-searchbar>
              </ion-item> -->

              <div class="search-content-wrapper" v-if="garage.length > 0">
                <div class="global-searchbar-wrapper">
                    <ion-searchbar v-model="search"></ion-searchbar>
                  </div><!--searchbar-wrapper-->
                <ion-list class="garage-page list-previous search-list">
                  <ion-item
                    v-for="car in filteredData"
                    v-bind:key="car._id"
                    lines="none"
                  >
                    <ListTemplates
                      :viewButton="viewButton"
                      :trashButton="trashButton"
                      :editButton="editButton"
                      :copyButton="copyButton"
                      @view-clicked="handleViewClicked(car)"
                      @edit-clicked="handleEditClicked(car)"
                      @delete-clicked="handleDeleteClicked(car)"
                      @copy-clicked="handleCopyClicked(car)"
                    >
                      <template #text-wrapper>
                        <div class="text-wrapper garage-landing">
                          <p class="title">{{ car.make }}</p>
                          <p style="line-height: 1.3em">
                            {{ car.model }} ({{ car.year }})<br />
                            {{ car.number }}
                          </p>
                        </div>
                        <!--text-wrapper-->
                      </template>
                    </ListTemplates>
                  </ion-item>
                </ion-list>
              </div>
              <!--search-content-wrapper-->
              <div class="empty-content-wrapper" v-else>
                <ion-card>
                  <ion-card-content class="cal-card-content">
                    <p class="cal-sess-title">
                      <strong>No cars found.</strong>
                    </p>
                  </ion-card-content>
                </ion-card>
              </div>
              <!--empty-content-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonSearchbar,
  IonLabel,
  onIonViewDidEnter,
  onIonViewDidLeave,
  IonCard,
  IonCardContent,
  IonSkeletonText,
  toastController,
} from "@ionic/vue";

import { computed, ref } from "vue";
import {
  addCircle,
  pencilOutline,
  chevronForwardOutline,
  chatbubbleOutline,
} from "ionicons/icons";
import HeaderComp from "../../../components/header-components/HeaderComp.vue";
import { useGarage, getClient } from "../../../modules/useTrackside.js";
import { onIonViewWillEnter } from "@ionic/vue";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import ListTemplates from "../../../components/list-templates/ListTemplates.vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useUser } from "@/modules/useTrackside";
import { alertController } from "@ionic/core";
import { useToast, useErrorToast } from "@/modules/useToasts.js";
import useSearchFilter from '@/modules/useSearchFilter.js';


const { getAuthHeader } = useUser();

const router = useRouter();
// const { showLoading, stop } = useLoadingSpinner();
const { garage, getCars, addCar } = useGarage();
// const garage = ref([]);
const carResults = ref([]);
// const trigger = ref(true);
//
const { isLoading } = getCars();
//useLoadingSpinner(isLoading);

const sortedCars = computed(() => {
  const sortedArray = [...garage.value]; // Create a new array to avoid modifying the original
  return sortedArray.sort((a, b) => a.make.localeCompare(b.make));
});



const { search, filteredData } = useSearchFilter(sortedCars, ['make', 'model', 'year', 'number']);

defineExpose({ search, filteredData });

const editButton = {
  active: true,
};

const viewButton = ref({
  active: true,
});

const copyButton = ref({
  active: true,
});

const trashButton = ref({
  active: true,
});

const handleViewClicked = (car) => {
  router.push({
    name: "CarLandingPage",
    params: { carId: car._id },
  });
};

const handleEditClicked = (car) => {
  router.push({
    name: "AddNewCar",
    query: { id: car._id },
  });
};

// const shouldRender = ref(true);
// const reloadComponent = () => {
//   shouldRender.value = false;
//   setTimeout(() => {
//     shouldRender.value = true;
//   }, 1);
// }

const route = useRoute();

const handleCopyClicked = async (car) => {
  const submittedData = {
    uid: car.uid,
    make: `${car.make} (copy)`,
    model: car.model,
    year: car.year,
    class: car.class,
    number: car.number,
    note: car.note,
  };
  isLoading.value = true;
  addCar(submittedData, {
    onComplete: () => {
      isLoading.value = false;
      getCars();
    },
    onSuccess: async () => {
      
      const toast = await toastController.create({
        message: "Car Successfully Copied!",
        duration: 1500,
        position: "bottom",
      });
      toast.present();
    },
    onError: (e) => {
      console.error(e);
      useErrorToast(e);
    },
  });
};

async function handleDeleteClicked(car) {
  const alert = await alertController.create({
    header: "Warning",
    message: `Pressing OK will delete this car and all linked calibrations and sessions. IMPORTANT you will NOT be able to retrieve the data.`,
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          deleteCar(car);
        },
      },
    ],
  });

  await alert.present();
}

function deleteCar(car) {
  //confirmation here
  if (!car._id) {
    return false;
  }
  axios
    .delete(`${import.meta.env.VITE_API_URL}/garage/car/delete/${car._id}`, {
      headers: {
        Authorization: getAuthHeader(),
      },
    })
    .then(() => {
      
      getCars();
    })
}

onIonViewWillEnter(function () {
  //const { isLoading } = getCars();
  getCars();
  //useLoadingSpinner(isLoading);
  // garage.value = response.data.garage;
  // carResults.value = garage.value;
  // 
});

//this is linked to @ionChange on the <ion-searchbar></ion-searchbar>
const handleChange = (event) => {
  const query = event.target.value.toLowerCase();
  
  carResults.value = garage.value.filter(
    (d) =>
      d.make.toLowerCase().indexOf(query) > -1 ||
      d.model.toLowerCase().indexOf(query) > -1 ||
      d.year.toLowerCase().indexOf(query) > -1
  );
};

onIonViewDidEnter(() => {});

onIonViewDidLeave(() => {
  // 
  // menuController.close();
});
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/garage/ASCalibrationLandingPage";
</style>
