<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-menu :content-id="asProps.contentId" side="start" >
    <ion-header>
      <ion-toolbar>
        <ion-title>The Paddock</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding as-content">
      <ion-list>
        <ion-item
          :class="{ 'tab-selected': $route.path.includes('profile') }"
          button
          @click="getWorkingMenu('Profile')"
          
        >
          <ion-label>Profile</ion-label>
        </ion-item>
        <ion-item :disabled="true" button @click="getWorkingMenu('EducationPage')" >
          <ion-label>Education (Coming Soon)</ion-label>
        </ion-item>
        <ion-item button @click="getWorkingMenu('ContactSupportPage')" >
          <ion-label>Contact Support</ion-label>
        </ion-item>
        <ion-item button @click="getWorkingMenu('RequestFeaturePage')" >
          <ion-label>Request Feature</ion-label>
        </ion-item>
        <ion-item button @click="getWorkingMenu('InAppTsandCs')" >
          <ion-label>Terms &amp; Conditions</ion-label>
        </ion-item>
        <ion-item button @click="getWorkingMenu('SubscriptionInformationPage')" >
          <ion-label>Licenses</ion-label>
        </ion-item>
      </ion-list>
      <div class="logout-wrapper">
        <div class="logout-icon-wrapper">
          <ion-button fill="clear" slot="icon-only" @click="closeStuff()">
            <ion-icon :icon="powerOutline"></ion-icon>
          </ion-button>
          <p>Logout</p>
        </div>
      </div>
     
    </ion-content>
    <div class="footer">
     
      <div
      class="content-wrapper"
        style="display: flex; justify-content: center"
      >
        <p>&copy;2023 | 057 Motorsports</p>
      </div>
    </div>
  </ion-menu>
</template>

<script setup>
import {
  IonToolbar,
  IonTitle,
  IonMenu,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  menuController,
  IonToggle,
onIonViewWillEnter,
IonIcon,
IonButton,
} from "@ionic/vue";

import { useRoute, useRouter } from "vue-router";
import { useUser } from "@jackallee/usetrackside/reactive";
import { defineProps, ref, watch, onMounted } from "vue";
import { powerOutline } from "ionicons/icons";

const { logOut } = useUser();

const closeStuff = async () => {
  await menuController.close();
  logOut();
}

const asProps = defineProps({
  contentId: {
    type: String,
    required: true,
  },
});

const tickThat = ref(false);

const canISee = ref(true);

const route = useRoute();
const router = useRouter();

const getWorkingMenu = async (namedRoute) => {
  await menuController.close();
  await router.push({ name: namedRoute });
};

const changeToCalibration = async (event) => {
  await menuController.close();
  
  if(event.detail.checked === false) {
    await router.push({ name: 'HomePage' });
    canISee.value = true;
  } else {
    await router.push({ name: 'CTHomePage' });
    canISee.value = false;
  }
 
}

onMounted(() => {
  const thisRoute = route.path;
  if(thisRoute.includes('ct-')) {
    canISee.value = false;
    tickThat.value = true;
  }
})

// watch(
//   () => route.path,
//   (value) => {
//     
//     if (value.includes("ct-")) {
//       canISee.value = false;
//     }
//   }
// );

</script>

<style scoped lang="scss">

.cali-admin-menu ion-item {
  color: white;
}

@import '@/styles/scss-global-features/slideout-menu';

</style>