<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-grid class="TPE1Cont session-setup-wrapper">
    <form>
      <ion-row class="title-row as-section-top">
        <ion-col class="title-col">
          <h2>Session Setup</h2>
        </ion-col> </ion-row><!--as-section-top-->
      <ion-row class="TPE1Row top-bottom-row">
        <ion-col class="TPE1Col1">
          <ion-list>
            <ion-item lines="none" class="date-title-custom session-title-setup-wrapper">
              <ion-label class="date-title-small"><strong>Session Name *</strong></ion-label>
            </ion-item>
            <ion-item lines="none" class="form-control">
              <ion-input aria-labelledby="Session Name *" placeholder="Enter session name*" type="text"
                v-model="TurnData.Setup.title" id="ion-title" required="true"
                error-text="This field must not be blank"></ion-input>
            </ion-item>

            <ion-item lines="none" class="date-title-custom">
              <ion-label class="date-title-small"><strong>Date / Time *</strong></ion-label>
            </ion-item>
            <ion-item lines="none" id="date" class="form-control date-time-item">
              <ion-datetime-button datetime="datetime"></ion-datetime-button>
            </ion-item>
            <ion-popover :keep-contents-mounted="true" class="date-time-popover">
              <ion-datetime color="secondary" id="datetime" v-model="TurnData.Setup.date"></ion-datetime>
            </ion-popover>
            <!-- <ion-item class="form-control">
              <ion-input
                placeholder="Driver"
                type="text"
                v-model="TurnData.Setup.driver"
              ></ion-input>
              <ion-note slot="error">This field must not be blank</ion-note>
            </ion-item> -->

            <div class="add-car-wrapper" v-if="!isSetup" style="width: 100%; margin-top: 7.5px;">
              <ion-item lines="none" class="date-title-custom">
                <ion-label class="date-title-small"><strong>Select Car *</strong></ion-label>
              </ion-item>
              <ion-item class="form-control car-select" lines="none">
                <ion-label style="display: flex">Car<ion-note class="required-text" style="font-size: 16px">
                    {{
                      TurnData.Setup.car
                      ? "&nbsp;&nbsp;&#10003;"
                      : "&nbsp;&nbsp;*"
                    }}</ion-note></ion-label>
                <ion-select aria-label="Select Car*" v-model="TurnData.Setup.car" class="as-car-select">
                  <ion-select-option v-for="car in garage" :key="car._id" :value="car._id">
                    {{ car.make }} {{ car.model }} ({{ car.number }})
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </div>
            <!--add-car-wrapper-->
            <div class="car-edit-wrapper" v-else>
              <ion-item lines="none" class="date-title-custom track-notes-label-wrapper">
                <ion-label class="date-title-small"><strong>Chosen Car</strong></ion-label>
              </ion-item>
              <ion-select aria-label="Chosen Car" disabled v-model="TurnData.Setup.car" class="as-car-select"
                style="margin-bottom: 15px; margin-left: 15px;">
                <ion-select-option v-for="car in garage" :key="car._id" :value="car._id">
                  {{ car.make }} {{ car.model }} ({{ car.number }})
                </ion-select-option>
              </ion-select>
            </div>
            <!--car-edit-wrapper-->
          </ion-list>
        </ion-col></ion-row>
      <ion-row id="trackTopParent" class="TPE2Row">
        <ion-col class="TPE2Col1" id="trackTop">
          <ion-list>
          <div class="add-track-wrapper" v-if="!isSetup">
            <ion-item lines="none" class="date-title-custom item-prev-search-label">
              <ion-label class="date-title-small" style="display: flex"><strong>Track</strong>
                <ion-note class="required-text" style="font-size: 16px">
                  {{
                    trackUrlSearched ? "&nbsp;&nbsp;&#10003;" : "&nbsp;&nbsp;*"
                  }}</ion-note></ion-label>
              <div class="previous-arrow" v-show="!isTrackVisible" style="text-align: right; font-size: 20px;">
                <ion-button style="font-size: 16px;" fill="clear" color="secondary"
                  @click="displayTrackSearch">Back<ion-icon slot="start" :icon="arrowUndoOutline"></ion-icon></ion-button>
              </div>
            </ion-item>

            <!-- <ion-item lines="none" class="item-previous-search">
            
            

            <ion-searchbar
              :debounce="1000"
              @ionChange="handleChange($event)"
              :placeholder="searchPlaceholderText"
              @ionFocus="displayList"
              @ionInput="displayList"
              :disabled="isSetup"
              class="track-search"
            ></ion-searchbar>
          </ion-item> -->
            <!-- <div class="global-searchbar-wrapper">
            <ion-searchbar v-model="search"></ion-searchbar>
        </div>
            <ion-list>
              <ion-item
              class="track-list-item"
                v-for="(trackResult) in filteredData"
                :key="trackResult.id"
                :disabled="isSetup"
                @click="displayMap(trackResult.mapUrl, trackResult.id)"
                :class="{ 'as-highlight': addHighlight(trackResult.id) }"
              >
              <ion-label>{{ trackResult.trackName }}</ion-label>
              </ion-item>
            </ion-list> -->

            <div class="global-searchbar-wrapper" v-if="isTrackVisible">
              <ion-searchbar v-model="search"></ion-searchbar>
            </div>
            <!-- <div class="previous-arrow" v-if="!isTrackVisible" style="text-align: right;">
              <ion-button
                fill="clear"
                color="secondary"
                @click="displayTrackSearch"
                >Back<ion-icon
                  slot="start"
                  :icon="arrowUndoOutline"
                ></ion-icon
              ></ion-button>
            </div> -->
            <ion-list class="global-track-list" v-if="isTrackVisible">
              <ion-item-group v-for="(group, letter) in groupedTracks" :key="letter">
                <ion-item-divider sticky="true">
                  <ion-label>{{ letter }}</ion-label>
                </ion-item-divider>

                <ion-item class="track-list-item" v-for="track in group" :key="track._id" :disabled="isSetup"
                  @click="displayMap(track.path, track._id)" :class="{ 'as-highlight': addHighlight(track._id) }">
                  <ion-label>{{ track.name }}</ion-label>
                </ion-item>
              </ion-item-group>
            </ion-list>
            <ion-item lines="none" v-if="trackUrlSearched" class="turn-data">

              <!-- <h2 class="track-map-title">
                  <strong>{{ trackUrlSearched.name }}</strong>
                </h2> -->
              <ion-img id="corner-img" class="track-map-image"
                :src="`https://trackside-public-assets.s3.amazonaws.com/track-maps${trackUrlSearched.path}`"></ion-img>
            </ion-item>
          </div>
          <!--add-track-wrapper-->
          <div class="edit-track-wrapper" v-else>
            <ion-item lines="none" class="date-title-custom track-notes-label-wrapper">
              <ion-label class="date-title-small"><strong>Chosen Track</strong></ion-label>
            </ion-item>
            <ion-item disabled >
              <p>{{ TrackData.name }}</p>
              
            </ion-item>
            <ion-item disabled>
              <ion-img 
                :src="`https://trackside-public-assets.s3.amazonaws.com/track-maps${TrackData.path}`"></ion-img>
            </ion-item>
          </div>
          <!--edit-track-wrapper-->

          <ion-item lines="none" class="date-title-custom track-notes-label-wrapper">
            <ion-label class="date-title-small"><strong>Track Notes</strong></ion-label>
          </ion-item>
          <ion-item class="form-control track-notes">
            <ion-textarea aria-label="Track Notes" :auto-grow="true" v-model="TurnData.Setup.trackNotes">
            </ion-textarea>
          </ion-item>

          <ion-item lines="none" class="date-title-custom temp-range-slider">
            <ion-label class="date-title-small"><strong>Temperature</strong></ion-label>
          </ion-item>

          <ion-item lines="none" class="temp-range-slider">
            <ion-range aria-label="Temperature" @ionChange="onIonRangeChange" :pin="true" :value="lastEmittedValue"
              :max="tempSliderRange.max" :min="tempSliderRange.min">
              <ion-icon slot="start" :icon="snowOutline"></ion-icon>
              <ion-icon slot="end" :icon="sunnyOutline"></ion-icon>
            </ion-range>
          </ion-item>
          <ion-item class="form-control temp-print">
            <ion-label>
              <p class="ion-text-center">
                Selected Temp:
                {{ lastEmittedValue ? lastEmittedValue : "0" }}
                {{ tempUnits === "celsius" ? "&#8451;" : "&#8457;" }};
              </p>
            </ion-label>
          </ion-item>
          <ion-item class="form-control temp-units">
            <ion-radio-group v-model="tempUnits" class="as-radio-group" value="farenheit" @ionChange="tempRadioSelect"
              errorText="This field must not be blank" aria-label="Degrees or Farenheit">
              <ion-item lines="none">
                <ion-label>Fahrenheit</ion-label>
                <ion-radio aria-label="Farenheit" slot="start" value="farenheit"></ion-radio>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Celsius</ion-label>
                <ion-radio aria-label="Celsius" slot="start" value="celsius"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-item>
          <ion-item lines="none" class="date-title-custom weather-title">
            <ion-label class="date-title-small">
              <strong>Weather</strong></ion-label>
          </ion-item>
          <ion-item class="form-control">
            <ion-select placeholder="Choose" :multiple="true" v-model="TurnData.Setup.weather"
              errorText="This field must not be blank" aria-label="Weather">
              <ion-select-option value="rain">Rain</ion-select-option>
              <ion-select-option value="snow">Snow</ion-select-option>
              <ion-select-option value="frost">Frost</ion-select-option>
              <ion-select-option value="sunshine">Sunshine</ion-select-option>

              <ion-select-option value="fog">Fog</ion-select-option>
              <ion-select-option value="wind">Wind</ion-select-option>
              <ion-select-option value="cloudy">Cloudy</ion-select-option>
              <ion-select-option value="Humid">Humid</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none">
            <ion-label class="ion-text-center">
              <!-- <ion-button
                class="submit-button"
                expand="block"
                size="default"
                @click="submitForm"
                >Create Session</ion-button
              > -->
            </ion-label>
          </ion-item>
        </ion-list>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonSearchbar,
  IonLabel,
  IonListHeader,
  IonInput,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonImg,
  IonRange,
  IonIcon,
  IonRadioGroup,
  IonRadio,
  IonButton,
  IonTextarea,
  IonNote,
  IonDatetimeButton,
  IonPopover,
  onIonViewWillEnter,
} from "@ionic/vue";

import { ref, computed, toRef, watchEffect, nextTick, watch } from "vue";
import { useRoute } from "vue-router";

import HeaderCompSession from "../header-components/HeaderCompSession.vue";
import { snowOutline, sunnyOutline, arrowUndoOutline } from "ionicons/icons";
import { useTurnData, useGarage, useTracks } from "@jackallee/usetrackside/reactive";
import useTrackListing from "@/modules/trackList.js";
import useSearchFilter from '@/modules/useSearchFilter.js';
//Need to have list of cars in array to choose from.
//Also need to have a list of weather
const route = useRoute();

// const { TurnData, createTurnData } = useTurnData();

const { trackData } = useTrackListing();

const props = defineProps({
  TurnData: Object,
  TrackData: Object,
  isSetup: Boolean,
});

const TurnData = toRef(props, "TurnData");
const { getCars, garage } = useGarage();
const { tracks, getAllTracks } = useTracks();

TurnData.value.Setup.car = ref(route.query.carId || '');

const lastEmittedValue = ref(TurnData.value.Setup.temperature.farenheit || 0);

getCars();

//Tracks Vars
const trackResults = ref(tracks);
const trackUrlSearched = ref("");
// const alphaTrackResults = ref(tracks);

// alphaTrackResults.value = [...tracks.value].sort((a, b) => a.name.localeCompare(b.name));


// const { search, filteredData } = useSearchFilter(alphaTrackResults, ['name']);

// defineExpose({ search, filteredData });


const searchPlaceholderText = ref("Find and Select Track");
const tempUnits = ref("farenheit");
const tempRange = {
  farenheit: { min: -10, max: 140 },
  celsius: { min: -15, max: 60 },
};
const tempSliderRange = computed(() => {
  return tempRange[tempUnits.value];
});
//
// onBeforeMount(async () => {
//   const res = await getCars();
//   if (res.data) {
//     garage.value = res.data.garage;
//   } else {
//     garage.value = [];
//   }
// });

console.log("trawehalksdjalksjdalksjd");
getAllTracks({
  onSuccess: (res) => {
    console.log(res);
  },
  onError: (err) => {
    console.log(err);
  },
});

onIonViewWillEnter(async function () {

  trackUrlSearched.value = "";
  const { response } = getCars();
  // if (res.data) {
  //   garage.value = res.data.garage;
  // } else {
  //   garage.value = [];
  // }
  TurnData.value.Setup.date = new Date().toISOString();
  lastEmittedValue.value = TurnData.value.Setup.temperature.farenheit;
});

/* Track Search and List */

//this is linked to @ionChange on the <ion-searchbar></ion-searchbar>
const handleChange = (event) => {
  const query = event.target.value.toLowerCase();

  trackResults.value = trackData.filter(
    (d) => d.trackName.toLowerCase().indexOf(query) > -1
  );
};

//This shows the list of maps on @ionFocus and @ionInput

const displayList = () => {
  const listItem = document.querySelector(".search-list ");
  listItem.classList.remove("list-init", "list-hide");

  //listItem.classList.remove("list-hide");
};

//This needs to be sorted as it is not working properly

// const hideList = () => {
//   const listItem = document.querySelector(".search-list ");
//   listItem.classList.add("list-hide");
// };



const highlightedTrackId = ref(null);


const selectTrack = (mapUrl, trackId) => {
  trackUrlSearched.value = tracks.value.find(
    (map) => map.url === mapUrl
  );
  highlightedTrackId.value = trackId;
  // TurnData.value.Setup.track = trackUrlSearched.value;
  TurnData.value.Setup.trackId = trackUrlSearched.value.id;

};

// const addHighlight = (trackId) => {
//   if (filteredData.value.length === 0) {
//     return false;
//   }
//   return trackId === highlightedTrackId.value;
// }



const isTrackVisible = ref(true);


const scrollToTop = () => {
  const element = document.getElementById("trackTop");
  //console.log(element, element.getBoundingClientRect());

  if (element) {
    //console.log(element.getBoundingClientRect());
    element.scrollIntoView({ behavior: "smooth" });
    //console.log('event-fired')
  }
};

// const scrollToTop = () => {
//   const container = document.getElementById('trackTopParent');
//   //console.log(container.getBoundingClientRect())
//   const element = document.getElementById('trackTop');
//   //console.log(element.getBoundingClientRect())
//   if (container && element) {
//     const topPos = element.getBoundingClientRect().top - container.getBoundingClientRect().top;
//     container.scrollTop = topPos;
//   }
// };


const displayMap = (mapUrl, trackId) => {
  trackUrlSearched.value = trackResults.value.find(
    (map) => map.path === mapUrl
  );

  isTrackVisible.value = true;
  TurnData.value.Setup.trackId = trackUrlSearched.value.id;
  nextTick(() => {
    scrollToTop();
    isTrackVisible.value = false;
  });
};

const displayTrackSearch = () => {
  isTrackVisible.value = true;
  search.value = '';
  trackUrlSearched.value = false;
};

const addHighlight = (trackId) => {
  if (filteredData.value.length === 0) {
    return false;
  }
  return trackId === highlightedTrackId.value;
};

// const alphaTrackResults = computed()

// watch(() => trackResults.value, () => {
//   console.log(trackResults.value);
//   alphaTrackResults.value = [...trackResults.value].sort((a, b) => a.name.localeCompare(b.name));
//   console.log(alphaTrackResults.value)
// })



const { search, filteredData } = useSearchFilter(trackResults, [
  "name",
]);

const groupedTracks = ref({});

watch(() => filteredData.value, () => {
  // Changed to watchEffect for immediate execution
  groupedTracks.value = {};

  console.log(filteredData.value)
  filteredData.value.forEach((track) => {
    const firstLetter = track.name.charAt(0).toUpperCase();
    if (!groupedTracks.value[firstLetter]) {
      groupedTracks.value[firstLetter] = [];
    }
    groupedTracks.value[firstLetter].push(track);
  });
});

defineExpose({ search, filteredData });



/* Temperature Range */

//maybe change rangeNumber to an array

//this is linked to the @ionChange on <ion-range></ion-range>
const onIonRangeChange = ({ detail }) => {
  //

  var tempObj = {
    celcius: 0,
  };

  if (tempUnits.value === "farenheit") {
    tempObj.farenheit = detail.value;
    tempObj.celcius = Math.round((detail.value - 32) / 1.8);
  } else {
    tempObj.celcius = detail.value;
    tempObj.farenheit = Math.round(detail.value * 1.8 + 32);
  }
  //Set Temp


  TurnData.value.Setup.temperature = tempObj;

  lastEmittedValue.value = detail.value;
  return lastEmittedValue;
};

//formula for converting temp from farenheit to celcius and vice versa

const tempRadioSelect = (event) => {
  if (event.detail.value === "farenheit") {

    const farenheitConvert = Math.round(lastEmittedValue.value * 1.8 + 32);
    lastEmittedValue.value = farenheitConvert;
  } else {

    const celciusConvert = Math.round((lastEmittedValue.value - 32) / 1.8);
    lastEmittedValue.value = celciusConvert;
  }
};

// const changeToByComponentHandler = (event) => {
//   if (event.detail.value === "active-session") {
//     router.push({ name: "Session", params: { id: "e" } });
//   }
// };
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/sessions/SessionSetup";

// ion-grid {
//   background-color: transparent;
//   margin: 7.5px;
//   margin-bottom: 30px;
// }

// ion-row {
//   background-color: var(--as-ion-grid-background);
// }

// .required-text {
//   color: var(--ion-color-black);
// }

// .as-radio-group {
//   display: flex;
// }

// .as-radio-group ion-radio {
//   width: 24px;
//   height: 24px;
//   border: solid 1px black;
//   border-radius: 50%;
// }

// .as-radio-group ion-radio.radio-checked {
//   border: solid 1px red;
// }
// .as-radio-group ion-radio {
//   border: solid 1px var(--ion-color-black);
// }

// .as-radio-group ion-radio.radio-checked::part(container) {
//   --color-checked: red;
// }

// .submit-button {
//   margin-top: 30px;
//   margin-bottom: 30px;
// }

// .submit-button::part(native) {
//   background-color: var(--ion-color-secondary);
// }

// .list-hide,
// .list-init {
//   display: none;
// }

// ion-datetime {
//   --background: transparent;
//   --background-rgb: transparent;
//   margin: 0 auto;
// }

// ion-datetime .wheel-order-month-first {
//   --background: transparent;
//   --background-rgb: transparent;
// }

// ion-datetime .datetime-header {
//   padding-left: 0 !important;
// }

// .date-title-custom .date-title-small {
//   font-size: 14px;
// }

// .TPE1Cont {
//   padding-bottom: 0px;
// }

// .TPE2Cont {
//   padding-bottom: 30px;
// }

// .car-select {
//   padding-bottom: 30px;
// }

// .car-select ion-label {
//   color: rgb(133, 133, 133);
// }

// .TPE2Row {
//   margin-top: 30px;
//   padding-top: 30px;
// }

// .track-map-image {
//   background-color: white;
//   margin-top: 30px;
// }

// .track-map-title {
//   margin-top: 15px;
// }

// .as-car-select {
//   max-width: 80%;
// }

// .car-select ion-note,
// .item-searchbar ion-note {
//   /* color: var(--ion-color-success); */
//   font-weight: 600;
// }

// .item-searchbar ion-note {
//   margin-top: -3px;
// }
</style>
