import { ref, reactive } from "vue";
import {
  getClient,
  useUser,
  useSocket,
  onLogin,
  setupSocket,
  reloadSocket,
} from "@jackallee/usetrackside/reactive";
import { useLoadingSpinner } from "./LoadingSpinner";
import { useRouter } from "vue-router";

import { useToast } from "./useToasts";
import axios from "axios";
// const UserProfileReactive = reactive({});

const UserProfileReactive = reactive({
  appStatus: {
    willReconnect: true,
    isConnected: false,
    isSetup: false,
  },
  user: {
    isFetching: false,
    isLoaded: false,
    name: "",
    email: "",
    username: "",
  },
  garage: {
    cars: [],
  },
  calibrators: { isFetching: false, isLoaded: false, value: [] },
  sessions: {
    isFetching: false,
    value: [
      {
        title: "",
        id: "",
        car: "",
        date: "",
      },
    ],
  },
  calibrations: [],
  chat: {
    conversations: {
      isFetching: false,
      value: [
        {
          _id: "",
          lastMessage: {
            content: "",
            embeds: false,
            from: "",
            fromSelf: false,
            timestamp: "",
            to: "",
            _id: "",
          },
          userProfiles: [
            {
              name: "",
              email: "",
              username: "",
            },
            {
              name: "",
              email: "",
              username: "",
            },
          ],
        },
      ],
    },
  },
  notify: {
    alerts: [],
  },
});

const isFetching = ref(false);

// 

export async function fetchSessions() {
  try {
    UserProfileReactive.sessions.isFetching = true;
    const sessionsRes = await getClient().sessions.getRecentSessions();
    UserProfileReactive.sessions.value = sessionsRes.data.sessions;
  } catch (e) {
    console.error(e);
  } finally {
    UserProfileReactive.sessions.isFetching = false;
  }
  // 
  UserProfileReactive.sessions.isFetching = false;
}

export async function fetchConversations(socket) {
  const convosRes = await getClient().user.getConversations();
  const conversations =
    convosRes.data.convos.map((c) => {
      const otherName = c.userProfiles.filter(
        (u) => u.username !== UserProfileReactive.user.username
      );
      if (otherName.length > 0) {
        c.displayName = otherName[0].name;
      } else {
        c.displayName = "";
        if (c.userProfiles[0]?.username == UserProfileReactive.user.username) {
          c.displayName = "Me";
        }
      }

      return {
        ...c,
      };
    }) || [];

  //

  conversations.forEach((c) => {
    //
    if (
      typeof socket !== "undefined" &&
      !Object.hasOwn(UserProfileReactive.chat, c._id)
    ) {
      socket.emit("set-session", { id: c._id });
    }
  });
  UserProfileReactive.chat.conversations.value = conversations;
}

async function fetchUserProfile() {
  const { userState } = useUser();

  const profileRes = await getClient().user.getUserProfile();
  const userObj = profileRes.data.user;
  delete userObj._id;
  
  userState.isPaying = userObj.plan?.active;

  UserProfileReactive.user = userObj;
}

async function fetchCalibrators() {
  UserProfileReactive.calibrators.isFetching = true;
  const calibratorsRes = await getClient().user.getCalibrators();
  UserProfileReactive.calibrators.isFetching = false;
  UserProfileReactive.calibrators.isLoaded = true;
  UserProfileReactive.calibrators.value = calibratorsRes.data.calibrators;
}

function initChatListeners(socket) {
  // 

  if (socket && socket.listeners('chat-message').length === 0) {
    const { userState } = useUser();
    socket.on("sessions", (sessions) => {
      // 
      sessions.messages.forEach((msg) => {
        msg.fromSelf = userState.id === msg.from;
      });
      UserProfileReactive.chat[sessions._id] = sessions;
    });

    socket.on("chat-message", async ({ content, from, to, embeds }) => {
      const fromSelf = from === userState.id;

      UserProfileReactive.chat[to].messages.push({
        content,
        fromSelf,
        embeds,
        from,
      });
    });

    socket.on("disconnect", () => {
      
      UserProfileReactive.appStatus.isConnected = false;
    });
    socket.on("connect", () => {
      UserProfileReactive.appStatus.isSetup = true;
      UserProfileReactive.appStatus.isConnected = true;
    });
  }
}

export function useProfile() {
  function setChatSession(users) {
    const { socket } = useSocket();
    
    socket.emit("set-session", { users });
  }
  return { UserProfileReactive, setChatSession };
}
export function useAppStatus() {
  return UserProfileReactive.appStatus;
}

function setupProfile() {
  // fetchUserProfile();
  const { userState } = useUser();
  UserProfileReactive.user = {...userState.profile, id: userState.id};
  
  UserProfileReactive.onBoarding = localStorage.getItem("firstLoad") || false;
  const { socket } = useSocket();
  initChatListeners(socket);
  fetchSessions();
  fetchConversations(socket);
  fetchCalibrators();
  setTimeout(() => {
    UserProfileReactive.appStatus.isSetup = true; //Make sure isSetup is set to true even if there is no socket connection.
  }, 5000);
  return { UserProfileReactive };
}

onLogin(() => {
  const { userState, logOut } = useUser();

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        if (userState.loggedIn === true) {
          useToast({
            message: "Your session has expired. Please login again.",
          });
          logOut();
        }
      } else if (error.response.status === 402) {
        useToast({
          message:
            "Your subscription has expired. Please renew your subscription.",
          });
          userState.isPaying = false;
      }
      
      return Promise.reject(error);
    }
  );

  if (useSocket().socket) {
    reloadSocket(userState.token);
  } else {
    
    setupSocket();
  }
  setupProfile();
});
