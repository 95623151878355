<template>
  <ion-page>
    <div class="ion-page">
      <HeaderComp title="Dashboard" />

      <ion-content class="ion-align-items-center">
        <!-- <ion-grid class="ST1Cont" v-if="!isLoading">
          <ion-row class="ST1Row">
            <ion-col class="ST1Col1">
              <ion-item lines="none" class="item-stperf">
                <ion-label color="primary">
                  <div class="button-wrapper">
                    
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid> -->
        <ion-grid class="ST2Cont" v-if="isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div
                class="title-wrapper-main"
                style="
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
              >
                <p class="cali-section-title">
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </p>
                <ion-button>
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </ion-button>
              </div>
              <!--title-wrapper-->
              <ion-item>
                <ion-label>
                  <p class="title">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </p>
                  <p style="line-height: 1.3em">
                    <ion-skeleton-text :animated="true"></ion-skeleton-text
                    ><br />
                    <ion-skeleton-text :animated="true"></ion-skeleton-text>
                  </p>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="large-dashboard">
          <ion-grid
          class="ST2Cont"
          v-if="!isLoading"
          style="background-color: black"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="logo-wrapper" style="width: 50%; margin: 0 auto;">
                <img
                  class="as-logo"
                  src="/assets/images/as-images/Asset3.png"
                  alt="logo"
                />
              </div>
              <!--logo-wrapper-->
            </ion-col>
            
          </ion-row>
        </ion-grid>

        <ion-grid class="ST2Cont" v-if="!isLoading">
          <ion-row class="ST2Row" >
            <ion-col size-xl="4" size-sm="3" class="ST2Col1">
              <div class="help-section">
                <div class="left-section">
                  <div class="image-wrapper">
                    <img class="as-image" src="/assets/images/new-menu-icons/Chat_Track_selected.svg" alt="" />
                  </div><!--image-wrapper-->
                  <p>
                    <strong>Need some help?</strong>
                  </p>
                  <p>Just contact our support team.</p>
                 
                </div>
                <!--left-section-->
                <div class="right-section">
                  <ion-button
                    class="support-button"
                    size="default"
                    color="secondary"
                    fill="solid"
                    href="mailto:team@057motorsports.com"
                  >
                    Get Support
                  </ion-button>
                </div>
                <!--right-section-->
              </div>
              <!--help-section-->
              <!--empty-content-wrapper-->
            </ion-col>
            <ion-col class="ST2Col1 shop-col" style="
            
          ">
              <div class="shop-wrapper">
                <div class="text-content">
                  <p><strong>Shop</strong></p>
                  <p>Enhance your Trackside experience!</p>
                  <p>
                    Add Calibration Analysis and Live, Real Time Calibration
                    Analysis options.
                  </p>
                </div><!--text-content-->
                
                <div class="button-wrapper">
                  <ion-button
                    class="shop-button"
                    size="default"
                    color="secondary"
                    fill="solid"
                    @click="
                      $router.push({
                        name: 'ShopLandingPage',
                      })
                    "
                  >
                    Shop Now
                  </ion-button>
                </div>
                <!--right-section-->
              </div>
              <!--shop-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ST2Cont" v-if="!isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="recent-car" v-if="mostRecentCars.length !== 0">
                <div class="title-wrapper">
                  <div class="text-content">
                    <p><strong>Recent cars</strong></p>
                    <p>View recent sessions and calibrations</p>
                  </div><!--text-content-->
                  <div class="button-wrapper">
                    <ion-button
                    class="add-car-button"
                    size="default"
                    color="secondary"
                    fill="solid"
                    @click="
                      $router.push({
                        name: 'AddNewCar',
                      })
                    "
                  >
                    Add Car
                  </ion-button>
                  </div>
                </div>
                <!--title-wrapper-->
                <div class="recent-car-wrapper">
                  <ion-list style="display: flex; gap: 15px;">
                    <ion-item
                    style="width: 33%;"
                      class="recent-car-item"
                      :key="car._id"
                      v-for="car in mostRecentCarsLarge"
                      lines="none"
                    >
                      <div class="inner-wrapper">
                        <div class="car-info-wrapper">
                          <p>{{ car.make }}</p>
                          <p>{{ car.model }} ({{ car.year }})</p>
                          <p>{{ car.number }}</p>
                        </div>
                        <!--car-info-wrapper-->
                        <div class="button-wrapper">
                          <ion-button
                            class="reports-button"
                            size="default"
                            fill="solid"
                            @click="
                              $router.push({
                                name: 'CarLandingPage',
                                params: { carId: car._id },
                              })
                            "
                          >
                            Reports
                          </ion-button>
                          <ion-button
                            class="edit-button"
                            size="default"
                            color="secondary"
                            fill="solid"
                            @click="
                              $router.push({
                                name: 'AddNewCar',
                                query: { id: car._id },
                              })
                            "
                          >
                            Edit
                          </ion-button>
                        </div>
                        <!--button-wrapper-->
                      </div>
                      <!--inner-wrapper-->
                    </ion-item>
                  </ion-list>
                </div>
                <!--recent-car-wrapper-->
              </div>
              <div class="empty-recent-car" v-else>
                <div class="title-wrapper">
                  <p><strong>Recent cars</strong></p>
                  <p>
                    No Cars found. Please enter your first car in the Garage.
                  </p>
                  
                </div><!--title-wrapper-->
                <div class="button-wrapper">
                    <ion-button
                      class="garage-button"
                      size="default"
                      color="secondary"
                      fill="solid"
                      @click="
                        $router.push({
                          name: 'ASCalibrationLandingPage',
                        })
                      "
                    >
                      Garage
                    </ion-button>
                  </div><!--button-wrapper-->
              </div>
              <!--empty-recent-car-->
              <!--recent-car-->
            </ion-col>
          </ion-row>
        </ion-grid>
        </div><!--large-dashboard-->

        <div class="mobile-dashboard">
          <ion-grid
          class="ST2Cont"
          v-if="!isLoading"
          style="background-color: black"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="logo-wrapper">
                <img
                  class="as-logo"
                  src="/assets/images/as-images/Asset3.png"
                  alt="logo"
                />
              </div>
              <!--logo-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ST2Cont" v-if="!isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="help-section">
                <div class="left-section">
                  <p>
                    <strong>Need some help?</strong>
                  </p>
                  <p>Just contact our support team.</p>
                </div>
                <!--left-section-->
                <div class="right-section">
                  <ion-button
                    class="support-button"
                    size="default"
                    color="secondary"
                    fill="solid"
                    href="mailto:team@alignstudios.co.uk"
                  >
                    Get Support
                  </ion-button>
                </div>
                <!--right-section-->
              </div>
              <!--help-section-->
              <!--empty-content-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid
          class="ST2Cont"
          v-if="!isLoading"
          style="
            background-image: url(/assets/images/new-home/shop-image.jpg);
            background-size: cover;
            background-position: left center;
          "
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="shop-wrapper">
                <p><strong>Shop</strong></p>
                <p>Enhance your Trackside experience!</p>
                <p>
                  Add Calibration Analysis and Live, Real Time Calibration
                  Analysis options.
                </p>
                <div class="button-wrapper">
                  <ion-button
                    class="shop-button"
                    size="default"
                    color="secondary"
                    fill="solid"
                    @click="
                      $router.push({
                        name: 'ShopLandingPage',
                      })
                    "
                  >
                    Shop Now
                  </ion-button>
                </div>
                <!--right-section-->
              </div>
              <!--shop-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ST2Cont" v-if="!isLoading">
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <div class="recent-car" v-if="mostRecentCars.length !== 0">
                <div class="title-wrapper">
                  <p><strong>Recent cars</strong></p>
                  <p>View recent sessions and calibrations</p>
                </div>
                <!--title-wrapper-->
                <div class="recent-car-wrapper">
                  <ion-list>
                    <ion-item
                      class="recent-car-item"
                      :key="car._id"
                      v-for="car in mostRecentCars"
                    >
                      <div class="inner-wrapper">
                        <div class="car-info-wrapper">
                          <p>{{ car.make }}</p>
                          <p>{{ car.model }} ({{ car.year }})</p>
                          <p>{{ car.number }}</p>
                        </div>
                        <!--car-info-wrapper-->
                        <div class="button-wrapper">
                          <ion-button
                            class="reports-button"
                            size="default"
                            fill="solid"
                            @click="
                              $router.push({
                                name: 'CarLandingPage',
                                params: { carId: car._id },
                              })
                            "
                          >
                            Reports
                          </ion-button>
                          <ion-button
                            class="edit-button"
                            size="default"
                            color="secondary"
                            fill="solid"
                            @click="
                              $router.push({
                                name: 'AddNewCar',
                                query: { id: car._id },
                              })
                            "
                          >
                            Edit
                          </ion-button>
                        </div>
                        <!--button-wrapper-->
                      </div>
                      <!--inner-wrapper-->
                    </ion-item>
                  </ion-list>
                </div>
                <!--recent-car-wrapper-->
              </div>
              <div class="empty-recent-car" v-else>
                <div class="title-wrapper">
                  <p><strong>Recent cars</strong></p>
                  <p>
                    No Cars found. Please enter your first car in the Garage.
                  </p>
                  
                </div><!--title-wrapper-->
                <div class="button-wrapper">
                    <ion-button
                      class="garage-button"
                      size="default"
                      color="secondary"
                      fill="solid"
                      @click="
                        $router.push({
                          name: 'ASCalibrationLandingPage',
                        })
                      "
                    >
                      Garage
                    </ion-button>
                  </div><!--button-wrapper-->
              </div>
              <!--empty-recent-car-->
              <!--recent-car-->
            </ion-col>
          </ion-row>
        </ion-grid>
        </div><!--mobile-dashboard-->
       
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonSkeletonText,
  onIonViewWillEnter,
  IonList,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { useGarage } from "../../../modules/useTrackside.js";
import { computed, ref, watch } from "vue";
const { garage, getCars } = useGarage();

const sortedArray = ref([]);

onIonViewWillEnter(function () {
  //const { isLoading } = getCars();
  getCars();
  //useLoadingSpinner(isLoading);
  // garage.value = response.data.garage;
  // carResults.value = garage.value;
  //
  console.log(garage);
});

const mostRecentCars = ref([]);
const mostRecentCarsLarge = ref([]);

const { isLoading } = getCars();

// const sortedRecentArray = garage.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
// console.log(sortedRecentArray);
// recentCars.value = sortedRecentArray.slice(0, 2);

const sortedCars = computed(() => {
  // Make sure garage.value is actually an array before spreading it
  if (Array.isArray(garage.value)) {
    const sortedArray = [...garage.value]; // Create a new array to avoid modifying the original
    return sortedArray.sort(
      (a, b) => new Date(b.lastModified) - new Date(a.lastModified)
    );
  }
  return []; // Return an empty array if garage.value is not an array
});

watch(sortedCars, (newVal) => {
  if (Array.isArray(newVal)) {
    mostRecentCars.value = newVal.slice(0, 2);
  }

  if (Array.isArray(newVal)) {
    mostRecentCarsLarge.value = newVal.slice(0, 3);
  }
});

console.log(sortedCars);
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/home/HomePageNew";
</style>
