<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <!-- <SlideoutMenu contentId="main-content" /> -->
    <div class="ion-page">
      <HeaderComp title="Conversations" />
      <ion-content>
        <ion-grid
          v-if="!UserProfileReactive.chat.conversations.isFetching"
          class="ST2Cont"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <p
                class="calibrator-title"
                style="
                  padding-bottom: 30px;
                  margin-bottom: 15px;
                  border-bottom: solid 1px #f0f0f0;
                "
              >
                <strong>My Conversations</strong>
              </p>
              <!-- <ion-item class="item-previous-search" lines="none">
                <ion-searchbar
                  :debounce="1000"
                  @ionChange="handleChange($event)"
                  placeholder="Find Conversation"
                  class="track-search"
                ></ion-searchbar>
              </ion-item> -->
              <ion-list class="list-previous search-list chat-landing-page">
                <ion-item
                  lines="none"
                  href="#"
                  v-for="chat in chatFormatted"
                  :key="chat._id"
                  button
                  @click="
                    $router.push({
                      name: 'ChatPage',
                      params: { chatId: chat._id },
                    })
                  "
                >
                  <ion-badge
                    class="badge-styles"
                    :class="chat.unreadMessages === 0 ? 'faded' : 'filled'"
                    slot="end"
                    >{{ chat.unreadMessages
                    }}<span :class="chat.unreadMessages === 0 ? 'hide' : 'show'"
                      >*</span
                    ></ion-badge
                  >
                  <ion-label>
                    <div class="title-wrapper">
                      <p>
                        <strong>{{ chat.formattedNames }}</strong>
                      </p>
                    </div>
                    <div class="car-wrapper" style="">
                      <p>{{ chat.formattedMessage }}</p>
                    </div>
                    <div class="chat-content-wrapper">
                      <p class="date-content">{{ chat.formattedDateTime }}</p>
                      <!-- <p class="date-content">
                        {{ chat.timestamp }}
                      </p> -->
                    </div>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid
          v-else
          class="ST2Cont"
          style="padding-top: 15px; padding-bottom: 15px"
        >
          <ion-row class="ST2Row">
            <ion-col class="ST2Col1">
              <ion-skeleton-text
                :animated="true"
                style="height: 15vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text
                :animated="true"
                style="height: 3vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonList,
  IonBadge,
  onIonViewWillEnter,
  IonSkeletonText,
onIonViewDidEnter,
} from "@ionic/vue";
import { ref, toRef, computed } from "vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { useProfile, fetchConversations } from "@/modules/useProfile.js";
import { formatDateTime } from "@/modules/useDate.js";
// import {io} from 'socket.io-client';
const { UserProfileReactive } = useProfile();

const chatPersonResults = toRef(
  UserProfileReactive.chat.conversations,
  "value"
);

console.log(chatPersonResults.value);

const chatFormatted = computed(() => {
  return chatPersonResults.value.map((item) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedChatObj = {
      _id: item._id,
      formattedNames: item.displayName,
      formattedDateTime: "",
      formattedMessage: "No messages yet",
      unreadMessages: 0,
    };

    if (item.lastMessage) {
      // let timeSplit = new Date(item.lastMessage?.timestamp).toLocaleTimeString(
      //   [],
      //   { hour: "2-digit", minute: "2-digit" }
      // );
      // let dateSplit = new Date(item.lastMessage?.timestamp).toLocaleDateString(
      //   "en-US",
      //   options
      // );
      //formatDateTime(item.lastMessage?.timestamp);
      const [dateSplit, timeSplit] = formatDateTime(
        item.lastMessage?.timestamp
      );
      formattedChatObj.formattedDateTime = timeSplit + " @ " + dateSplit;
      
      formattedChatObj.formattedMessage = item.lastMessage.content;
    }
    return formattedChatObj;
  });
});

// const dateOrderChatRes = chatPersonResults.value.sort((a, b) => {
//   return new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp);
// });

onIonViewDidEnter(fetchConversations);

const handleChange = (event) => {
  const query = event.target.value.toLowerCase();
  
  chatPersonResults.value = dateOrderChatRes.filter((d) => {
    return (
      d.userProfiles[1].name.toLowerCase().indexOf(query) > -1 ||
      // d.date.toLowerCase().indexOf(query) > -1 ||
      d.lastMessage.content.toLowerCase().indexOf(query) > -1
    );
  });
};
</script>

<style scoped lang="scss">
@import "@/styles/scss-views/trackside/chats/ChatLandingPage";
</style>
