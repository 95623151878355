<template>
  <ul class="as-chip-wrapper" style="margin-top: 0; margin-bottom: 0">
    <li :key="index" class="enteredChip" v-for="(chip, index) in modelValue">
      <span>{{ chip }}</span>
      <span
        v-on:click="removeOld(index, $event)"
        class="removeList"
        style="margin-left: 20px"
        >x</span
      >
    </li>
    <li class="notEnteredChip">
      <ion-input
        aria-label="Not Entered Chip"
        @input="onInput"
        v-on:keyup.enter="addNew"
        type="text"
        @ionBlur="addNew"
        ref="input"
      />
    </li>
  </ul>
</template>

<script setup>
import { IonInput } from "@ionic/vue";

import { ref, watch } from "vue";

const asProps = defineProps({
  // shimSide: String,
  // calItemPass: String,
  modelValue: Array,
});

const emit = defineEmits(["update:modelValue", "add", "remove"]);

// const chipArray = ref([]);

const inputValue = ref();
const input = ref(null);
// watch(
//   calReactive.ShockData[asProps.shimSide][asProps.calItemPass],
//   (newValue) => {
//     
//   }
// );

const addNew = (event) => {
  let shimmy = inputValue.value;
  
  if (shimmy === "" || shimmy === undefined) {
    return;
  }
  
  // calReactive.ShockData[asProps.shimSide][asProps.calItemPass].push(shimmy);
  let value = asProps.modelValue ? [...asProps.modelValue] : [];
  value.push(inputValue.value);
  emit("update:modelValue", value);
  emit("add", {
    originalEvent: event,
    value: value,
  });

  //
  input.value.$el.value = "";
  inputValue.value = ""; 

  //document.querySelector('.notEnteredChip ion-input').value = "";
  // shimNonArray.value = "";
  
};

function onInput(event) {
  inputValue.value = event.target.value;
}

const removeOld = (index, event) => {
  let value = asProps.modelValue ? [...asProps.modelValue] : [];
  value.splice(index, 1);
  emit("update:modelValue", value);
  emit("remove", {
    index,
    originalEvent: event,
    value: value,
  });
};
</script>

<style scoped>
.as-chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
}

.enteredChip {
  padding: 0.375rem 0.75rem;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.notEnteredChip {
  padding: 0.375rem 0;
}

.notEnteredChip ion-input {
  --padding-top: 0;
  --padding-bottom: 0;
  min-height: auto;
}
</style>
