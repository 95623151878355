import CalibrationModal from "@/components/modal-components/CalibrationModal.vue";
import SessionModal from "@/components/modal-components/SessionModal.vue";
import { modalController } from "@ionic/vue";
import { useLoadingSpinner } from "./LoadingSpinner";

export async function useSessionModal(opts) {
  // Open the session modal
  const {outingId, doRefresh = false, sessionId = undefined, turnData = undefined} = opts ?? {};
  if(!outingId)
    throw new Error("useSessionModal requires an outingId");
    
  const modal = await modalController.create({
    component: SessionModal,
    componentProps: {
      sessionId,
      outingId,
      fetchSession: doRefresh,
      turnData,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}

export async function useCalibrationModal(calibrationId) {
  // Open the calibration modal
  const modal = await modalController.create({
    component: CalibrationModal,
    componentProps: {
      calibrationId,
      fetchCal: true,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}

export async function useModalOutings(sessionId, outingId) {
  // 
  // const corners = ["RF", "LF", "RR", "LR"];
  // if (corners.includes(corner)) {
  //   var tireData = {
  //     TireData: calReactive.TireData[corner],
  //     ShockData: calReactive.ShockData[corner],
  //   };

  const modal = await modalController.create({
    component: SessionModal,
    componentProps: {
      sessionId,
      outingId,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}