<template>
  <div class="content-wrapper">
    <p class="title">
      <strong>{{ asProps.title }}</strong>
    </p>
    <ul class="input-list list-tire">
      <li>
        <div class="wrapper-div">
          <p>Camb</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].camb }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Toe</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].toe }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Ride</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].ride }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Tire</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].tire }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Start PSI</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].strtPSI }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Target PSI</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].targetPSI }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Caster Angle</p>
          <p>{{ calReactive.TireData[asProps.cornerSide].castAng }}</p>
        </div>
      </li>
    </ul>
    <ul class="input-list list-shock">
      <li>
        <div class="wrapper-div">
          <p>LSC</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].LSC }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>HSC</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].HSC }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>LSREB</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].LSREB }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>HSREB</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].HSREB }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Packer</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].PACK }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>PSI</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].PSI }}</p>
        </div>
      </li>
      <li>
        <div
          v-if="['RF', 'LF'].includes(asProps.cornerSide)"
          class="wrapper-div"
        >
          <p>FARB</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].FARB }}</p>
        </div>
        <div
          v-else-if="['RR', 'LR'].includes(asProps.cornerSide)"
          class="wrapper-div"
        >
          <p>RARB</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].RARB }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Spring</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].SPR }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Tn/Hlp Spring</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].tenhelpSPR }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div">
          <p>Bump Rubber</p>
          <p>{{ calReactive.ShockData[asProps.cornerSide].BumpRub }}</p>
        </div>
      </li>
      <li>
        <div class="wrapper-div-noflex">
          <p>Camber Shim</p>
          <ul class="shim-list">
            <li
              v-bind:key="shim"
              v-for="shim in calReactive.ShockData[asProps.cornerSide]
                .CamberShim"
            >
              {{ shim }}
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="wrapper-div-noflex">
          <p>Ride Height Shim</p>
          <ul class="shim-list">
            <li
              v-bind:key="shim"
              v-for="shim in calReactive.ShockData[asProps.cornerSide]
                .RideHeightShim"
            >
              {{ shim }}
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="wrapper-div-noflex">
          <p>Toe Shim</p>
          <ul class="shim-list">
            <li
              v-bind:key="shim"
              v-for="shim in calReactive.ShockData[asProps.cornerSide].ToeShim"
            >
              {{ shim }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
// import { useCalibration } from "@jackallee/usetrackside/reactive";
// const { calReactive } = useCalibration();

import { toRef } from "vue";

const asProps = defineProps({
  cornerSide: String,
  title: String,
  cal: Object,
});

const calReactive = toRef(asProps, 'cal');
</script>

<style scoped lang="scss">
ion-grid {
  background-color: transparent;
  margin: 0;
}

ion-row {
  background-color: var(--as-ion-grid-background);
  margin-bottom: 15px;
}

.title-row ion-col {
  text-align: center;
}

.title-row ion-col h2 {
  font-weight: 700;
}

.title-row ion-col p {
  margin-top: 0;
}

.corner-section {
  padding-bottom: 15px;
}

.corner-section ion-col {
  text-align: center;
}

.corner-section .corner-title-col p {
  margin-top: 16px;
  margin-bottom: 16px;
}
.corner-section ion-col p {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

.gray-corner {
  background-color: #888585;
}

.cross {
  background-color: #5c5c5c;
}

.total {
  background-color: black;
}

.content-wrapper .title {
  text-align: center;
  color: var(--ion-color-secondary);
}

.input-list {
  margin-left: 0;
  list-style-type: none;
  padding: 5px;
}

.input-list.list-tire {
  background-color: #888585;
  
  & p {
    color: var(--always-white);
  }
}

.shim-list {
  padding-left: 20px;
}

.input-list.list-header {
  background-color: black;
  color: white;
}

.input-list li {
  font-size: 15px;
}

.input-list li p {
  margin-top: 0;
  margin-bottom: 0;
}

.input-list li .wrapper-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-list li::part(native) {
  padding-left: 0;
}

.title-header {
  margin-bottom: 0;
}

.global-list {
  background-color: #888585;
  color: white;
  margin-top: 0;
}
</style>
