<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <HeaderComp title="Product Information">
      <ion-buttons slot="start">
        <ion-button
              slot="icon-only"
              @click="$router.push({ name: 'ShopLandingPage' })"
            >
              <ion-icon :icon="chevronBackOutline"></ion-icon>
            </ion-button>
      </ion-buttons>
    </HeaderComp>
    <ion-content class="ion-align-items-center">
      <ion-grid class="ST1Cont">
        <ion-row class="ST1Row">
          <ion-col class="ST1Col1 cal-sess-butt-wrap">
            <h1 class="title"><strong>{{ chosenProduct.name }}</strong></h1>
            <!-- <pre style="font-family: Helvetica, sans-serif; white-space: pre-wrap">{{ chosenProduct.longDescription }}</pre> -->
            <swiper
              :effect="'coverflow'"
              :grabCursor="true"
              :centeredSlides="true"
              :slidesPerView="'auto'"
              :coverflowEffect="{
                rotate: 100,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }"
              
              navigation
              class="productSwiper"
              :slideToClickedSlide="true"
            >
              <swiper-slide
                v-for="(product) in chosenProduct.productInformation"
                :key="product.id"
              >
              <div class="content-wrapper">
                
                <div class="image-wrapper">
                  <img class="as-image" :src="product.image" alt="" />
                </div>
                <p>{{ product.description }}</p>
              </div><!--content-wrapper-->
              
              </swiper-slide>
             </swiper>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton,
  IonButton,
} from "@ionic/vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, EffectCoverflow, Navigation, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-cards";

import {
  chevronBackOutline
} from "ionicons/icons";

import HeaderComp from "../../components/header-components/HeaderComp.vue";

import useShopContents from "@/modules/ShopContents";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

const { products } = useShopContents();
const route = useRoute();



const chosenProduct = ref();

chosenProduct.value = products.value.find(
  (prod) => prod.id === route.params.productId
);

const asPagination = {
  dynamicBullets: true,
  dynamicMainBullets: 1,
  renderBullet: function (index, className) {
    return '<span  class="' + className + '">' + (index + 1) + "</span>";
  },
  clickable: true,
};


// SwiperCore.use([Pagination, EffectCoverflow, Navigation, Zoom]);


</script>

<style scoped lang="scss">
.ST1Cont {
    margin: 15px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
    border-top: solid 8px var(--ion-color-black);

    & .ST1Row {

        & .ST1Col1.cal-sess-butt-wrap {

            
        }
    }
}

.title {
  font-weight: 700;
    text-align: center;
    font-size: 22px;
}

.productSwiper {
  

  & .swiper-slide {
  
    & .content-wrapper {
      
      & p {
        margin-top: 30px;
        margin-bottom: 75px;
        padding-left: 15px;
        padding-right: 15px;
      }

      & .image-wrapper {
        text-align: center;
        padding-top: 15px;
        & .as-image {
        width: 65%;
      }
      }

      
    }
  }
}

@media only screen and (min-width: 700px) and (max-width: 1023px) {
  .productSwiper {
  
  & .swiper-slide {
  
    & .content-wrapper {
      display: flex;

      & p {
        margin-top: 30px;
        margin-bottom: 75px;
        padding-left: 15px;
        padding-right: 15px;
        width: 50%;
        font-size: 16px;
      }

      & .image-wrapper {
        text-align: center;
        padding-top: 15px;
        width: 50%;
        & .as-image {
        width: 65%;
      }
      }

      
    }
  }
}
}

@media only screen and (min-width: 1024px) {

  .productSwiper {
  
  & .swiper-slide {
  
    & .content-wrapper {
      display: flex;

      & p {
        margin-top: 30px;
        margin-bottom: 75px;
        padding-left: 15px;
        padding-right: 15px;
        width: 50%;
        font-size: 16px;
      }

      & .image-wrapper {
        text-align: center;
        padding-top: 15px;
        width: 50%;
        & .as-image {
        width: 65%;
      }
      }

      
    }
  }
}
}

</style>
