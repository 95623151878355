<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-accordion>
    <ion-item slot="header">
      <ion-label>{{ title }}</ion-label>
    </ion-item>
    <ion-list slot="content">
      <ion-item>
        <ion-input
          label="Left Front"
          label-placement="stacked"
          :value="LF"
          @input="handleLFInputChange"
        ></ion-input>
      </ion-item>
      <div class="icon-item" @click="toggleLinkModeFront">
        <ion-icon class="icon-lock" :icon="linkModeFront ? linkOutline : unlinkOutline" size="medium"></ion-icon>
      </div>
      <ion-item class="right-item">
        <ion-input
          label="Right Front"
          label-placement="stacked"
          :value="RF"
          @input="handleRFInputChange"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="Left Rear"
          label-placement="stacked"
          :value="LR"
          @input="handleLRInputChange"
        ></ion-input>
      </ion-item>
      <div class="icon-item" @click="toggleLinkModeRear">
        <ion-icon class="icon-lock" :icon="linkModeRear ? linkOutline : unlinkOutline" size="medium"></ion-icon>
      </div>
      <ion-item class="right-item">
        <ion-input
          label="Right Rear"
          label-placement="stacked"
          :value="RR"
          @input="handleRRInputChange"
        ></ion-input>
      </ion-item>
    </ion-list>
  </ion-accordion>
</template>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue';
import { IonInput, IonList, IonItem, IonLabel, IonAccordion, IonButton, IonIcon } from "@ionic/vue";
import { linkOutline, unlinkOutline } from "ionicons/icons";

const props = defineProps({
  LF: { type: String, default: '' },
  RF: { type: String, default: '' },
  LR: { type: String, default: '' },
  RR: { type: String, default: '' },
  title: String,
});

const LF = ref(props.LF);
const RF = ref(props.RF);
const LR = ref(props.LR);
const RR = ref(props.RR);

const lastUpdatedFront = ref('LF');
const lastUpdatedRear = ref('LR');

const linkModeFront = ref(true);
const linkModeRear = ref(true);

// Emit event after handling input change
const handleLFInputChange = (event) => {
  LF.value = event.target.value;
  emit('update:LF', LF.value);
  if (linkModeFront.value) {
    RF.value = LF.value;
    emit('update:RF', RF.value);
  }
};

const handleRFInputChange = (event) => {
  RF.value = event.target.value;
  emit('update:RF', RF.value);
  if (linkModeFront.value) {
    LF.value = RF.value;
    emit('update:LF', LF.value);
  }
};

const handleLRInputChange = (event) => {
  LR.value = event.target.value;
  emit('update:LR', LR.value);
  if (linkModeRear.value) {
    RR.value = LR.value;
    emit('update:RR', RR.value);
  }
};

const handleRRInputChange = (event) => {
  RR.value = event.target.value;
  emit('update:RR', RR.value);
  if (linkModeRear.value) {
    LR.value = RR.value;
    emit('update:LR', LR.value);
  }
};

// Emit event after toggling link mode
const toggleLinkModeFront = () => {
  linkModeFront.value = !linkModeFront.value;
  if (linkModeFront.value) {
    if (RF.value === '' || LF.value !== '' && lastUpdatedFront.value === 'LF') {
      RF.value = LF.value;
      emit('update:RF', RF.value);  // emit event
    } else {
      LF.value = RF.value;
      emit('update:LF', LF.value);  // emit event
    }
  }
};

const toggleLinkModeRear = () => {
  linkModeRear.value = !linkModeRear.value;
  if (linkModeRear.value) {
    if (RR.value === '' || LR.value !== '' && lastUpdatedRear.value === 'LR') {
      RR.value = LR.value;
      emit('update:RR', RR.value);  // emit event
    } else {
      LR.value = RR.value;
      emit('update:LR', LR.value);  // emit event
    }
  }
};

const emit = defineEmits(['update:LF', 'update:RF', 'update:LR', 'update:RR'])

watch(() => props.LF, (newValue) => { LF.value = newValue; });
watch(() => props.RF, (newValue) => { RF.value = newValue; });
watch(() => props.LR, (newValue) => { LR.value = newValue; });
watch(() => props.RR, (newValue) => { RR.value = newValue; });

onMounted(() => {
  linkModeFront.value = LF.value === RF.value;
  linkModeRear.value = LR.value === RR.value;
});

</script>

<style scoped lang="scss">


.item-row {
  

  & ion-list {
    display: flex;
  flex-wrap: wrap;
  padding-right: 15px;
  padding-bottom: 15px;

    & ion-item {
      flex: 40%;
      &.right-item {

        &::part(native) {
          padding-inline-start: 0;
        }

      }

      
    }

    & .icon-item {
        flex: 10%;
        display: flex;
        justify-content: center;
          align-items: center;
        & ion-icon.icon-lock {
          font-size: 26px;
          
        }
      }
  }
}

</style>
