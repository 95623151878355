import { toastController } from "@ionic/vue";
import { useRouter } from "vue-router";

export function useToast(options = {}) {
  const router = useRouter();
  const {
    message = "Test",
    duration = 5000,
    position = "bottom",
    buttons = [],
    onDidDismiss = () => {},
    redirect = null,
  } = options ?? {};

  var dismiss = () => {};
  var present = () => {};
  var toast = {};
  async function createToast() {
    toast = await toastController.create({
      message,
      duration,
      position,
      buttons,
    });
    if (redirect) {
      router.push(redirect);
    }

    toast.onDidDismiss().then(onDidDismiss);

    dismiss = () => toast.dismiss();
    present = () => toast.present();
    present();
  }
  createToast();
  return { dismiss, present };
}

export function useErrorToast(e, opts = {}) {
  var {
    prefix = "",
    suffix = "",
    fallbackMessage = "An Unknown Error has Occurred",
  } = opts;
  const buttons = [
    {
      text: "Dismiss",
      role: "cancel",
    },
  ];
  if (e.code === "ERR_NETWORK") {
    return useToast({
      message: `${prefix}Network Error${suffix}`,
      duration: null,
      buttons,
    });
  }
  const data = e?.response.data || {};
  const message = prefix + data.message + suffix || fallbackMessage;
  
  if (e.code === "ERR_NETWORK") {
    return useToast({ message: "Network Error", duration: null, buttons });
  }

  return useToast({ message, duration: null, buttons });
}
