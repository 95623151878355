<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <div class="ion-page">
      <HeaderComp title="Trackside Products" >
        <ion-buttons slot="start">
          <ion-button
            slot="icon-only"
            @click="$router.push({ name: 'Dashboard' })"
          >
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        </HeaderComp>
      <ion-content>
        <div class="as-page-wrapper home-page shop-landing-page">
          <ion-grid class="ST1Cont">
            <ion-row class="ST1Row">
              <ion-col class="ST1Col1">
                <div class="title-content-wrapper">
                  <h2>Trackside Products</h2>
                  <p>
                    Enhance your track experience by choosing a product below:
                  </p>
                </div>
                <!--content-wrapper-->
              </ion-col>
            </ion-row>
            <ion-row class="ST1Row" v-if="products">
              <ion-col size-sm="6" class="ST1Col1" v-for="product in products" :key="product.id">
                <ion-card class="first-card as-card" >
                  <ion-card-header>
                    <ion-card-title>{{ product.name }}</ion-card-title>
                    <ion-card-subtitle v-if="product.subTitle"
                      >{{ product.subTitle }}</ion-card-subtitle
                    >
                  </ion-card-header>

                  <ion-card-content>
                    {{ product.shortDescription }}
                  </ion-card-content>
                  <div class="button-wrapper">
                    <ion-button
                      class="info-button"
                      @click="$router.push({ name: 'ShopProdInfoPage', params: { productId: product.id } })"
                      color="tertiary"
                      >Product Info</ion-button
                    >
                    <ion-button
                      class="buy-button"
                      @click="productChoice(product.id)"
                      color="tertiary"
                      >Buy Now</ion-button
                    >
                  </div>
                  <div class="price-circle">${{ product.price }}</div>
                </ion-card>
                
              </ion-col>
            </ion-row>
            <ion-row class="ST2Row" v-else>
            <ion-col class="ST2Col1">
              <ion-skeleton-text
                :animated="true"
                style="height: 15vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text
                :animated="true"
                style="height: 3vh"
              ></ion-skeleton-text>
              <br />
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
              <ion-skeleton-text :animated="true"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-grid>
        
        </div>
        <!--as-page-wrapper-->
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonButtons,
  IonThumbnail,
  IonCardSubtitle,
  IonSkeletonText,
  IonIcon,
} from "@ionic/vue";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import useShopContents from '@/modules/ShopContents';
import {
  chevronBackOutline,
} from "ionicons/icons";

const { products } = useShopContents();
const router = useRouter();

const productChoice = (productId) => {
  router.push({
    name: "ShopAddOnPage",
    query: { product: productId }
  });
};
</script>

<style scoped lang="scss">
.title-content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.title-content-wrapper h2 {
  font-weight: 700;
  text-align: center;
}

.title-content-wrapper p {
  text-align: center;
}

ion-card {
  background: var(--ion-color-white);
  position: relative;
  overflow: visible;
}

ion-card-title {
  font-size: 19px;
  margin-bottom: 7.5px;
  width: 85%;
}

ion-card-subtitle {
  font-size: 13px;
}

.price-circle {
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 8px;
  height: 60px;
  width: 60px;
  background: var(--ion-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: white;
  z-index: 10000;
  font-size: 16px;
}

.button-wrapper {
  display: flex;
  padding: 15px;
  padding-top: 0;
  justify-content: space-between;
  gap: 10px;

  & ion-button {
    width: 50%;

    &.info-button {
      &::part(native) {
        background: var(--as-gray-gradient-button);
        color: black;
      }
    }

    &.buy-button {
      &::part(native) {
        background: var(--as-black-gradient-button);
      }
    }
  }
}

.as-card {
  margin-top: 45px;
}

.as-card.first-card {
  margin-top: 20px;
}
</style>
