<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <HeaderComp title="Add Car">
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/garage" text="" :icon="chevronBackOutline"></ion-back-button>
      </ion-buttons>
    </HeaderComp>
    <ion-content class="as-content ion-align-items-center">
      <ion-grid class="ST1Cont" v-if="!skeletonIsLoading">
        <ion-row class="ST1Row">
          <ion-col class="ST1Col1">
            <p class="sub-title">Car</p>
            <ion-list>
              <ion-item id="make-field" class="form-control">
                <ion-input
                  label="Make:"
                  type="text"
                  placeholder="Porsche"
                  v-model="formData.make"
                  @ionBlur="validateMakeInput"
                  @ionChange="validateMakeInput"
                  required="true"
                ></ion-input>
                <ion-note slot="errorText"
                  >This field must not be blank</ion-note
                >
              </ion-item>
              <ion-item id="model-field" class="form-control">
                <ion-input
                  label="Model:"
                  placeholder="718 Cayman"
                  type="text"
                  v-model="formData.model"
                  @ionBlur="validateModelInput"
                  @ionChange="validateModelInput"
                  required="true"
                ></ion-input>
                <ion-note slot="errorText"
                  >This field must not be blank</ion-note
                >
              </ion-item>
              <ion-item id="year-field" class="form-control">
                <ion-input
                  label="Year:"
                  placeholder="2024"
                  type="text"
                  v-model="formData.year"
                  @ionBlur="validateYearInput"
                  @ionChange="validateYearInput"
                  required="true"
                ></ion-input>
                <ion-note slot="errorText"
                  >This field must not be blank</ion-note
                >
              </ion-item>
              <ion-item id="class-field" class="form-control">
                <ion-input
                  label="Class:"
                  placeholder="GT4 RS"
                  type="text"
                  v-model="formData.class"
                ></ion-input>
              </ion-item>
              <ion-item id="car-number" class="form-control">
                <ion-input
                  label="Car Num/ID:"
                  placeholder="057-#456"
                  type="text"
                  v-model="formData.number"
                ></ion-input>
              </ion-item>
              <ion-item id="car-notes" class="form-control">
                <ion-textarea
                  label="Car Notes:"
                  v-model="formData.note"
                  :auto-grow="true"
                ></ion-textarea>
              </ion-item>
            </ion-list>

            <div class="button-wrapper form-control">
              <ion-button size="default" @click="submitFormHandler">
                <!-- <ion-button size="default" @click="submitFormHandler"> -->
                Save Car
                <ion-icon
                  slot="end"
                  :icon="chevronForwardCircleOutline"
                ></ion-icon>
              </ion-button>
              <div class="button-error">{{ errorMessage }}</div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ST2Cont" v-if="skeletonIsLoading">
        <ion-row class="ST2Row">
          <ion-col class="ST2Col1">
            <div
              class="title-wrapper-main"
              style="
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: solid 1px #f0f0f0;
              "
            >
              <p class="cali-section-title">
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </p>
              <ion-button>
                <ion-skeleton-text :animated="true"></ion-skeleton-text>
              </ion-button>
            </div>
            <!--title-wrapper-->
            <ion-item>
              <ion-label>
                <p class="title">
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </p>
                <p style="line-height: 1.3em">
                  <ion-skeleton-text :animated="true"></ion-skeleton-text><br />
                  <ion-skeleton-text :animated="true"></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script setup>
import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";

import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  toastController,
  IonTextarea,
  onIonViewWillEnter,
  onIonViewDidEnter,
  IonButtons,
  IonBackButton,
  IonSkeletonText,
} from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";

import { chevronForwardCircleOutline, chevronBackOutline } from "ionicons/icons";
import { useGarage, useUser } from "@jackallee/usetrackside/reactive";
import HeaderComp from "../header-components/HeaderComp.vue";
import { useLoadingSpinner } from "@/modules/LoadingSpinner.js";
import { useToast, useErrorToast } from "@/modules/useToasts.js";
import { getClient } from "@jackallee/usetrackside/reactive";
const { isLoading } = useLoadingSpinner();

const route = useRoute();
const router = useRouter();
const { userState } = useUser();

const errorMessage = ref("");

const { garage, addCar, getCars, editCar } = useGarage();

//
const skeletonIsLoading = ref(false);

const findCar = ref();

const isEditing = ref(false);

const car = reactive({});

const formData = ref({
  uid: "",
  make: "",
  model: "",
  year: "",
  class: "",
  number: "",
  note: "",
});

onIonViewWillEnter(() => {
  formData.value.make = "";
  formData.value.model = "";
  formData.value.year = "";
  formData.value.class = "";
  formData.value.number = "";
  formData.value.note = "";
});
//
watchEffect(() => {
  if (route.query.id) {
    skeletonIsLoading.value = true;
    isEditing.value = true;
    getClient()
      .garage.getCar(route.query.id)
      .then((result) => {
        //

        if (result.data.success) {
          skeletonIsLoading.value = false;
          // eslint-disable-next-line no-prototype-builtins
          if (result.data.hasOwnProperty("car")) {
            Object.assign(car, { ...result.data.car });

            

            formData.value = {
              uid: car.uid || "",
              make: car.make || "",
              model: car.model || "",
              year: car.year || "",
              class: car.class || "",
              number: car.number || "",
              note: car.note || "",
            };
          }
        }
      });
  }
});

const validateMakeInput = () => {
  if (formData.value.make.length < 1) {
    formData.value.makeValidity = "invalid";
    document.querySelector("#make-field").classList.add("ion-invalid");
  } else {
    formData.value.makeValidity = "valid";
    document.querySelector("#make-field").classList.remove("ion-invalid");
  }
};
const validateModelInput = () => {
  if (formData.value.model.length < 1) {
    formData.value.modelValidity = "invalid";
    document.querySelector("#model-field").classList.add("ion-invalid");
  } else {
    formData.value.modelValidity = "valid";
    document.querySelector("#model-field").classList.remove("ion-invalid");
  }
};
const validateYearInput = () => {
  if (formData.value.year.length < 1) {
    formData.value.yearValidity = "invalid";
    document.querySelector("#year-field").classList.add("ion-invalid");
  } else {
    formData.value.yearValidity = "valid";
    document.querySelector("#year-field").classList.remove("ion-invalid");
  }
};

async function submitFormHandler() {
  const submittedData = {
    uid: userState.id,
    make: formData.value.make,
    model: formData.value.model,
    year: formData.value.year,
    class: formData.value.class,
    number: formData.value.number,
    note: formData.value.note,
  };
  if (route.query.id) {
    editCar(route.query.id, submittedData, {
      onComplete: () => {
        isLoading.value = false;
        getCars();
      },
      onSuccess: async (response) => {
          router.push({
            name: "GarageLanding",
            params: { carId: route.query.id },
          });
        const toast = await toastController.create({
          message: "Car Successfully Edited!",
          duration: 1500,
          position: "bottom",
        });
        toast.present();
      },
      onError: (e) => {
        console.error(e);
        useErrorToast(e);
      },
    });
  } else {
    addCar(submittedData, {
      onComplete: () => {
        isLoading.value = false;
        getCars();
      },
      onSuccess: async (response) => {
        // router.push({
        //   name: "CalibrationEditor",
        //   params: { editSessionId: self.crypto.randomUUID() },
        //   query: { car: response.data.id },
        // });

        router.push({
          name: "CarLandingPage",
          params: { carId: response.data.id },
        });
        const toast = await toastController.create({
          message: "Car Successfully Created!",
          duration: 1500,
          position: "bottom",
        });
        toast.present();
      },
      onError: (e) => {
        console.error(e);
        useErrorToast(e);
      },
    });
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/scss-components/scss-as-garage/AddNewCar";
</style>
