<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <HeaderComp title="Track Editor" />
    <ion-content>
      <ion-grid class="ST1Cont">
        <ion-row class="ST1Row">
          <ion-col class="ST1Col1">
            <div class="add-track-wrapper ST2Cont">
              <ion-item
                lines="none"
                class="date-title-custom item-prev-search-label"
              >
                <ion-label class="date-title-small" style="display: flex"
                  ><strong>Track</strong>
                  <ion-note class="required-text" style="font-size: 16px">
                    {{
                      _track
                        ? "&nbsp;&nbsp;&#10003;"
                        : "&nbsp;&nbsp;*"
                    }}</ion-note
                  ></ion-label
                >
                <div
                  class="previous-arrow"
                  v-show="!isTrackVisible"
                  style="text-align: right; font-size: 20px"
                >
                  <ion-button
                    style="font-size: 16px"
                    fill="clear"
                    color="secondary"
                    @click="displayTrackSearch"
                    >Back<ion-icon
                      slot="start"
                      :icon="arrowUndoOutline"
                    ></ion-icon
                  ></ion-button>
                </div>
              </ion-item>

      
              <div class="global-searchbar-wrapper" v-if="isTrackVisible">
                <ion-searchbar v-model="search"></ion-searchbar>
              </div>
             
              <ion-list class="global-track-list" v-if="isTrackVisible">
                <ion-item-group
                  v-for="(group, letter) in groupedTracks"
                  :key="letter"
                >
                  <ion-item-divider sticky="true">
                    <ion-label>{{ letter }}</ion-label>
                  </ion-item-divider>

                  <ion-item
                    class="track-list-item"
                    v-for="track in group"
                    :key="track.id"
                    @click="displayMap(track.url, track.id)"
                    :class="{ 'as-highlight': addHighlight(track.id) }"
                  >
                    <ion-label>{{ track.name }}</ion-label>
                  </ion-item>
                </ion-item-group>
              </ion-list>

              <ion-item lines="none" v-if="_track" class="turn-data">
                <ion-label>
                  <h2 class="track-map-title">
                    
                    <strong>{{ _track.name }}</strong>
                  </h2>
                  
                  <ion-img
                    id="corner-img"
                    class="track-map-image"
                    :src="_track.url"
                  ></ion-img>
                </ion-label>
              </ion-item>
              <ion-button
                @click="addCorner"
                class="submit-button"
                expand="block"
                fill="outline"
              >
                Add Corners
              </ion-button>
            
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="ST1Cont" v-if="_track">
        <ion-row class="ST1Row">
          <ion-col class="ST1Col1">
            <div class="" v-if="isReady">
              <ion-card
                style="max-width: 600px; margin: auto"
                class="mySwiper slide-wrapper-border-top"
              >
                <ion-card-header>
                  <ion-card-subtitle class="subtitle-trackname"
                    >{{ _track.name }}</ion-card-subtitle
                  >
                </ion-card-header>
                <div
                  class="image-wrapper"
                  style="margin-bottom: 15px; margin-top: 15px; "
                >
                  <tracks-viewer
                    :view-port="trackViewPort"
                    ref="tracksViewer"
                    class="as-image"
                    :image-url="_track.url"
                  />
                </div>
              </ion-card>
              <!-- took   :modules="modules" out of swiper-->
              <swiper class="mySwiper" ref="swiperEl" init="false"
                :effect="'coverflow'"
                :grabCursor="true"
                :centeredSlides="true"
                :slidesPerView="'auto'"
                :coverflowEffect="{
                  rotate: 100,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }"
                :pagination="asPagination"
           
                :slideToClickedSlide="true"
                @slideChange="handleSlideChange"
              >
                <swiper-slide
                  v-for="(corner, k, i) in _track.corners"
                  :key="k"
                  :virtual-index="i"
                >
                  <ion-card class="slide-wrapper-border-top">
                    <ion-card-header>
                      <ion-card-subtitle class="subtitle-trackname">{{
                        _track.name
                      }}</ion-card-subtitle>
                      <ion-card-title>{{ corner.name }}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                      <ion-button @click="setCornerData(k)" fill="outline">
                        Set Corner {{ corner.number }}
                      </ion-button>

                      <ion-input
                        fill="outline"
                        label="Edit Corner Name:"
                        v-model="corner.name"
                      ></ion-input>
                      {{ corner }}
                    </ion-card-content>
                  </ion-card>
                </swiper-slide>
              </swiper>
              <ion-button
                @click="saveContent"
                class="submit-button"
                expand="block"
                fill="outline"
              >
                Save Track
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import {
  IonPage,
  IonContent,
  IonImg,
  IonText,
  IonButton,
  pickerController,
  IonList,
  IonItem,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  toastController,
  IonInput,
  IonLabel,
  onIonViewWillEnter,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonModal,
  alertController,
  modalController,
  IonItemGroup,
  IonItemDivider,
  IonNote,
  IonSearchbar,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import {
  ref,
  computed,
  toRef,
  onMounted,
  inject,
  unref,
  nextTick,
  watchEffect,
} from "vue";
// import Corner from "./CornerSetup.vue";
import TracksViewer from "@/components/TracksViewer.vue";
import { trash, medalOutline } from "ionicons/icons";
// import SVGDefault from "../components/svg/SvgDefault.vue";
import { useTurnData, useTracks, getClient } from "@jackallee/usetrackside/reactive";
import { addOutline, stopwatchOutline } from "ionicons/icons";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { Pagination, EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
// import {
//   Pagination,
//   EffectCoverflow,
//   Navigation,
//   Virtual,
// } from "swiper/modules";
// import "swiper/element/css/navigation";
// import "swiper/element/css/pagination";
// import "swiper/element/css/effect-coverflow";
// import "swiper/element/css/virtual";
import useSearchFilter from "@/modules/useSearchFilter.js";
import { useToast, useErrorToast } from "@/modules/useToasts.js";
import { arrowUndoOutline } from "ionicons/icons";

const { getTrack, getAllTracks } = useTracks();

const trackList = ref([]);

getAllTracks({
  onSuccess: (res) => {
    trackList.value = res.data.tracks;
    console.log(trackList);
  },
  onError: (err) => {
    console.error(err);
  },
});

const props = defineProps({
  CurrentOuting: Object,
  TurnData: Object,
  Setup: Object,
});
const tracksViewer = ref();
console.log(tracksViewer);
const _track = ref();
const client = getClient();

const trackViewPort = ref({ x: 0, y: 0, z: 1 });

const { isReady } = getTrack("lime-rock", {
  onSuccess: (res) => {
    _track.value = res.data.track;
    trackViewPort.value = res.data.track.corners[0].position;
    console.log(_track);
  },
  onError: (err) => {
    console.error(err);
  },
});

function setCornerData(i) {
  console.log(i);
  Object.assign(
    _track.value.corners[i].position,
    unref(tracksViewer.value.position)
  );
  console.log(_track.value.corners[i], tracksViewer.value.position);
}

function handleSlideChange(swiper) {
  let activeIndex = swiper.activeIndex;
  let trackCorner = _track.value.corners[activeIndex];
  console.log(trackCorner);
  trackViewPort.value = trackCorner.position.hasOwnProperty("x")
    ? trackCorner.position
    : { x: 0, y: 0, z: 1 };
}

const saveContent = async () => {
  const res = await client.tracks.tracksideFetch("/admin/tracks/set-corners", {
    method: "POST",
    data: {
      trackId: _track.value.id,
      corners: _track.value.corners,
    },
  });
};

function addCorner() {
  _track.value.corners.push({
    name: "New Corner",
    number: _track.value.corners.length + 1,
    position: {
      x: 0,
      y: 0,
      z: 1,
    },
  });
}
const deleteHandler = (index) => {
  const currentLapArray = CurrentOuting.value.laptimes;

  const updatedLapArray = [
    ...currentLapArray.slice(0, index),
    ...currentLapArray.slice(index + 1),
  ];

  let bestTime = CurrentOuting.value.laptimes[0];

  if (updatedLapArray.length === 0) {
    CurrentOuting.value["bestTime"] = "";
  } else {
    for (let i = 1; i < updatedLapArray.length; i++) {
      const currentLapTime = updatedLapArray[i];
      if (compareLapTimes(currentLapTime, bestTime) < 0) {
        bestTime = currentLapTime;
      }
    }
    // Assign the best lap time to the 'bestTime' property
    CurrentOuting.value["bestTime"] = bestTime;
  }

  // Assign the updated array back to CurrentOuting or use it as needed
  CurrentOuting.value.laptimes = updatedLapArray;
};

const asPagination = {
  dynamicBullets: true,
  dynamicMainBullets: 1,
  renderBullet: function (index, className) {
    return '<span  class="' + className + '">' + (index + 1) + "</span>";
  },
  clickable: true,
};

SwiperCore.use([Pagination, EffectCoverflow, Navigation]);

// async function cancelHome() {
//   const alert = await alertController.create({
//     header: "Warning",
//     message:
//       "Pressing OK will return you to the Session Home page and lose all entered data",
//     buttons: [
//       {
//         text: "Cancel",
//         role: "cancel",
//         handler: () => {},
//       },
//       {
//         text: "OK",
//         handler: () => {
//           router.push({ name: "SessionsLanding" });
//         },
//       },
//     ],
//   });

//   await alert.present();
// }

onIonViewWillEnter(async function () {
  _track.value = "";
});

const highlightedTrackId = ref(null);

const isTrackVisible = ref(true);

const scrollToTop = () => {
  const element = document.getElementById("trackTop");
  //console.log(element, element.getBoundingClientRect());

  if (element) {
    //console.log(element.getBoundingClientRect());
    element.scrollIntoView({ behavior: "smooth" });
    //console.log('event-fired')
  }
};

// const scrollToTop = () => {
//   const container = document.getElementById('trackTopParent');
//   //console.log(container.getBoundingClientRect())
//   const element = document.getElementById('trackTop');
//   //console.log(element.getBoundingClientRect())
//   if (container && element) {
//     const topPos = element.getBoundingClientRect().top - container.getBoundingClientRect().top;
//     container.scrollTop = topPos;
//   }
// };
const trackResults = ref(trackList);

const displayMap = (mapUrl, trackId) => {
  _track.value = trackResults.value.find(
    (map) => map.url === mapUrl
  );

  isTrackVisible.value = true;
  trackViewPort.value = _track.value.corners[0].position;

  nextTick(() => {
    scrollToTop();
    isTrackVisible.value = false;
  });
};

const displayTrackSearch = () => {
  isTrackVisible.value = true;
  search.value = "";
  _track.value = false;
};

const addHighlight = (trackId) => {
  if (filteredData.value.length === 0) {
    return false;
  }
  return trackId === highlightedTrackId.value;
};

console.log(trackResults);

// const alphaTrackResults = ref(
//   [...trackResults.value].sort((a, b) => a.name.localeCompare(b.name))
// );

// console.log(alphaTrackResults);

const { search, filteredData } = useSearchFilter(trackResults, ["name"]);

const groupedTracks = ref({});

watchEffect(() => {
  // Changed to watchEffect for immediate execution
  groupedTracks.value = {};

  filteredData.value.forEach((track) => {
    const firstLetter = track.name.charAt(0).toUpperCase();
    if (!groupedTracks.value[firstLetter]) {
      groupedTracks.value[firstLetter] = [];
    }
    groupedTracks.value[firstLetter].push(track);
  });
});

defineExpose({ search, filteredData });
</script>

<style scoped lang="scss">
ion-item.best-lap {
  & p {
    font-weight: 700;
    font-style: italic;
  }

  & ion-icon {
    &.trophy-icon {
    }
  }
}
.turn-outing-details.as-track-image {
  border-top: solid 8px var(--ion-color-black);
  margin-bottom: 30px;
  margin-top: 45px;

  & .title-wrapper {
    margin-bottom: 0;
    & ion-item {
      &::part(native) {
        padding-inline-start: 0;
      }
    }
  }
}

.as-track-image-wrapper {
  margin-top: 30px;
  margin-bottom: 45px;
}

.slide-wrapper-border-top {
  border-top: solid 8px var(--ion-color-black);
}

.subtitle-trackname {
  font-size: 30px;
  font-family: Helvetica, sans-serif;
  text-transform: capitalize;
}

ion-card {
  background-color: var(--as-ion-grid-background);
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.as-track-image {
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
}

.as-laptime-wrapper {
  background-color: var(--as-ion-grid-background);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
}

.as-laptime-wrapper {
  margin-top: 30px;
  margin-bottom: 45px;
}

.as-laptime-wrapper .button-wrapper {
  margin-top: -5px;
  text-align: right;
}

/* .as-laptime-wrapper .button-wrapper ion-button::part(native) {
  background-color: var(--ion-color-secondary);
} */

.as-laptime-wrapper .lap-time-wrapper ion-list {
  padding-top: 10px;
}

.as-laptime-wrapper .lap-time-wrapper ion-list ion-item::part(native) {
  margin-left: 0;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  --inner-padding-end: 0;
}

.as-laptime-wrapper .lap-time-wrapper ion-list ion-item {
  display: flex;
  justify-content: space-between;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.trash-button {
  font-weight: 700;
  margin-left: auto;
  height: 60%;

  &::part(native) {
    padding-left: 20px;
    padding-right: 20px;
    background: var(--as-red-gradient-button);
  }

  & ion-icon {
    font-size: 18px;
  }
}

.as-track-image ion-text {
  font-weight: 700;
  color: black;
}

.half-half-styleT .image-wrapper svg {
  width: 100%;
  object-fit: contain;
}

.swiper {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
}

.swiper-slide ion-card {
  box-shadow: var(--as-box-shadow);
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.lap-button {
  color: var(--ion-color-black);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}
</style>
