<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div class="outings-wrapper">
    <div class="outings-small-inner-wrapper">
      <div class="outings-item outings-item-details">
  <div
    class="as-track-image turn-outing-details ST2Cont as-track-image-wrapper"
  >
  <div class="title-wrapper">
    <p><strong>Outing Details</strong></p>
    <ion-item>
      <ion-input
        label="Outing"
        label-placement="stacked"
        placeholder="Outing Name"
        v-model="CurrentOuting.title"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-input
        label="Driver"
        label-placement="stacked"
        placeholder="Driver Name"
        v-model="CurrentOuting.driver"
      ></ion-input>
    </ion-item>
  </div>
  </div>

  </div><!--outings-item-->
  <!-- <div class="outings-item  outings-item-turns  outings-show-small"> -->
    <div class="outings-item  outings-item-turns ">
  <OutingsTurns 
    :CurrentOuting="CurrentOuting"
    :TrackData="TrackData"
    :Setup="Setup"
  />
</div><!--outings-item-->
<div class="outings-item  outings-item-trackimage">
  <div class="as-track-image ST1Cont as-track-image-wrapper">
    <div class="title-wrapper">
      <p class="cal-sess-title" style="margin-top: 0">
        <strong v-if="TrackData">{{ TrackData.name }} Overview</strong>
      </p>
    </div>
    <ion-img id="corner-img" :src="TrackData.url"></ion-img>
  </div>
</div><!--outings-item-->
<div class="outings-item  outings-item-laps">
  <div class="as-laptime-wrapper ST1Cont">
    <div class="content-wrapper">
      <div class="flex-wrapper">
        <div class="header-wrapper">
          <ion-text>
            <p><strong>Lap Times</strong></p>
          </ion-text>
        </div>
        <div class="button-wrapper">
          <ion-button
            id="open-custom-dialog"
            class="lap-button"
            fill="outline"
            @click="clearInputsHandler"
          >
            <ion-icon slot="start" :icon="addOutline"></ion-icon>Add Lap
          </ion-button>
        </div>
      </div>
    </div>
    <div class="lap-time-wrapper">
      <ion-list>
        <ion-item
          :id="`smooth${index}`"
          v-for="(lap, index) in CurrentOuting.laptimes"
          v-bind:key="index"
          lines="none"
          :class="{
            'best-lap':
              JSON.stringify(lap) === JSON.stringify(CurrentOuting.bestTime),
          }"
        >
          <ion-icon
            v-if="
              JSON.stringify(lap) === JSON.stringify(CurrentOuting.bestTime)
            "
            class="trophy-icon"
            :icon="medalOutline"
            slot="start"
          ></ion-icon>
          <ion-icon
            v-if="
              JSON.stringify(lap) !== JSON.stringify(CurrentOuting.bestTime)
            "
            :icon="stopwatchOutline"
            slot="start"
          ></ion-icon>
          <p>
            <strong>Lap {{ index + 1 }}:</strong>&nbsp;&nbsp;{{ lap.minutes }} :
            {{ lap.seconds }} . {{ lap.milliseconds }}
          </p>

          <ion-button
            class="trash-button as-button"
            slot="end"
            color="secondary"
            @click="deleteWarning(index)"
          >
            <ion-icon
              slot="icon-only"
              src="/assets/icon/new-action-icons/trash-icon-white.svg"
            ></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </div>
  </div>
</div><!--outings-item-->
    </div>
    <!-- <div class="outings-large-inner-wrapper outings-show-large">
      <div class="outings-item  outings-item-turns">
  <OutingsTurns 
    :CurrentOuting="CurrentOuting"
    :TrackData="TrackData"
    :Setup="Setup"
  />
</div>
    </div> -->
   
</div><!--outings-wrapper-->
  <!-- Lap Times Model -->
  <ion-modal
    id="laptime-modal"
    ref="modal"
    trigger="open-custom-dialog"
    :initial-breakpoint="1"
    :breakpoints="[0, 1]"
  >
    <div class="modal-wrapper">
      <div class="modal-title-wrapper">
        <p class="smaller-text" @click="cancelHandler">Discard</p>

        <p class="smaller-text" @click="saveContent(lapMin, lapSec, lapMs)">
          Save
        </p>
      </div>
      <p class="modal-lap-title"><strong>Add Lap Time</strong></p>

      <ion-list lines="none">
        <ion-item>
          <ion-input
            v-model="lapMin"
            label="Min:"
            type="number"
            placeholder="00"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            v-model="lapSec"
            label="Sec:"
            type="number"
            placeholder="00"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            v-model="lapMs"
            label="Ms:"
            type="number"
            placeholder="000"
          ></ion-input>
        </ion-item>
      </ion-list>
    </div>
  </ion-modal>

    
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

import {
  IonPage,
  IonContent,
  IonImg,
  IonText,
  IonButton,
  pickerController,
  IonList,
  IonItem,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  toastController,
  IonInput,
  IonLabel,
  onIonViewWillEnter,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonModal,
  alertController,
  modalController,
} from "@ionic/vue";
import { ref, computed, toRef, onMounted, inject } from "vue";
import OutingsTurns from "@/components/outings/OutingsTurns.vue";
import {  addOutline, stopwatchOutline, medalOutline } from "ionicons/icons";

const props = defineProps({
  CurrentOuting: Object,
  TrackData: Object,
  TurnData: Object,
  Setup: Object,
});

const TurnData = toRef(props, "TurnData");
const Setup = toRef(props, "Setup");
const CurrentOuting = toRef(props, "CurrentOuting");

console.log(CurrentOuting.value);
console.log(Setup.value.trackId);


onMounted(() => {
  document.querySelectorAll(".svglink").forEach((elem) =>
    elem.addEventListener("click", (e) => {
      e.preventDefault();

      elem.classList.add("as-highlight");
    })
  );
  if (!CurrentOuting.value.laptimes) {
    CurrentOuting.value.laptimes = [];
  }
});

//copied over logic
const lapMin = ref();
const lapSec = ref();
const lapMs = ref();
console.log(Setup.value.trackId);


const lapTime = ref();

const isFetching = ref(true);
const route = useRoute();
const router = useRouter();
const eventDate = ref("");

// Function to compare two lap times
function compareLapTimes(lapTime1, lapTime2) {
  // Convert each lap time to milliseconds for comparison
  const time1 = convertToMilliseconds(lapTime1);
  const time2 = convertToMilliseconds(lapTime2);

  // Compare the lap times and return the result
  return time1 - time2;
}

// Function to convert a lap time to milliseconds
function convertToMilliseconds(lapTime) {
  const minutes = lapTime.minutes * 60000;
  const seconds = lapTime.seconds * 1000;
  const milliseconds = lapTime.milliseconds;
  return minutes + seconds + milliseconds;
}

function handleSlideChange(e) {
  console.log(e.target.swiper.activeIndex);
  let activeIndex = e.target.swiper.activeIndex;
  let trackCorner = props.TrackData.corners[activeIndex];
  trackViewPort.value = trackCorner.position || { x: 0, y: 0, z: 1 };
}

const cancelHandler = () => {
  return modalController.dismiss(null, "cancel");
};

const clearInputsHandler = () => {
  lapMin.value = "";
  lapSec.value = "";
  lapMs.value = "";
};

const saveContent = (minutes, seconds, milliseconds) => {
  if (
    minutes !== undefined &&
    seconds !== undefined &&
    milliseconds !== undefined
  ) {
    // Convert lap time values to numbers
    // const minutes = Number(laptime.minutes.value);
    // const seconds = Number(laptime.seconds.value);
    // const milliseconds = Number(laptime.milliseconds.value);

    // Check if any of the converted values are NaN (not a number)
    if (isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
      //
      useToast({
        message: "Please enter valid values for all lap time fields.",
      });
      return; // Stop further execution
    }

    const correctedMinutes =
      minutes < 10 && minutes >= 0 && minutes[0] !== "0"
        ? `0${minutes}`
        : minutes;
    const correctedSeconds =
      seconds < 10 && seconds >= 0 && seconds[0] !== "0"
        ? `0${seconds}`
        : seconds;

    if (milliseconds.length !== 3 || isNaN(milliseconds)) {
      useToast({
        message: "Milliseconds should be a 3-digit number.",
      });
      return; // Stop further execution
    }

    if (minutes > 59 || seconds > 59) {
      useToast({
        message: "Minutes and seconds should be less than or equal to 59.",
      });
      return; // Stop further execution
    }

    lapTime.value = {
      milliseconds,
      seconds: correctedSeconds,
      minutes: correctedMinutes,
    };

    // Create a new lap time object with numeric values
    // const lapTime = {
    //   minutes,
    //   seconds,
    //   milliseconds,
    // };

    // lapArray.value.push(lapArrayNewItem);
    CurrentOuting.value["laptimes"] = [
      ...CurrentOuting.value.laptimes,
      lapTime.value,
    ];

    let bestTime = CurrentOuting.value.laptimes[0];
    for (let i = 1; i < CurrentOuting.value.laptimes.length; i++) {
      const currentLapTime = CurrentOuting.value.laptimes[i];
      if (compareLapTimes(currentLapTime, bestTime) < 0) {
        bestTime = currentLapTime;
      }
    }

    // Assign the best lap time to the 'bestTime' property
    CurrentOuting.value["bestTime"] = bestTime;

    return modalController.dismiss(null, "cancel");
  }
};

async function deleteWarning(index) {
  const alert = await alertController.create({
    header: "Warning",
    message: "Pressing OK will permanently delete the laptime.",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",

        handler: () => {
          const currentLapArray = CurrentOuting.value.laptimes;

          const updatedLapArray = [
            ...currentLapArray.slice(0, index),
            ...currentLapArray.slice(index + 1),
          ];

          let bestTime = CurrentOuting.value.laptimes[0];

          if (updatedLapArray.length === 0) {
            CurrentOuting.value["bestTime"] = "";
          } else {
            for (let i = 1; i < updatedLapArray.length; i++) {
              const currentLapTime = updatedLapArray[i];
              if (compareLapTimes(currentLapTime, bestTime) < 0) {
                bestTime = currentLapTime;
              }
            }
            // Assign the best lap time to the 'bestTime' property
            CurrentOuting.value["bestTime"] = bestTime;
          }

          // Assign the updated array back to CurrentOuting or use it as needed
          CurrentOuting.value.laptimes = updatedLapArray;
        },
      },
    ],
  });

  await alert.present();
}

const deleteHandler = (index) => {
  const currentLapArray = CurrentOuting.value.laptimes;

  const updatedLapArray = [
    ...currentLapArray.slice(0, index),
    ...currentLapArray.slice(index + 1),
  ];

  let bestTime = CurrentOuting.value.laptimes[0];

  if (updatedLapArray.length === 0) {
    CurrentOuting.value["bestTime"] = "";
  } else {
    for (let i = 1; i < updatedLapArray.length; i++) {
      const currentLapTime = updatedLapArray[i];
      if (compareLapTimes(currentLapTime, bestTime) < 0) {
        bestTime = currentLapTime;
      }
    }
    // Assign the best lap time to the 'bestTime' property
    CurrentOuting.value["bestTime"] = bestTime;
  }

  // Assign the updated array back to CurrentOuting or use it as needed
  CurrentOuting.value.laptimes = updatedLapArray;
};

</script>

<style scoped lang="scss">
ion-item.best-lap {
  & p {
    font-weight: 700;
    font-style: italic;
  }

  & ion-icon {
    &.trophy-icon {
    }
  }
}
.turn-outing-details.as-track-image {
  border-top: solid 8px var(--ion-color-black);
  margin-bottom: 30px;
  margin-top: 45px;
  background-color: var(--ion-color-white);

  & .title-wrapper {
    margin-bottom: 0;
    & ion-item {
      &::part(native) {
        padding-inline-start: 0;
      }
    }
  }
}

.as-track-image-wrapper {
  margin-top: 30px;
  margin-bottom: 45px;
}

.slide-wrapper-border-top {
  border-top: solid 8px var(--ion-color-black);
}

.subtitle-trackname {
  font-size: 30px;
  font-family: Helvetica, sans-serif;
  text-transform: capitalize;
}

ion-card {
  background-color: var(--as-ion-grid-background);
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.as-track-image {
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
}

.as-laptime-wrapper {
  background-color: var(--as-ion-grid-background);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
}

.as-laptime-wrapper {
  margin-top: 30px;
  margin-bottom: 45px;
}

.as-laptime-wrapper .button-wrapper {
  margin-top: -5px;
  text-align: right;
}

/* .as-laptime-wrapper .button-wrapper ion-button::part(native) {
  background-color: var(--ion-color-secondary);
} */

.as-laptime-wrapper .lap-time-wrapper ion-list {
  padding-top: 10px;
}

.as-laptime-wrapper .lap-time-wrapper ion-list ion-item::part(native) {
  margin-left: 0;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  --inner-padding-end: 0;
}

.as-laptime-wrapper .lap-time-wrapper ion-list ion-item {
  display: flex;
  justify-content: space-between;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.trash-button {
  font-weight: 700;
  margin-left: auto;
  height: 60%;

  &::part(native) {
    padding-left: 20px;
    padding-right: 20px;
    background: var(--as-red-gradient-button);
  }

  & ion-icon {
    font-size: 18px;
  }
}

.as-track-image ion-text {
  font-weight: 700;
  color: black;
}

.half-half-styleT .image-wrapper svg {
  width: 100%;
  object-fit: contain;
}

.swiper {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: auto;
}

.swiper-slide ion-card {
  box-shadow: var(--as-box-shadow);
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.lap-button {
  color: var(--ion-color-black);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}
</style>
