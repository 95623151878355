<template>
  <ion-grid class="ST1Cont">
    <ion-row class="title-row head-comp-title as-top-border">
      <div class="red-circle-wrapper">
        <img class="as-red-circle" :src="image" alt="" />
      </div>
      <ion-col class="title-col">
        <h2>Calibration Setup</h2>
      </ion-col>
    </ion-row>
    <ion-row class="calibration-title-row-top">
      <ion-col>
        <ion-item lines="none">
          <ion-input
            label="Enter Calibration Title"
            label-placement="floating"
            v-model="calReactive.Header.title"
          ></ion-input>
          <!-- <small
              v-if="
               false
              "
              style="
                color: red;
                display: flex;
                justify-content: center;
                background-color: var(--as-chat-white-transp);
              "
            >
              {{ CalibrationState.message }}
            </small> -->
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="frontback-title-row front-row">
      <ion-col class="front-col">
        <div class="content-wrapper">
          <p class="ion-text-center"><strong>Front</strong></p>
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="ST1Row front-tire-row" style="position: relative">
      <ion-col size="6" class="ST1Col1 ST1Col1-left ST1Col1-top">
        <ion-button
          fill="outline"
          class="as-button corner-button"
          id="top-left"
          @click="openCorner('LF')"
          style="position: relative"
        >
          LF
        </ion-button>
        <div class="red-circle-wrapper front-left">
          <img
            class="red-circle"
            src="/assets/images/as-images/red-highlight-circle.svg"
            alt=""
          />
        </div>
      </ion-col>
      <ion-col size="6" class="ST1Col1 ST1Col1-right ST1Col1-top">
        <ion-button
          fill="outline"
          class="as-button corner-button"
          id="top-right"
          @click="openCorner('RF')"
          style="position: relative"
        >
          RF
        </ion-button>
        <div class="red-circle-wrapper front-right">
          <img
            class="red-circle"
            src="/assets/images/as-images/red-highlight-circle.svg"
            alt=""
          />
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ST1Row back-tire-row">
      <ion-col size="6" class="ST1Col1 ST1Col1-left ST1Col1-bottom">
        <ion-button
          fill="outline"
          class="as-button corner-button"
          id="bottom-left"
          @click="openCorner('LR')"
          style="position: relative"
        >
          LR
        </ion-button>
        <div class="red-circle-wrapper back-left">
          <img
            class="red-circle"
            src="/assets/images/as-images/red-highlight-circle.svg"
            alt=""
          />
        </div>
      </ion-col>
      <ion-col size="6" class="ST1Col1 ST1Col1-right ST1Col1-bottom">
        <ion-button
          fill="outline"
          class="as-button corner-button"
          id="bottom-right"
          @click="openCorner('RR')"
          style="position: relative"
        >
          RR
        </ion-button>
        <div class="red-circle-wrapper back-right">
          <img
            class="red-circle"
            src="/assets/images/as-images/red-highlight-circle.svg"
            alt=""
          />
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="frontback-title-row back-row">
      <ion-col class="back-col">
        <div class="content-wrapper">
          <p class="ion-text-center"><strong>Rear</strong></p>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="global-cal-row last-row">
      <ion-col size="12">
        <div class="content-wrapper">
          <p class="ion-text-center"><strong>Aero-Dynamics</strong></p>
        </div>
      </ion-col>
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-input
              label="Front Splitter"
              label-placement="floating"
              v-model="calReactive.Aero.FrSplitter"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Front Dive Plane 1"
              label-placement="floating"
              v-model="calReactive.Aero.FrDivePlane1"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Front Dive Plane 2"
              label-placement="floating"
              v-model="calReactive.Aero.FrDivePlane2"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Front Third Element"
              label-placement="floating"
              v-model="calReactive.Aero.Fr3rdElement"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Wing Shim"
              label-placement="floating"
              v-model="calReactive.Aero.WingShim"
            ></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-input
              label="Rear Wing"
              label-placement="floating"
              v-model="calReactive.Aero.RrWing"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Rear Diffuser"
              label-placement="floating"
              v-model="calReactive.Aero.RrDiffuser"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Rear Gurney"
              label-placement="floating"
              v-model="calReactive.Aero.RrGurney"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Rear Third Element"
              label-placement="floating"
              v-model="calReactive.Aero.Rr3rdElement"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Rake"
              label-placement="floating"
              v-model="calReactive.Aero.Rake"
            ></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row class="global-cal-row last-row">
      <ion-col size="12">
        <div class="content-wrapper">
          <p class="ion-text-center"><strong>Global Fields</strong></p>
        </div>
      </ion-col>
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-input
              label="Preload lb/ft"
              label-placement="floating"
              v-model="calReactive.Header.Preload"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Bias %"
              label-placement="floating"
              v-model="calReactive.Header.Bias"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              label="Differential Acceleration"
              label-placement="floating"
              v-model="calReactive.Header.DiffAccel"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Differential Deceleration"
              label-placement="floating"
              v-model="calReactive.Header.DiffDeccel"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Brake Cooling Front"
              label-placement="floating"
              v-model="calReactive.Header.BrkCoolFront"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Brake Cooling Rear"
              label-placement="floating"
              v-model="calReactive.Header.BrkCoolRear"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Front Master Cylinder dia"
              label-placement="floating"
              v-model="calReactive.Header.FrtMastCylDia"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Crn Wgt Cross"
              label-placement="floating"
              v-model="calReactive.CornerWeights.Cross"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Anti Lock Braking"
              label-placement="floating"
              v-model="calReactive.Header.abs"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Elec Stability Control"
              label-placement="floating"
              v-model="calReactive.Header.esc"
            ></ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-input
              label="Front Brand/Size Tire"
              label-placement="floating"
              v-model="calReactive.Header.TireFrontBRSZ"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Rear Brand/Size Tire"
              label-placement="floating"
              v-model="calReactive.Header.TireRearBRSZ"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              label="Brake Pad Front"
              label-placement="floating"
              v-model="calReactive.Header.BrkPadFront"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Brake Pad Rear"
              label-placement="floating"
              v-model="calReactive.Header.BrkPadRear"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Brake Disc Front"
              label-placement="floating"
              v-model="calReactive.Header.BrkDiscFront"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-input
              label="Brake Disc Rear"
              label-placement="floating"
              v-model="calReactive.Header.BrkDiscRear"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              label="Rear Master Cylinder dia"
              label-placement="floating"
              v-model="calReactive.Header.RrMastCylDia"
            ></ion-input>
          </ion-item>

          <ion-item>
            <ion-input
              label="Crn Wgt Total"
              label-placement="floating"
              v-model="calReactive.CornerWeights.Total"
            ></ion-input>
          </ion-item>
          
          <ion-item>
            <ion-input
              label="Traction Control"
              label-placement="floating"
              v-model="calReactive.Header.tc"
            ></ion-input>
          </ion-item>
          <ion-item lines="none"></ion-item>
        </ion-list>
      </ion-col>
      <CornerWeightGlobalInputCorner />
    </ion-row>
  </ion-grid>
  <!-- <div class="button-global-content-wrapper">
      <div class="button-wrapper cancel-button">
        <ion-button fill="outline" @click="cancelHome()"> Cancel </ion-button>
      </div>
      <div class="button-wrapper submit-button">
        <ion-button @click="handleSave()"> Save Calibration </ion-button>
      </div>
    </div> -->
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonHeader,
  IonModal,
  modalController,
  toastController,
  alertController,
} from "@ionic/vue";
import { ref, toRef } from "vue";
import CalTitleCompVue from "../../header-components/CalTitleComp.vue";
import { useRouter } from "vue-router";
import ByCornerModal from "./ByCornerModal.vue";
import CornerWeightGlobalInputCorner from "../../form-components/CornerWeightGlobalInputCorner.vue";

const router = useRouter();

const errorMessage = ref("");

const props = defineProps({ calibration: Object });

const calReactive = toRef(props, "calibration");

async function openCorner(corner) {
  const modal = await modalController.create({
    component: ByCornerModal,
    componentProps: {
      calReactive: calReactive,
      corner,
    },
    // initialBreakpoint: 0.92,
    // breakpoints: [0, 0.92],
    // handleBehavior: "cycle",
  });
  modal.present();
}

// async function handleSave() {
//   const result = await create();
//
//   if (!result) {
//     errorMessage.value = "Server Error";
//     const toast = await toastController.create({
//       message: errorMessage.value,
//       duration: 1500,
//       position: "bottom",
//     });

//     toast.present();
//     console.error(result);
//     return;
//   }
//   if (result.status === 200) {
//     const toast = await toastController.create({
//       message: "Calibration Successfully Saved!",
//       duration: 1500,
//       position: "bottom",
//     });

//     toast.present();
//     router.push({
//       name: "CarLandingPage",
//       params: { carId: calReactive.CarId },
//     });
//   }
// }

const changeToByComponentHandler = (event) => {
  if (event.detail.value === "by-component") {
    router.push({ name: "CalibrationGlobal" });
  }
};

const showRedCircle = (event) => {
  //event.target.nextSibling.css("display", "block");
};
</script>

<style scoped lang="scss">
/* global */

.title-row {
  position: relative;
}

.red-circle-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
}

.title-row ion-col {
  text-align: center;
}

.title-row ion-col h2 {
  font-weight: 700;
}

.title-row ion-col p {
  margin-top: 0;
}

.calibration-title-row {
  margin-bottom: 60px;
}

.ST1Row .ST1Col1-left {
  text-align: center;
}

/* page specific */

/* .sub-title {
    font-size: 14px;
    font-weight: 700;
    margin-left: 20px;
    margin-bottom: 0;
  }
*/

.ST1Cont {
  padding-bottom: 20px;
  background-color: var(--as-ion-grid-background);
  border-top: solid 8px var(--ion-color-black);
  margin-top: 30px;
}

.ST1Row {
  margin-left: 15px;
  margin-right: 15px;
}

.ST1Col1 {
  position: relative;
  padding: 7.5px;
}

.frontback-title-row .front-col {
  padding-bottom: 0;
}

.frontback-title-row .front-col p {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
}

.frontback-title-row .back-col p {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.ST1Col1-left .corner-button {
  margin-right: 0px;
}

.ST1Col1-right .corner-button {
  margin-left: 0px;
}

.corner-button {
  font-size: 70px;
  font-weight: 700;
  height: 100%;
  width: 100%;
}

.corner-button {
  padding: 7.5px;
  margin: 0;
}

.corner-button::part(native) {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding: 30px 45px;
  background-color: var(--ion-color-black);
  color: var(--ion-color-secondary);
  border-width: 0px;
  letter-spacing: 5px;
}

.ST1Col1-left.ST1Col1-top:before {
  content: "";
  position: absolute;
  top: 0;
  right: -8.5px;
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 50%;
}

.ST1Col1-left.ST1Col1-bottom:before {
  content: "";
  position: absolute;
  top: -7.5px;
  left: -8.5px;
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 50%;
}

.ST1Col1-right.ST1Col1-bottom:before {
  content: "";
  position: absolute;
  bottom: -7.5px;
  left: -8.5px;
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 50%;
}

.ST1Col1-right.ST1Col1-top:before {
  content: "";
  position: absolute;
  bottom: -7.5px;
  right: -5px;
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 50%;
}

.ST1Col1-left {
  border-right: dashed 1px #ccc;
}

.ST1Col1-top {
  border-bottom: dashed 1px #ccc;
}

ion-grid {
  padding-left: 15px;
  padding-right: 15px;
}

ion-item::part(native) {
  padding-left: 0;
}

ion-note::part(native) {
  padding-left: 0;
}

.global-button-wrapper {
  display: flex;
  justify-content: space-between;
}

.red-circle-wrapper {
  position: absolute;
  top: -19px;
  display: none;
}

.red-circle-wrapper.front-left {
  right: -17px;
}

.red-circle-wrapper.front-right {
  left: -25px;
}

.red-circle-wrapper.back-left {
  right: -17px;
}

.red-circle-wrapper.back-right {
  left: -25px;
}

.global-cal-row {
  margin-top: 30px;
}

/* .button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  margin-inline: 16px;
  position: fixed;
  bottom: 0px;
  width: calc(100% - 32px);
} */

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}

.item-corner::part(native) {
  padding-inline-start: 0 !important;
}
</style>
