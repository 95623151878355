<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <!-- <ion-page> -->
  <!-- <ion-header> -->
  <!-- <HeaderCompCalibration title="Calibration Global" />
      <ion-toolbar class="segment-toolbar">
        <ion-segment
          @ionChange="changeToByCornerHandler"
          id="calSection"
          value="by-component"
        >
          <ion-segment-button value="by-corner"> By Corner </ion-segment-button>
          <ion-segment-button value="by-component">
            By Component
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header> -->
  <!-- <ion-content class="ion-align-items-center"> -->
  <ion-grid class="grid-wrapper">
    <CalTitleCompVue title="Calibration Report" car="" />
    <ion-row
      class="calibration-title-row"
      style="background-color: var(--as-ion-grid-background); margin-top: -20px"
    >
      <ion-col>
        <ion-item lines="none" style="margin-bottom: 15px">
          <ion-input
            label="Enter Calibration Title"
            label-placement="floating"
            v-model="calReactive.Header.title"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="item-row ST1Cont">
      <ion-col>
        <p class="sub-title">Suspension</p>
        <ion-accordion-group :multiple="true">
          <AccordionGlobalInput
            title="Low Speed Compression"
            calItem="LSC"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.LSC"
            v-model:RF="calReactive.ShockData.RF.LSC"
            v-model:LR="calReactive.ShockData.LR.LSC"
            v-model:RR="calReactive.ShockData.RR.LSC"
          />
          <AccordionGlobalInput
            title="High Speed Compression"
            calItem="HSC"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.HSC"
            v-model:RF="calReactive.ShockData.RF.HSC"
            v-model:LR="calReactive.ShockData.LR.HSC"
            v-model:RR="calReactive.ShockData.RR.HSC"
          />
          <AccordionGlobalInput
            title="Low Speed Rebound"
            calItem="LSREB"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.LSREB"
            v-model:RF="calReactive.ShockData.RF.LSREB"
            v-model:LR="calReactive.ShockData.LR.LSREB"
            v-model:RR="calReactive.ShockData.RR.LSREB"
          />
          <AccordionGlobalInput
            title="High Speed Rebound"
            calItem="HSREB"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.HSREB"
            v-model:RF="calReactive.ShockData.RF.HSREB"
            v-model:LR="calReactive.ShockData.LR.HSREB"
            v-model:RR="calReactive.ShockData.RR.HSREB"
          />
          <AccordionGlobalInput
            title="Packer"
            calItem="PACK"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.PACK"
            v-model:RF="calReactive.ShockData.RF.PACK"
            v-model:LR="calReactive.ShockData.LR.PACK"
            v-model:RR="calReactive.ShockData.RR.PACK"
          />
          <AccordionGlobalInput
            title="Shock PSI"
            calItem="PSI"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.PSI"
            v-model:RF="calReactive.ShockData.RF.PSI"
            v-model:LR="calReactive.ShockData.LR.PSI"
            v-model:RR="calReactive.ShockData.RR.PSI"
          />

          <!--Bespoke accordions below-->
          <ion-accordion>
            <ion-item slot="header">
              <ion-label>FARB</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-input
                  label="Left Front"
                  label-placement="stacked"
                  v-model="calReactive.ShockData.LF.FARB"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Right Front"
                  label-placement="stacked"
                  v-model="calReactive.ShockData.RF.FARB"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-accordion>
          <ion-accordion>
            <ion-item slot="header">
              <ion-label>RARB</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-input
                  label="Left Rear"
                  label-placement="stacked"
                  v-model="calReactive.ShockData.LR.RARB"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Right Rear"
                  label-placement="stacked"
                  v-model="calReactive.ShockData.RR.RARB"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-accordion>

          <AccordionGlobalInput
            title="Spring"
            calItem="SPR"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.SPR"
            v-model:RF="calReactive.ShockData.RF.SPR"
            v-model:LR="calReactive.ShockData.LR.SPR"
            v-model:RR="calReactive.ShockData.RR.SPR"
          />
          <AccordionGlobalInput
            title="Tender/Helper Spring"
            calItem="tenhelpSPR"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.tenhelpSPR"
            v-model:RF="calReactive.ShockData.RF.tenhelpSPR"
            v-model:LR="calReactive.ShockData.LR.tenhelpSPR"
            v-model:RR="calReactive.ShockData.RR.tenhelpSPR"
          />
          <AccordionGlobalInput
            title="Bump Rubber"
            calItem="BumpRub"
            calParent="ShockData"
            v-model:LF="calReactive.ShockData.LF.BumpRub"
            v-model:RF="calReactive.ShockData.RF.BumpRub"
            v-model:LR="calReactive.ShockData.LR.BumpRub"
            v-model:RR="calReactive.ShockData.RR.BumpRub"
          />

          <!--Bespoke accordions below-->
          <!-- <ion-accordion>
                <ion-item slot="header">
                  <ion-label>Shim</ion-label>
                </ion-item>
                <ion-list class="single-list" slot="content">
                  <ion-item class="single-item">
                    <ion-label position="stacked"> Left Front </ion-label>
                    <MultipleInput shimSide="LF" />
                  </ion-item>
                  <ion-item class="single-item">
                    <ion-label position="stacked"> Right Front </ion-label>
                    <MultipleInput shimSide="RF" />
                  </ion-item>
                  <ion-item class="single-item">
                    <ion-label position="stacked"> Left Rear </ion-label>
                    <MultipleInput shimSide="LR" />
                  </ion-item>
                  <ion-item class="single-item">
                    <ion-label position="stacked"> Right Rear </ion-label>
                    <MultipleInput shimSide="RR" />
                  </ion-item>
                </ion-list>
              </ion-accordion> -->

          <AccordionShimInput
            title="Camber Shim Stack"
            v-model:LF="calReactive.ShockData.LF.CamberShim"
            v-model:RF="calReactive.ShockData.RF.CamberShim"
            v-model:LR="calReactive.ShockData.LR.CamberShim"
            v-model:RR="calReactive.ShockData.RR.CamberShim"
            calItem="CamberShim"
          />

          <AccordionShimInput
            v-model:LF="calReactive.ShockData.LF.RideHeightShim"
            v-model:RF="calReactive.ShockData.RF.RideHeightShim"
            v-model:LR="calReactive.ShockData.LR.RideHeightShim"
            v-model:RR="calReactive.ShockData.RR.RideHeightShim"
            title="Ride Height Shim Stack"
            calItem="RideHeightShim"
          />

          <AccordionShimInput
            v-model:LF="calReactive.ShockData.LF.ToeShim"
            v-model:RF="calReactive.ShockData.RF.ToeShim"
            v-model:LR="calReactive.ShockData.LR.ToeShim"
            v-model:RR="calReactive.ShockData.RR.ToeShim"
            title="Toe Shim Stack"
            calItem="ToeShim"
          />
        </ion-accordion-group>
      </ion-col>
    </ion-row>
    <ion-row class="item-row ST1Cont">
      <ion-col>
        <p class="sub-title">Tires</p>
        <ion-accordion-group :multiple="true">
          <AccordionGlobalInput
            title="Tire"
            calItem="tire"
            calParent="TireData"
            v-model:LF="calReactive.TireData.LF.tire"
            v-model:RF="calReactive.TireData.RF.tire"
            v-model:LR="calReactive.TireData.LR.tire"
            v-model:RR="calReactive.TireData.RR.tire"
          />
          <AccordionGlobalInput
            title="Camb"
            calItem="camb"
            calParent="TireData"
            v-model:LF="calReactive.TireData.LF.camb"
            v-model:RF="calReactive.TireData.RF.camb"
            v-model:LR="calReactive.TireData.LR.camb"
            v-model:RR="calReactive.TireData.RR.camb"
          />
          <AccordionGlobalInput
            title="Toe"
            calItem="toe"
            calParent="TireData"
            v-model:LF="calReactive.TireData.LF.toe"
            v-model:RF="calReactive.TireData.RF.toe"
            v-model:LR="calReactive.TireData.LR.toe"
            v-model:RR="calReactive.TireData.RR.toe"
          />
          <AccordionGlobalInput
            title="Starting PSI"
            calParent="TireData"
            calItem="strtPSI"
            v-model:LF="calReactive.TireData.LF.strtPSI"
            v-model:RF="calReactive.TireData.RF.strtPSI"
            v-model:LR="calReactive.TireData.LR.strtPSI"
            v-model:RR="calReactive.TireData.RR.strtPSI"
          />
          <AccordionGlobalInput
            title="Target PSI"
            calParent="TireData"
            calItem="targetPSI"
            v-model:LF="calReactive.TireData.LF.targetPSI"
            v-model:RF="calReactive.TireData.RF.targetPSI"
            v-model:LR="calReactive.TireData.LR.targetPSI"
            v-model:RR="calReactive.TireData.RR.targetPSI"
          />
          <AccordionGlobalInput
            title="Ride Height"
            calItem="ride"
            calParent="TireData"
            v-model:LF="calReactive.TireData.LF.ride"
            v-model:RF="calReactive.TireData.RF.ride"
            v-model:LR="calReactive.TireData.LR.ride"
            v-model:RR="calReactive.TireData.RR.ride"
          />
          <AccordionGlobalInput
            title="Caster Angle"
            calItem="castAng"
            calParent="TireData"
            v-model:LF="calReactive.TireData.LF.castAng"
            v-model:RF="calReactive.TireData.RF.castAng"
            v-model:LR="calReactive.TireData.LR.castAng"
            v-model:RR="calReactive.TireData.RR.castAng"
          />
        </ion-accordion-group>
      </ion-col>
    </ion-row>
    <ion-row class="item-row ST1Cont">
      <ion-col>
        <p class="sub-title">Aero-Dynamics</p>
        <ion-accordion-group :multiple="true">
          <ion-accordion>
            <ion-item slot="header">
              <ion-label>Add Aero-Dynamics</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-input
                  label="Front Splitter"
                  label-placement="stacked"
                  v-model="calReactive.Aero.FrSplitter"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Front Dive Plane 1"
                  label-placement="stacked"
                  v-model="calReactive.Aero.FrDivePlane1"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Front Dive Plane 2"
                  label-placement="stacked"
                  v-model="calReactive.Aero.FrDivePlane2"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Front Third Element"
                  label-placement="stacked"
                  v-model="calReactive.Aero.Fr3rdElement"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rear Wing"
                  label-placement="stacked"
                  v-model="calReactive.Aero.RrWing"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rear Diffuser"
                  label-placement="stacked"
                  v-model="calReactive.Aero.RrDiffuser"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rear Gurney"
                  label-placement="stacked"
                  v-model="calReactive.Aero.RrGurney"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rear Third Element"
                  label-placement="stacked"
                  v-model="calReactive.Aero.Rr3rdElement"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Wing Shim"
                  label-placement="stacked"
                  v-model="calReactive.Aero.WingShim"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rake"
                  label-placement="stacked"
                  v-model="calReactive.Aero.Rake"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>

    <ion-row class="item-row ST1Cont">
      <ion-col>
        <p class="sub-title">Corner Weights</p>
        <ion-accordion-group :multiple="true">
          <ion-accordion>
            <ion-item slot="header">
              <ion-label>Add Corner Weights</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-input
                  label="Left Front"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.LF"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Right Front"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.RF"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Left Rear"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.LR"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Right Rear"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.RR"
                ></ion-input>
              </ion-item>

              <!-- <ion-item
                    v-if="
                      calReactive.CornerWeights.LR &&
                      calReactive.CornerWeights.RF &&
                      totalWeight 
                    "
                  >
                    <ion-label position="stacked"
                      >Cross Weight&nbsp;&nbsp;<ion-icon
                        @click="
                          crossWeightCalc(
                            calReactive.CornerWeights.LR,
                            calReactive.CornerWeights.RF,
                            totalWeight
                          )
                        "
                        :icon="calculator"
                      ></ion-icon
                    ></ion-label>
                    <p style="margin-top: 0; margin-bottom: 0;">{{ crossWeight }}</p>
                    <ion-note slot="helper">Click Calculator to (re)calculate</ion-note>
                  </ion-item>
                  <ion-item
                    v-if="
                      calReactive.CornerWeights.LR &&
                      calReactive.CornerWeights.RF &&
                      calReactive.CornerWeights.LF &&
                      calReactive.CornerWeights.RR
                    "
                  >
                    <ion-label position="stacked"
                      >Total&nbsp;&nbsp;<ion-icon
                        @click="
                          totalWeightCalc(
                            calReactive.CornerWeights.LF,
                            calReactive.CornerWeights.LR,
                            calReactive.CornerWeights.RF,
                            calReactive.CornerWeights.RR
                          )
                        "
                        :icon="calculator"
                      ></ion-icon
                    ></ion-label>
                    <p style="margin-top: 0; margin-bottom: 0;">{{ totalWeight }}</p>
                    <ion-note slot="helper">Click Calculator to (re)calculate</ion-note>
                  </ion-item> -->
              <!-- <CornerWeightGlobalInput /> -->
              <ion-item>
                <ion-input
                  label="Cross"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.Cross"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                label="Total"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.Total"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Fuel"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.Fuel"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Ballast"
                  label-placement="stacked"
                  v-model="calReactive.CornerWeights.Ballast"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>
    <ion-row class="item-row last-row ST1Cont">
      <ion-col>
        <p class="sub-title">Header</p>
        <ion-accordion-group :multiple="true">
          <ion-accordion>
            <ion-item slot="header">
              <ion-label>Add Information</ion-label>
            </ion-item>
            <ion-list slot="content">
              <ion-item>
                <ion-input
                  label="Differential Acceleration"
                  label-placement="stacked"
                  v-model="calReactive.Header.DiffAccel"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Differential Decceleration"
                  label-placement="stacked"
                  v-model="calReactive.Header.DiffDeccel"
                ></ion-input>
              </ion-item>

              <ion-item>
                <ion-input
                  label="Preload lbs / ft"
                  label-placement="stacked"
                  v-model="calReactive.Header.Preload"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Bias %"
                  label-placement="stacked"
                  v-model="calReactive.Header.Bias"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Fr Brand/Size Tire"
                  label-placement="stacked"
                  v-model="calReactive.Header.TireFrontBRSZ"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rr Brand/Size Tire"
                  label-placement="stacked"
                  v-model="calReactive.Header.TireRearBRSZ"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Pad Front"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkPadFront"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Pad Rear"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkPadRear"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Disc Front"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkDiscFront"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Disc Rear"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkDiscRear"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Front Master Cylinder dia"
                  label-placement="stacked"
                  v-model="calReactive.Header.FrtMastCylDia"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Rear Master Cylinder dia"
                  label-placement="stacked"
                  v-model="calReactive.Header.RrMastCylDia"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Cooling Front"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkCoolFront"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Brake Cooling Rear"
                  label-placement="stacked"
                  v-model="calReactive.Header.BrkCoolRear"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Anti Lock Braking"
                  label-placement="stacked"
                  v-model="calReactive.Header.abs"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Elec Stability Control"
                  label-placement="stacked"
                  v-model="calReactive.Header.esc"
                ></ion-input>
              </ion-item>
              <ion-item>
                <ion-input
                  label="Traction Control"
                  label-placement="stacked"
                  v-model="calReactive.Header.tc"
                ></ion-input>
              </ion-item>
              <ion-item  lines="none"
              ></ion-item>
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="as-error-wrapper">
    <!-- <p
          class="button-global-content-wrapper error-message-global"
          v-if="CalibrationState.error"
        >
          {{ CalibrationState.message }}
        </p> -->
  </div>
  <!-- <div class="button-global-content-wrapper">
        <div class="button-wrapper cancel-button">
          <ion-button fill="outline" @click="cancelHome()"> Cancel </ion-button>
        </div>
        <div class="button-wrapper submit-button">
          <ion-button @click="handleSave()" router-link="">
            Save Calibration
          </ion-button>
        </div>
      </div> -->
  <!-- <ion-modal ref="modal" trigger="open-modal" class="calibrationModal">
        <CalibrationModal />
      </ion-modal> -->
  <!-- </ion-content>
  </ion-page> -->
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonHeader,
  IonModal,
  IonIcon,
  IonNote,
  IonToast,
  toastController,
} from "@ionic/vue";

import CalTitleCompVue from "../../header-components/CalTitleComp.vue";
import { useRouter } from "vue-router";
import HeaderCompCalibration from "../../header-components/HeaderCompCalibration.vue";
import CalibrationModal from "../../modal-components/CalibrationModal.vue";
import { alertController } from "@ionic/vue";

// import { useCalibration } from "@jackallee/usetrackside/reactive";
import AccordionGlobalInput from "../../form-components/AccordionGlobalInput.vue";

import CornerWeightGlobalInput from "../../form-components/CornerWeightGlobalInput.vue";
import AccordionShimInput from "../../form-components/AccordionShimInput.vue";
import { toRef, watchEffect } from "vue";
// const { calReactive, create, CalibrationState, clearCalibrationErrors } =
//   useCalibration();

const props = defineProps(["calibration"]);
const calReactive = toRef(props, "calibration");
// const calReactive = toRef(props.calReactive);

// onMounted(() => {
//   calReactive.ShockData.LF.shim;
//   calReactive.ShockData.RF.shim;
//   calReactive.ShockData.LR.shim;
//   calReactive.ShockData.RR.shim;
//   
//   
// });

// watch(
//   () => route.name,
//   (value) => {
//     
//     if (value === "CalibrationGlobal") {
//       calReactive.ShockData.LF.shim;
//       calReactive.ShockData.RF.shim;
//       calReactive.ShockData.LR.shim;
//       calReactive.ShockData.RR.shim;
//     }
//   }
// );

// const cwFLNumber = +cwFL;
// 

// const totalWeight = cwFL + cwRF + cwLR + cwRR;
// 

// const crossWeight = (cwRF + cwLR) / totalWeight;
// 

// let crossWeight = ref();

// const crossWeightCalc = (a, b, totalWeight) => {
//   if (totalWeight) {
//     crossWeight.value = (+a + +b) / +totalWeight;
//     
//     return crossWeight.value;
//   }
//   return;
// };

// let totalWeight = ref();

// const totalWeightCalc = (a, b, c, d) => {
//   if (a && b && c && d) {
//     totalWeight.value = +a + +b + +c + +d;
//     
//     return totalWeight;
//   }
//   return;
// };

const router = useRouter();

// const errorMessage = ref("");

const changeToByCornerHandler = (event) => {
  if (event.detail.value === "by-corner") {
    router.push({ name: "ByCornerLanding" });
  }
  //
};

// async function handleSave() {
//   const result = await create();
//   

//   if (result.status === 200) {

//     const toast = await toastController.create({
//         message: 'Calibration Successfully Saved!',
//         duration: 1500,
//         position: "bottom",
//       });

//     toast.present();

//     router.push({
//       name: "CarLandingPage",
//       params: { carId: calReactive.CarId },
//     });
//   }
// };

async function cancelHome() {
  const alert = await alertController.create({
    header: "Warning",
    message:
      "Pressing OK will return you to the Garage Home page and lose all entered data",
    buttons: [
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
      {
        text: "OK",
        handler: () => {
          router.push({ name: "GarageLanding" });
        },
      },
    ],
  });

  await alert.present();
}
</script>

<style scoped>
.grid-wrapper {
  margin-top: 30px;
}
.as-top-border {
  border-top: solid 8px var(--ion-color-black);
}
/* global */

ion-grid {
  /* padding-bottom: 30px; */
  background-color: transparent;
}

.item-row ion-list {
  display: flex;
  flex-wrap: wrap;
}

.item-row ion-item {
  flex: 50%;
}

.global-cal-row {
  margin-top: 30px;
  background-color: var(--as-ion-grid-background);
}

/* page specific */

.sub-title {
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 0;
}

.item-row {
  margin-top: 30px;
  background-color: var(--as-ion-grid-background);
  padding-left: 15px;
  padding-right: 15px;
}

.calibration-title-row {
  padding-left: 15px;
  padding-right: 15px;
}

.item-row .single-list .single-item {
  flex: 100%;
}

.title-row {
  background-color: var(--as-ion-grid-background);
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 22px;
  flex-wrap: nowrap;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}

.as-chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
}

.enteredChip {
  padding: 0.375rem 0.75rem;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #495057;
  border-radius: 16px;
}

.notEnteredChip {
  padding: 0.375rem 0;
}

.notEnteredChip ion-input {
  --padding-top: 0;
  --padding-bottom: 0;
}



</style>
