<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-page>
    <!-- <SlideoutMenu contentId="main-content" />  -->
    <div class="ion-page">
      <ion-content class="ion-align-items-center">
        <HeaderComp title="Verify Email">
          <ion-buttons slot="start">
            <ion-button
              slot="icon-only"
              @click="$router.push({ name: 'Entry' })"
            >
              <ion-icon :icon="chevronBackOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </HeaderComp>
        <ion-grid class="ST1Cont" style="padding-bottom: 15px;  padding-left: 30px; padding-right: 30px;">
          <ion-row>
            <ion-col>
              <div class="content-wrapper" style="text-align: center; margin-bottom: 30px;">
                <p style="font-size: 18px; margin-bottom: 30px;">
                  <strong>Thanks for signing up! We've successfully created your
                  account.</strong></p>
                  <p> As a final security measure please <strong>verify your email</strong>
                  by clicking the link that has been sent to your provided email
                  address. Clicking the button or link will bring you back to the login screen where you will be able to login with your chosen username and password.</p>
                  <p>If you didn't receive an email please re-enter your email and
                  click the resend button below. Please also check your spam
                  folder. Also please feel free to contact our support team at <a style="color: red" href="mailto:team@057motorsports.com">team@057motorsports.com</a> and we will be happy to help.
                </p>
              </div>

              <div v-if="!requestSent" class="input-wrapper">
                <ion-list>
                  <ion-item :class="{ 'ion-invalid': errorMessage }">
           
                    <ion-input
                      label="Enter Email"
                      label-placement="floating"
                      v-model="email"
                    ></ion-input>
                  </ion-item>
                  <ion-note class="error-note-styles">{{
                    errorMessage
                  }}</ion-note>
                </ion-list>
              </div>
              <div
                v-else
                style="
                  text-align: center;
                  padding-top: 15px;
                  padding-bottom: 15px;
                "
              >
                <strong
                  >Please Check Your email.</strong
                >
              </div>
              <div
                class="button-wrapper"
                style="text-align: right; margin-top: 15px"
              >
                <ion-button :disabled="disableResend" size="default" @click="throttledResendVerifyEmail">Resend {{count > 0 ? count : ""}}</ion-button>
              </div>
              <!--button-wrapper-->
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </div>
  </ion-page>
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonInput,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonList,
  IonRippleEffect,
  IonNote,
  IonButtons,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { useUser } from "../../modules/useTrackside.js";
import HeaderComp from "@/components/header-components/HeaderComp.vue";
import { chevronBackOutline } from "ionicons/icons";
import { useCounter, useThrottleFn } from '@vueuse/core'

const { count, inc, dec, set, reset } = useCounter(6, { min: 0, max: 6 })

//import SlideoutMenu from "../slideout-menu/SlideoutMenu.vue";
const disabled = ref(false);

const email = ref("");
const errorMessage = ref("");
const requestSent = ref(true);
const sendResendText = ref(false);
const disableResend = ref(true);

watch(count, (count, prevCount) => {
  console.log(count);
  if(count > 0){
    setTimeout(dec, 1000);
  } else {
    requestSent.value = false;
    disableResend.value = false;
  }
}, { immediate: true });

function resendVerifyEmail() {
  
  var data = JSON.stringify({
    email: email.value.toLowerCase(),
  });

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${import.meta.env.VITE_API_URL}/user/resendverifyemail`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      //
      if (response.status === 200) {
        errorMessage.value = "";
        requestSent.value = true;
      }
    })
    .catch(function (error) {
      //
      if (error.request.status === 400) {
        errorMessage.value = "Invalid Email";
      } else {
        errorMessage.value = "An error has occurred. Please try again later.";
      }
    });
}
const throttledResendVerifyEmail = useThrottleFn(resendVerifyEmail, 10000);

</script>

<style scoped lang="scss">
.error-note-styles {
  display: block;
  padding-left: 15px;
  color: red;
  font-size: 12px;
  margin-top: 15px;
}
.ST1Cont {
  border-top: solid 8px var(--ion-color-black);
}

.button-wrapper {
  & ion-button {
    &::part(native) {
      background: var(--as-red-gradient-button);
    }
  }
}
</style>
