export function formatDateTime(date) {
  date = new Date(date);

  let dateFormatted = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let timeFormatted = date.toLocaleTimeString();

  return [dateFormatted, timeFormatted];
}
//{{ formatDateTime(outing.date)[0] }}
//{{ formatDateTime(outing.date)[1] }}
