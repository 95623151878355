<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelHandler" class="modal-close-button"
          >Close</ion-button
        >
      </ion-buttons>
      <ion-title>Calibration Overview</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-align-items-center">
    <ion-grid class="ST1Cont">
      <CalTitleCompVue
        :title="CornerTitle.title"
        car=""
        :image="CornerTitle.imgSrc"
      />
      <ion-row class="ST1Row">
        <ion-col class="ST1Col1">
          <form>
            <ion-accordion-group :multiple="true">
              <ion-accordion>
                <ion-item slot="header">
                  <ion-label><strong>Shocks</strong></ion-label>
                </ion-item>
                <ion-grid class="inner-grid" slot="content">
                  <ion-row>
                    <ion-col size="6">
                      <ion-list>
                        <ion-item>
                          <ion-input
                          label="LSC"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].LSC"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="HSC"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].HSC"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="LSREB"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].LSREB"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="HSREB"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].HSREB"
                          ></ion-input>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <ion-col size="6">
                      <ion-list>
                        <ion-item>
                          <ion-input
                          label="Packer"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].PACK"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Shock PSI"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].PSI"
                          ></ion-input>
                        </ion-item>
                        <ion-item
                          v-if="
                            CornerTitle.title === 'Left Front' ||
                            CornerTitle.title === 'Right Front'
                          "
                        >
                          <ion-input
                          label="FARB"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].FARB"
                          ></ion-input>
                        </ion-item>
                        <ion-item
                          v-if="
                            CornerTitle.title === 'Left Rear' ||
                            CornerTitle.title === 'Right Rear'
                          "
                        >
                          <ion-input
                          label="RARB"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].RARB"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Spring"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].SPR"
                          ></ion-input>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <ion-col size="12">
                      <ion-list>
                        <ion-item>
                          <ion-input
                          label="Bump Rubber"
                  label-placement="stacked"
                            v-model="calReactive.ShockData[corner].BumpRub"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Tender/Helper Spring"
                  label-placement="stacked"
                            v-model="
                              calReactive.ShockData[corner].tenhelpSPR
                            "
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-label position="stacked"
                            >Camber Shim Stack</ion-label
                          >
                          <MultipleInput
                            v-model="calReactive.ShockData[corner].CamberShim"
                            :shimSide="cornerShimValue.value"
                            calItemPass="CamberShim"
                          />
                        </ion-item>
                        <ion-item>
                          <ion-label position="stacked"
                            >Ride Height Shim Stack</ion-label
                          >
                          <MultipleInput
                            v-model="calReactive.ShockData[corner].RideHeightShim"
                            :shimSide="cornerShimValue.value"
                            calItemPass="RideHeightShim"
                          />
                        </ion-item>
                        <ion-item>
                          <ion-label position="stacked"
                            >Toe Shim Stack</ion-label
                          >
                          <MultipleInput
                            v-model="calReactive.ShockData[corner].ToeShim"
                            :shimSide="cornerShimValue.value"
                            calItemPass="ToeShim"
                          />
                        </ion-item>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-accordion>
              <ion-accordion>
                <ion-item slot="header">
                  <ion-label><strong>Tires</strong></ion-label>
                </ion-item>
                <ion-grid class="inner-grid" slot="content">
                  <ion-row>
                    <ion-col size="6">
                      <ion-list>
                        <ion-item>
                          <ion-input
                          label="Camb"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].camb"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Toe"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].toe"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Ride Height"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].ride"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Caster Angle"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].castAng"
                          ></ion-input>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <ion-col size="6">
                      <ion-list>
                        <ion-item>
                          <ion-input
                          label="Tire"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].tire"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Starting PSI"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].strtPSI"
                          ></ion-input>
                        </ion-item>
                        <ion-item>
                          <ion-input
                          label="Target PSI"
                  label-placement="stacked"
                            v-model="calReactive.TireData[corner].targetPSI"
                          ></ion-input>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-accordion>
              <ion-accordion>
                <ion-item slot="header">
                  <ion-label><strong>Corner Weight</strong></ion-label>
                </ion-item>
                <ion-grid class="inner-grid" slot="content">
                  <ion-row>
                    <ion-col size="12">
                      <ion-list>
                        <ion-item>
                          <ion-input
                            label="Weight"
                            label-placement="stacked"
                            v-model="calReactive.CornerWeights[corner]"
                          ></ion-input>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-accordion>
            </ion-accordion-group>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- <div class="button-global-content-wrapper">
        <div class="button-wrapper cancel-button">
          <ion-button
            fill="outline"
            @click="$router.push({ name: 'ByCornerLanding' })"
          >
            Save and Back
          </ion-button>
        </div>
        <div style="display: none" class="button-wrapper submit-button">
          <ion-button> Submit Calibration </ion-button>
        </div>
      </div> -->
  </ion-content>
</template>
<script setup>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonButton,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonHeader,
  modalController,
  IonTitle,
} from "@ionic/vue";

import { watch, computed } from "vue";

import CalTitleCompVue from "@/components/header-components/CalTitleComp.vue";
import HeaderCompCalibration from "@/components/header-components/HeaderCompCalibration.vue";

import { useRoute } from "vue-router";

import { ref, toRef } from "vue";

import { useCalibration } from "../../../modules/useTrackside.js";
import MultipleInput from "../../form-components/MultipleInput.vue";
// const { calReactive } = useCalibration();

const route = useRoute();

// const corner = ref(route.query.c);

const props = defineProps({
  corner: {
    required: true,
    validate: function (p) {
      return ["LF", "RF", "LR", "RR"].includes(p);
    },
  },
  calReactive: {
    required: true,
    type: Object,
  },
});
const calReactive = toRef(props, "calReactive");
let cornerShimValue = toRef(props, "corner");

const cancelHandler = () => {
  return modalController.dismiss(null, "cancel");
};
// watch(
//   () => route.query.c,
//   (value) => {
//     
//     if (value) {
//       corner.value = value;
//     }
//   }
// );

const CornerTitle = computed(() => {
  

  switch (props.corner) {
    case "LF":
      return {
        title: "Left Front",
        imgSrc:
          "/assets/images/as-corners/corner-icons/Calibration_Buttons_FL.svg",
      };
    case "RF":
      return {
        title: "Right Front",
        imgSrc:
          "/assets/images/as-corners/corner-icons/Calibration_Buttons_FR.svg",
      };
    case "LR":
      return {
        title: "Left Rear",
        imgSrc:
          "/assets/images/as-corners/corner-icons/Calibration_Buttons_RL.svg",
      };
    case "RR":
      return {
        title: "Right Rear",
        imgSrc:
          "/assets/images/as-corners/corner-icons/Calibration_Buttons_RR.svg",
      };
    default:
      return props.corner;
  }
});
</script>

<style scoped lang="scss">
/* global */

/* page specific */

/* .sub-title {
      font-size: 14px;
      font-weight: 700;
      margin-left: 20px;
      margin-bottom: 0;
    }
  */

/*
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding: 30px 45px;
    background-color: var(--ion-color-black);
    color: var(--ion-color-secondary);
    border-width: 0px;
    letter-spacing: 5px;
    */

.ST1Cont {
  padding: 0;
}

.inner-grid {
  margin: 0;
  margin-bottom: 30px;
}

.button-global-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--as-chat-white-transp);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-inline: 16px;
}

.button-global-content-wrapper
  .button-wrapper.submit-button
  ion-button::part(native) {
  background-color: var(--ion-color-secondary);
}

.cancel-button ion-button::part(native) {
  color: var(--ion-color-black);
  border: solid 1px var(--ion-color-black);
}
</style>
