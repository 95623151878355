import { ref, watchEffect, isRef } from "vue";
import { loadingController } from "@ionic/vue";
const loadingList = [
  "Checking Tires...",
  "Changing Oil...",
  "Testing Brakes...",
  "Lowering Shocks...",
];

export function useLoadingSpinner(isLoading_ = undefined) {
  const isLoading = isRef(isLoading_) ? isLoading_ : ref(false);
  const random = Math.floor(Math.random() * loadingList.length);
  loadingController
    .create({
      message: loadingList[random],
      spinner: "crescent",
      cssClass: "custom-loading",
    })
    .then((l) => {
      watchEffect(() => {
        if (isLoading.value) {
          l.present();
        } else {
          l.dismiss();
        }
      });
    });
  return { isLoading };
}
