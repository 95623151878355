<template>
  <form onsubmit="return false" class="form-style">
    <span style="display: none" class="feedback-title"> </span>

    <ion-grid class="as-corner-grid">
      <ion-row class="title-row">
        <ion-col class="title-col1">
          <div class="as-content-wrapper">
            <h2 class="turn-title">
              Turn {{ cornerData.number }}<br />
              {{ cornerData.name }}
            </h2>
          </div>
          <!--as-content-wrapper-->
        </ion-col>
        <!-- <ion-col class="title-col2" size="4">
          <div class="as-content-wrapper">
            <p>{{}}</p>
          </div>
        </ion-col> -->
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-item class="select-item-wrapper" lines="none">
              <ion-select
                label="Entry"
                :class="entryClass"
                interface="popover"
                placeholder="Select"
                v-model="turnEntry.entry"
                :disabled="disabledFields"
              >
                <ion-select-option class="over-select" value="over"
                  >Over</ion-select-option
                >
                <ion-select-option class="neutral-select" value="neutral"
                  >Neutral</ion-select-option
                >
                <ion-select-option class="under-select" value="under"
                  >Under</ion-select-option
                >
              </ion-select>
            </ion-item>
            <ion-item class="select-item-wrapper" lines="none">
              <ion-select
                label="Apex"
                :class="apexClass"
                interface="popover"
                placeholder="Select"
                v-model="turnEntry.apex"
                :disabled="disabledFields"
              >
                <ion-select-option value="over">Over</ion-select-option>
                <ion-select-option value="neutral">Neutral</ion-select-option>
                <ion-select-option value="under">Under</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item class="select-item-wrapper" lines="none">
              <ion-select
                label="Exit"
                :class="exitClass"
                interface="popover"
                placeholder="Select"
                v-model="turnEntry.exit"
                :disabled="disabledFields"
              >
                <ion-select-option value="over">Over</ion-select-option>
                <ion-select-option value="neutral">Neutral</ion-select-option>
                <ion-select-option value="under">Under</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-list>
            <ion-item>
              <ion-textarea
                label="Comments"
                v-model="turnEntry.notes"
                auto-grow="true"
                :disabled="disabledFields"
              ></ion-textarea>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";

import {
  IonItem,
  IonLabel,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
// import { watch, ref } from "vue";
//import { defineProps } from "vue";
// import { useTurnData } from "../../modules/useTrackside.js";
// const notes = ref();
// const entry = ref();
// const apex = ref();
// const exit = ref();

const props = defineProps({
  turnEntry: { type: Object, required: true },
  cornerData: Object,
  disabledFields: {
    type: Boolean,
  }
});

const TurnEntry_ = toRef(props, "turnEntry");
const disabledFields = toRef(props, "disabledFields");

console.log(disabledFields.value);

// watch(() => {
//   entry.value = props.turnEntry.entry;
//   apex.value = props.turnEntry.apex;
//   exit.value = props.turnEntry.exit;
//   notes.value = props.turnEntry.notes;

const getClass = (value) => {
  if (value === "over") {
    return "as-turn-select over-select";
  } else if (value === "neutral") {
    return "as-turn-select neutral-select";
  } else if (value === "under") {
    return "as-turn-select under-select";
  } else {
    return "as-turn-select";
  }
};

const entryClass = computed(() => getClass(TurnEntry_.value.entry));
const apexClass = computed(() => getClass(TurnEntry_.value.apex));
const exitClass = computed(() => getClass(TurnEntry_.value.exit));

// });
</script>

<style scoped lang="scss">
.as-turn-select {
  width: 100%;
  border-right: solid 8px transparent;
  text-align: center;
  padding-right: 15px;

  &.over-select {
    border-right: solid 12px var(--ion-color-secondary);
  }

  &.neutral-select {
    border-right: solid 12px var(--ion-color-green);
  }

  &.under-select {
    border-right: solid 12px var(--ion-color-amber);
  }
}

@media only screen and (min-width: 800px) {
  .as-turn-select {
    border-right: solid 12px transparent;
    text-align: right;
    padding-right: 20px;

    &.over-select {
      border-right: solid 12px var(--ion-color-secondary);
    }

    &.neutral-select {
      border-right: solid 12px var(--ion-color-green);
    }

    &.under-select {
      border-right: solid 12px var(--ion-color-amber);
    }
  }
}
.as-content-wrapper h2.turn-title {
  font-size: 17px;
  padding-bottom: 7.5px;
}
ion-item.select-item-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--ion-toolbar-background);
  --ion-safe-area-right: 0px;
  --inner-padding-end: 0px;

  &::part(native) {
    --padding-end: 0 !important;
    padding-inline-end: 0 !important;
  }
}

.item-inner {
}
.as-corner-grid {
  margin: 0;
  margin-bottom: 30px;
}
.title-row {
  /* border-top: solid 2px var(--ion-color-black); */
  border-bottom: solid 2px var(--ion-color-black);
}

.title-row .title-col1 {
  /* border-right: solid 2px var(--ion-color-black); */
}

.title-row .title-col1 .as-content-wrapper h2 {
  color: var(--ion-color-black);
  text-align: left;
  font-family: "Helvetica";
  font-weight: 700;
}

.title-row .title-col2 .as-content-wrapper p {
  text-align: right;
  font-weight: 400;
}

ion-item::part(native) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-style {
  display: block;
}

.feedback-title {
  align-self: flex-start;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 50px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2em;
  color: #e1e1e1;
  text-transform: uppercase;
  margin-block: 15px;
}

.feedback-wrapper {
  display: grid;
  font-family: "Perfect DOS VGA 437";
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 100;
  width: 100%;
  height: 40%;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
  grid-auto-rows: minmax(30px, auto);
}

.as-content-wrapper p {
  color: var(--ion-color-black);
  font-family: "Helvetica";
}

ion-radio::part(container) {
  background: white;
  border-radius: 50%;
  border: solid 1px var(--ion-color-black);
}

.as-radio-group {
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 12px);
  margin: 0 auto;
}

ion-radio {
  margin-left: -1px;
}

ion-radio + ion-radio {
}

ion-radio + ion-radio + ion-radio {
  margin-right: 27px;
}

.comments {
  width: 100%;
  padding-block: 2px;
  padding-inline: 2px;
  margin-top: 10px;
}

.comments ion-textarea {
  --border-radius: 10px;
}
</style>
