<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <ion-header>
    <ion-toolbar class="as-toolbar">
      <ion-title>Calibration Overview</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <CalibrationOverview
      :calibrationId="calibrationId"
      :fetchCal="fetchCal"
      :calibration="calibration"
    />
    
  </ion-content>
  <ion-fab class="as-fab" slot="fixed" vertical="bottom" horizontal="end" >
    <ion-fab-button class="as-fab-button" @click="cancelHandler">
      <ion-icon :icon="closeOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <!-- <ion-footer class="as-footer" :translucent="true">
      <ion-toolbar class="as-footer-toolbar">
        <ion-buttons class="as-buttons">
          <ion-button fill="clear" slot="icon-only" @click="cancelHandler">
            <ion-icon :icon="closeCircleOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer> -->


</template>
<script setup>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  modalController,
  IonSkeletonText,
  IonFooter,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";

import { closeOutline } from "ionicons/icons";

import CalibrationOverview from "../calibration/CalibrationOverview.vue";
const props = defineProps({
  fetchCal: {
    type: Boolean,
    required: true,
  },
  calibrationId: {
    type: String,
    required: false,
  },
  calibration: {
    default: undefined,
    required: false,
  },
});
const cancelHandler = () => {
  return modalController.dismiss(null, "cancel");
};
</script>

<style scoped lang="scss">

ion-grid {
  margin-bottom: 10px;
}

.as-toolbar {
  padding-top: 5px;
}

.as-fab {

  & .as-fab-button {

    &::part(native) {
      background-color: var(--ion-color-secondary);
    }
  }
}

  // ion-footer.as-footer {

  //   & ion-toolbar.as-footer-toolbar {
  //     text-align: center;
  //     padding-bottom: 6px;

  //     & ion-buttons.as-buttons {
  //       justify-content: center;

  //       & ion-button {

  //         & ion-icon {
  //           font-size: 40px;
  //         }
  //       }
  //     }
  //   }
  // }

</style>
